import React, { useState, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import Icons from "../../assets/icons";

const CustomAttachment = ({ title }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileRef = useRef();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    handleFileUpload(event.target.files[0]);
  };

  const handleFileUpload = (file) => {
    // Add your file upload logic here
    console.log("File uploaded:", file);
  };

  return (
    <>
      <Box
        sx={{
          border: "1px dashed #D9D9D9",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <input
          type="file"
          ref={fileRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <Button component="span" onClick={() => fileRef.current.click()}>
          <Icons.ATTACHMENT />
        </Button>
        <Typography
          variant="body1"
          sx={{ fontSize: "15px !important", fontWeight: "500" }}
        >
          {title}
        </Typography>
      </Box>
      {selectedFile && (
        <Typography
          variant="body2"
          sx={{ display: "flex", gap: "9px", color: "#000", marginTop: "15px" }}
        >
          <Icons.FILE_UPLOAD /> {selectedFile.name} ({selectedFile.size} bytes)
        </Typography>
      )}
    </>
  );
};

export default CustomAttachment;
