import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { EMAIL_LOGIN_MUTATION } from '../../api/queries/auth.queries';
import { EMAIL_REGISTER_MUTATION } from '../../api/queries/auth.queries';
import { useMutation } from '@apollo/client';




export const signInwithGoogle = createAsyncThunk(
  'auth/signInWithGoogle',
  async (_, { rejectWithValue }) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const LoginMutation = useMutation(EMAIL_LOGIN_MUTATION)
    const RegisterMutation = useMutation(EMAIL_REGISTER_MUTATION)
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;

      if (user) {
        const { displayName, email, phoneNumber, photoURL, uid } = user;
        const [firstName, lastName] = displayName ? displayName.split(' ') : ['', ''];

        const userData = {
          email,
          firstName,
          lastName,
          phone: phoneNumber,
          image: photoURL,
        };
       // Attempt to login the user
        try {
          const loginResponse = await (LoginMutation, {
            email: userData.email,
            password: userData.password, 
          });

          // Successfully logged in
          localStorage.setItem('token', loginResponse.login.token);
          localStorage.setItem('userData', JSON.stringify(loginResponse.login.user));
          localStorage.setItem('refreshToken', loginResponse.login.refresh_token);
          return loginResponse.login.user;

        } catch (loginError) {
          try {
            const registerResponse = await (RegisterMutation, {
              email: userData.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
              password: userData.password,
            });
            localStorage.setItem('token', registerResponse.register.token);
            localStorage.setItem('userData', JSON.stringify(registerResponse.register.user));
            localStorage.setItem('refreshToken', registerResponse.register.token);
            return registerResponse.register.user;

          } catch (registerError) {
            console.error('Error registering user:', registerError);
            return rejectWithValue(registerError.message);
          }
        }
      } else {
        throw new Error('User object is not valid or undefined');
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    user: null,
    loading: false,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      localStorage.removeItem('isLoggedIn');
      state.isLoggedIn = false;
      state.user = null;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(signInwithGoogle.pending, (state) => {
  //       state.loading = true;
  //       state.error = null;
  //     })
  //     .addCase(signInwithGoogle.fulfilled, (state, action) => {
  //       state.loading = false;
  //       state.user = action.payload.user;
  //       state.isLoggedIn = true;
  //       localStorage.setItem('isLoggedIn', 'true');
  //     })
  //     .addCase(signInwithGoogle.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.payload;
  //     });
  // },
});

export const { login, logout, setUser } = authSlice.actions;

export default authSlice.reducer;
