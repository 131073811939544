import Header from "../../../components/Header/header.component";
import Footer from "../../../components/Footer/footer.component";
import { useTranslation } from "react-i18next";
import CreateNewPassword from "./CreateNewPassword";
// import "../styles/rtl.scss";
const CreateNewPasswordLayout = () => {
  const { i18n } = useTranslation();
  return (
    <>
      <Header />
      <div className={i18n.language === "en" ? "ltr-layout" : "rtl-layout"}>
        <main>
          <CreateNewPassword />
        </main>
        <Footer />
      </div>
    </>
  );
};
export default CreateNewPasswordLayout;
