import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import {
  useQuery,
} from "@apollo/client";
import {
  Stack,
  Box,
  Autocomplete,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Menu,
  Button,
  Typography,
  IconButton,
  FormControl,
  Card,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import IconWithBadge from "../../../components/icon-with-badge/icon-with-badge.component";
import * as ROUTES from "../../../constants/routes";
import ControlledButton from "../../../components/Button/button.component";
import SectionTitle from "../../../components/section-title/section-title.component";
import Icons from "../../../assets/icons";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ResponsiveCarousel from "../../../components/responsive-carousel/responsive-carousel.component";
import { formatNumberWithCommas } from "../../../helpers/global";
import { useTheme } from "@mui/material";
import PriceSlider from "../../../components/slider/slider.component";
import Checkbox from "@mui/material/Checkbox";
import CustomPagination from "../../../components/pagination/pagiantion.component";
import AreaSlider from "../../../components/area-slider/AreaSlider";
import { Helmet } from "react-helmet";
import Skeleton from "@mui/material/Skeleton";
import {
  extras,
  seatCapacity,
  transmissionType,
  fuelType,
  badges,
  exteriorColor,
  interiorColor,
  regionalSpecs,
  no_ofCylinder,
  doors,
  bodyTYpe,
  horsePower,
} from "../../../data";
import {
  GET_MOTORS,
  GET_MOTORS_MAKE,
} from "../../../api/queries/motors.queries";
import "./search.scss";
import ShowMoreList from "../../../components/show-more-list/ShowMoreList";
import YearPicker from "../../../components/year-picker/year-picker.component";
const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const useStyles = makeStyles(() => ({
  selectRoot: {
    width: "100%",
    height: "min-content",
    borderRadius: "8px",
    fontSize: "14px",
    padding: 0,
    "& .MuiSelect-select": {
      padding: "9.5px 14px",
      fontSize: "14px",
    },
  },
  autocompleteRoot: {
    padding: 0,
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
  },
  inputRoot: {
    padding: "0",
  },
  MuiInputBaseRoot: {
    borderRadius: "8px !important",
    borderColor: "#D9D9D9",
  },
  bedsBathsSelect: {
    width: "100%",
    height: "min-content",
    borderRadius: "8px",
    fontSize: "14px",
    padding: 0,
  },
  bedsBathsMenuPaper: {
    width: "300px",
  },
}));

const MotorCard = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    classes,
    data,
    verifiedRankStyles,
    featuredStyles,
    bedroomBathroomAspectStyles,
    statusStyles,
    isMobile,
    lang,
  } = props;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      // simulate loading delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };
    loadData();
  }, []);
  const handleMotorClick = (id) => {
    navigate(generatePath(`${ROUTES.MOTOR_DETAILS}`, { id }));
  };
  return (
    <Card
      className="motor-card"
      // className={classes.cardRoot}
      sx={{
        borderTopRightRadius: "5px!important",
        borderTopLeftRadius: "5px!important",
        cursor: "pointer",
        boxShadow: "unset",
      }}
      onClick={() => handleMotorClick(data?.id.toString())}
    >
      {loading ? (
        <Skeleton
          variant="rect"
          width={281}
          height={183}
          style={{ borderRadius: "5px" }}
          animation="wave"
        />
      ) : (
        <Box
          className="images-carousel"
          position={"relative"}
          width={"281px"}
          height={"183px"}
        >
          {data?.images?.length >= 1 && (
            <ResponsiveCarousel
              images={data.images ? data?.images : <Icons.NO_IMAGE />}
              hideThumbs={true}
            />
          )}
          {data?.images?.length === 0 && (
            <Box
              display={"grid"}
              height={"100%"}
              borderRadius={"8px"}
              border={"3px solid #D9D9D9"}
            >
              <Box alignSelf={"center"} justifySelf={"center"}>
                <Icons.NO_IMAGE style={{ width: "50%" }} loading="lazy" />
                <Typography color={theme.palette.primary.main}>
                  No Image Available
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
      <CardContent
        sx={{ padding: isMobile ? "16px 0px" : "16px 0px", display: "grid" }}
      >
        <Stack direction={"row"} display={"flex"}>
          {/*  <IconWithBadge
            icon={
              <Icons.VERIFIED className={isMobile ? classes.mobileMedia : ""} />
            }
            title={data?.verified && "Verified"}
            badgeStyle={verifiedRankStyles}
          /> */}
          {/* {!isMobile && (
                      <IconWithBadge
                          icon={<Icons.RANK />}
                          title={data?.rank}
                          badgeStyle={verifiedRankStyles}
                      />
                  )} */}
          {/* <IconWithBadge
            title={data?.is_featured && "Featured"}
            badgeStyle={featuredStyles}
          /> */}
        </Stack>
        <Box padding={isMobile && "0 8px"} textAlign={"start"}>
          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <Box className={classes.headline} gap={"8px"} display={"grid"}>
              <Typography
                variant="h3"
                color={theme.palette.primary.main}
              >{`${formatNumberWithCommas(data?.price)} AED`}</Typography>
              <Typography
                variant="h3"
                fontWeight={400}
                color={theme.palette.primary.main}
                sx={{
                  width: '250px',
                  overflow: 'hidden',
                  textOverflow:'ellipsis',
                  whiteSpace: 'noWrap'
                }}
              >
                {data?.title}
              </Typography>
            </Box>
          )}

          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <IconWithBadge
              icon={<Icons.LOCATION style={{ width: 20, height: 20 }} />}
              title={data?.emirates}
              badgeStyle={{
                margin: "8px 0px!important",
                display: "flex",
                alignItems: "center",
                color: theme.palette.primary.main,
              }}
              type={"location"}
              className={classes?.badges}
            />
          )}
        </Box>

        {loading ? (
          <Skeleton animation="wave" />
        ) : (
          <Typography
            color={theme.palette.primary.main}
            display={"flex"}
            padding={isMobile ? "6px" : 0}
            margin={"8px 0px 0px 0px"}
            gap={"6px"}
            sx={{ cursor: "pointer", flexWrap: "wrap" }}
          >
            <Box
              sx={{
                border: "1px solid #EFEFEF",
                borderRadius: "4px",
                padding: "6px",
                width: "44px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              <Typography noWrap sx={{ fontSize: "12px !important" }}>
                YEAR
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px !important",
                  fontWeight: "600 !important",
                  marginTop: "10px",
                }}
              >
                {data?.year}
              </Typography>
            </Box>

            <Box
              sx={{
                border: "1px solid #EFEFEF",
                borderRadius: "4px",
                padding: "6px",
              }}
            >
              <Typography sx={{ fontSize: "12px !important" }}>
                KILOMETER
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px !important",
                  fontWeight: "600 !important",
                  marginTop: "10px",
                }}
              >
                {data?.kilometers}
              </Typography>
            </Box>

            <Box
              sx={{
                border: "1px solid #EFEFEF",
                borderRadius: "4px",
                padding: "6px",
              }}
            >
              <Typography sx={{ fontSize: "12px !important" }}>
                SPECS
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px !important",
                  fontWeight: "600 !important",
                  marginTop: "10px",
                }}
              >
                {data?.specs}
              </Typography>
            </Box>

            <Box
              sx={{
                border: "1px solid #EFEFEF",
                borderRadius: "4px",
                padding: "6px",
              }}
            >
              <Typography sx={{ fontSize: "12px !important" }}>
                STEERING
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px !important",
                  fontWeight: "600 !important",
                  marginTop: "10px",
                }}
              >
                {data?.steering_side}
              </Typography>
            </Box>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const MotorSearch = () => {
  const params = useParams();

  // getting category ID
  const { categoryId } = params;
  const categoryIdInt = parseInt(categoryId);

  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [showAll, setShowAll] = useState(true);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [isGridView, setIsGridView] = useState(true);

  const handleGridViewClick = () => {
    setIsGridView(true);
  };

  const handleListViewClick = () => {
    setIsGridView(false);
  };

  const [places, setPlaces] = useState([]);
  const [value, setValue] = useState("");

  const getPlaces = async () => {
    const promise = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${mapboxAccessToken}`
    );
    const data = await promise.json();
    setPlaces(data.features);
  };

  useEffect(() => {
    getPlaces();
  }, [value]);

  // price

  const [minPrice, setMinPrice] = useState(7000);
  const [maxPrice, setMaxPrice] = useState(10000000);

  const handlePriceChange = (newValues) => {
    const [minPrice, maxPrice] = newValues;
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);

    setFilters((prevFilters) => ({
      ...prevFilters,
      price: `${minPrice} - ${maxPrice}`,
    }));
  };

  // kilometers

  const [minKilometerValue, setMinKilometerValue] = useState(100);
  const [maxKilometerValue, setMaxKilometerValue] = useState(1000000);

  const handleKiloMeterChange = (newValues) => {
    const [newMinKilometerValue, newMaxKilometerValue] = newValues;
    setMinKilometerValue(newMinKilometerValue);
    setMaxKilometerValue(newMaxKilometerValue);
    setFilters((prevFilters) => ({
      ...prevFilters,
      kilometers: `${newMinKilometerValue} - ${newMaxKilometerValue}`,
    }));
  };

  // year

  const [startYear, setStartYear] = useState(1995);
  const [endYear, setendYear] = useState(2024);

  const handleYearChange = (newValues) => {
    const [startYear, endYear] = newValues;
    setStartYear(startYear);
    setendYear(endYear);
    setFilters((prevFilters) => ({
      ...prevFilters,
      year: `${startYear} - ${endYear}`,
    }));
  };

  const [tabValue, setTabValue] = useState(0);

  // filters

  const [ispriceOpen, setpriceOpen] = useState(false);

  const OpenpriceMenu = () => {
    setpriceOpen(!ispriceOpen);
  };

  const verifiedRankStyles = {
    display: "inline-flex",
    gap: "5px!important",
    borderRadius: 100,
    background: "#EFEFEF",
    alignItems: "center",
    padding: "2px 7px",
    margin: isMobile ? "0 5px" : "0 5px 0 0",
  };
  const featuredStyles = {
    padding: "5px 9px",
    borderRadius: 100,
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  };
  const bedroomBathroomAspectStyles = {
    borderRadius: "4px!important",
    border: "1px solid #EFEFEF",
    padding: "4px 12px",
    margin: "8px 0px!important",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  };
  const statusStyles = {
    padding: "5px 9px",
    position: "absolute",
    top: "12%",
    width: "max-content",
    left: 0,
    borderRadius: "0px 3px 3px 0px",
    background: theme.palette.secondary.main,
  };

  const filterTypes = [
    {
      name: "Rent",
      function: () => console.log("filter rent"),
    },
    {
      name: "Buy",
      function: () => console.log("filter rent"),
    },
    {
      name: "Sell",
      function: () => console.log("filter rent"),
    },
  ];

  const [openSecelect, setOpenSeclect] = React.useState(false);

  const handleClickOpen = () => {
    setOpenSeclect(true);
  };
  const handleSelectClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenSeclect(false);
    }
  };

  const [activeType, setActiveType] = useState(filterTypes[0].name);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [propertyType, setPropertType] = useState([]);

  const [showFilterMessage, setShowFilterMessage] = useState(false);

  const handleButtonClick = (name) => {
    setActiveType(name);
    // You can also call the respective function here if needed
    const filter = filterTypes.find((type) => type.name === name);
    if (filter) {
      filter.function();
    }
  };

  const handlePlaceChange = (event, value) => {
    setSelectedPlaces(value);

    const extractCityName = (placeName) => {
      const placeNameParts = placeName.split(",");
      return placeNameParts[0].trim(); // Ensure that you get the city name without extra spaces
    };

    const locationValue =
      value.length === 1
        ? extractCityName(value[0].place_name)
        : value.map((place) => extractCityName(place?.place_name));

    setFilters((prevFilters) => ({
      ...prevFilters,
      location: locationValue,
    }));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [filterOpen, setFilterOpen] = useState(null);

  const filterOpennow = Boolean(filterOpen);

  const handleFilterBtnClick = (e) => {
    setFilterOpen(e.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterOpen(null);
  };

  const [priceOpen, setPriceOpen] = useState(null);

  const priceOpennow = Boolean(filterOpen);

  const handlePriceBtnClick = (e) => {
    setPriceOpen(e.currentTarget);
  };

  const handlePriceClose = () => {
    setPriceOpen(null);
  };

  const [yearOpen, setyearOpen] = useState(null);

  const yearOpennow = Boolean(filterOpen);

  const handleYearBtnClick = (e) => {
    setyearOpen(e.currentTarget);
  };

  const handleYearClose = () => {
    setyearOpen(null);
  };

  const [selectedExtras, setselectedExtras] = useState([]);

  const handleAmenityClick = (extra) => {
    const isAlreadySelected = selectedExtras.some(
      (selected) => selected.id === extra.id
    );
    if (isAlreadySelected) {
      const updatedExtras = selectedExtras.filter(
        (selected) => selected.id !== extra.id
      );
      setselectedExtras(updatedExtras);
    } else {
      setselectedExtras([...selectedExtras, extra]);
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      extras: selectedExtras,
    }));
  };

  const [displayedExtras, setDisplayedExtras] = useState(extras);

  const handleExtrasButtonClick = () => {
    if (showAll) {
      setDisplayedExtras(extras.slice(0, 15));
    } else {
      setDisplayedExtras(extras);
    }
    setShowAll(!showAll);
  };

  // function for handling the items selection

  const handleSelectionClick = (
    selectedItems,
    setSelectedItems,
    setFilters,
    filterKey,
    item
  ) => {
    const isAlreadySelected = selectedItems.some(
      (selected) => selected.id === item.id
    );

    let updatedItems;
    if (isAlreadySelected) {
      updatedItems = selectedItems.filter(
        (selected) => selected.id !== item.id
      );
    } else {
      updatedItems = [...selectedItems, item];
    }
    const selectedValues = updatedItems.map((option) => String(option.title));
    setSelectedItems(updatedItems);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: selectedValues.join(", "),
    }));
  };

  const [selectedBody, setSelectedBody] = useState([]);

  const handleBodyClick = (body) => {
    handleSelectionClick(
      selectedBody,
      setSelectedBody,
      setFilters,
      "body_type",
      body
    );
  };

  // function for seat capacity

  const [selectedSeatCapacity, setselectedSeatCapacity] = useState([]);

  const handleSeatCapacityClick = (seat) => {
    handleSelectionClick(
      selectedSeatCapacity,
      setselectedSeatCapacity,
      setFilters,
      "seating_capacity",
      seat
    );
  };

  // handling the transmission type

  const [selectedTransmission, setSelectedTransmission] = useState([]);

  const handleTransmissionClick = (transmission) => {
    handleSelectionClick(
      selectedTransmission,
      setSelectedTransmission,
      setFilters,
      "transmission_type",
      transmission
    );
  };

  // for fuel

  const [selectedFuel, setselectedFuel] = useState([]);
  const handleFuelTypeClick = (fuel) => {
    handleSelectionClick(
      selectedFuel,
      setselectedFuel,
      setFilters,
      "fuel_type",
      fuel
    );
  };
  // for bades

  const [selectedBadges, setselectedBadges] = useState([]);

  const handleBadgeClick = (badge) => {
    handleSelectionClick(
      selectedBadges,
      setselectedBadges,
      setFilters,
      "badge",
      badge
    );
  };

  // horsepower

  const [selectedHorsePower, setselectedHorsePower] = useState([]);

  const handleHorsePowerClick = (power) => {
    handleSelectionClick(
      selectedHorsePower,
      setselectedHorsePower,
      setFilters,
      "horsepwer",
      power
    );
  };

  // exterior color:
  const [selectedExteriorColor, setselectedExteriorColor] = useState([]);

  const handleExteriorClick = (exterior) => {
    handleSelectionClick(
      selectedExteriorColor,
      setselectedExteriorColor,
      setFilters,
      "exterior_color",
      exterior
    );
  };

  // interior color:
  const [selectedInteriorColor, setselectedInteriorColor] = useState([]);

  const handleInteriorClick = (interior) => {
    handleSelectionClick(
      selectedInteriorColor,
      setselectedInteriorColor,
      setFilters,
      "interior_color",
      interior
    );
  };

  // specs

  const [selectedSpecs, setselectedSpecs] = useState([]);

  const handleSpecsClick = (spec) => {
    handleSelectionClick(
      selectedSpecs,
      setselectedSpecs,
      setFilters,
      "specs",
      spec
    );
  };

  // cylinders

  const [selectedCylinder, setselectedCylinder] = useState([]);

  const handleCylinderClick = (cylinder) => {
    handleSelectionClick(
      selectedCylinder,
      setselectedCylinder,
      setFilters,
      "no_of_cylinders",
      cylinder
    );
  };

  // doors

  const [selectedDoors, setselectedDoors] = useState([]);

  const handleDoorsClick = (door) => {
    handleSelectionClick(
      selectedDoors,
      setselectedDoors,
      setFilters,
      "doors",
      door
    );
  };

  // checking search is preformed or not

  const [searchPerformed, setSearchPerformed] = useState(false);

  // pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(12);
  const [filters, setFilters] = useState({
    extras: null,
    location: null,
    kilometers: null,
    horsepwer: null,
    price: null,
    make: null,
    seating_capacity: null,
    body_type: null,
    transmission_type: null,
    make: null,
    fuel_type: null,
    exterior_color: null,
    interior_color: null,
    specs: null,
    no_of_cylinders: null,
    doors: null,
    year: null,
  });

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  let {
    loading: isLoadingMotors,
    error: errorLoadingProperties,
    data: motorsList,
  } = useQuery(GET_MOTORS, {
    variables: {
      page: currentPage,
      userId: null,
      categoryId: null,
      filters: null,
    },
  });

  const {
    loading: searchMotorsLoading,
    error: errorLoadingSearch,
    data: searchedMotors,
    refetch,
  } = useQuery(GET_MOTORS, {
    fetchPolicy: "cache-and-network",
    variables: {
      filters: {},
      page: null,
      userId: null,
    },
    notifyOnNetworkStatusChange: true,
  });


  const handleSearch = () => {
    const filtersSelected = Object.values(filters).some(
      (filter) => filter !== null && filter !== ""
    );

    if (filtersSelected) {
      refetch({
        filters: filters,
        page: currentPage,
      });
      setFiltersApplied(true);
      setSearchPerformed(true);
    } else {
      setShowFilterMessage(true);
    }
  };

  const resetFilters = () => {
    setFilters({
      extras: null,
      location: null,
      area_start: null,
      bathrooms: null,
      horsepwer: null,
      area_end: null,
      price: null,
      make: null,
      seating_capacity: null,
      body_type: null,
      transmission_type: null,
      make: null,
      fuel_type: null,
      exterior_color: null,
      interior_color: null,
      specs: null,
      no_of_cylinders: null,
      doors: null,
      year: null,
    });
  };

  const handleResetClick = () => {
    selectedBody([]);
    setselectedExtras([]);
    resetFilters();
  };

  useEffect(() => {
    if (searchMotorsLoading) {
      console.log("loading ", searchMotorsLoading);
    }
  }, [searchMotorsLoading]);

  useEffect(() => {
    if (errorLoadingSearch) {
      console.log("error ", errorLoadingSearch);
    }
  }, [errorLoadingSearch]);

  const {
    loading: isMakeLoading,
    error: errorLoadingMake,
    data: makeList,
  } = useQuery(GET_MOTORS_MAKE);

  useEffect(() => {
    console.log("loading", isMakeLoading);
  }, [isMakeLoading]);

  useEffect(() => {
    console.log("error loding makes", errorLoadingMake);
  }, [errorLoadingMake]);

  // Check if data is empty after refetching
  const handleSearchButtonClick = () => {
    handleSearch();
  };

  useEffect(() => {
    console.log("loading", isLoadingMotors);
  }, [isLoadingMotors]);

  const [adsToShow, setAdsToShow] = React.useState(12);

  // paginaitlon

  const totalProperties = motorsList?.motors?.total || 0;
  const totalPages = Math.ceil(totalProperties / propertiesPerPage);

  // Pagination Logic
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = Math.min(
    (currentPage - 1) * propertiesPerPage,
    totalProperties - propertiesPerPage
  );
  const currentProperties =
    motorsList?.motors?.data.slice(indexOfFirstProperty, indexOfLastProperty) ||
    [];

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // for map search

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  const resetSearchValue = () => {
    console.log("resetting ");
    setSearchValue("");
  };

  // sorting the properties

  const [sortBy, setSortBy] = useState("latest");

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const sortProperties = () => {
    if (
      sortBy === "latest" &&
      motorsList &&
      motorsList.motors &&
      motorsList.motors
    ) {
      const sortedData = [...motorsList.motors.data];
      sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
      motorsList = {
        ...motorsList,
        motors: {
          ...motorsList.motors,
          data: sortedData,
        },
      };
    } else if (
      sortBy === "default" &&
      motorsList &&
      motorsList.motors &&
      motorsList.motors
    ) {
      const sortedData = [...motorsList.motors.data];
      sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
      motorsList = {
        ...searchedMotors,
        motors: {
          ...searchedMotors.motors,
          data: sortedData,
        },
      };
    }
  };

  React.useEffect(() => {
    sortProperties();
  }, [sortBy]);

  return (
    <>
      <Helmet>
        <title>{t("motors.meta-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>
      <Box sx={{ minHeight: "100vh" }} className="box-padding">
        <Box
          padding="0 15px"
          margin="30px auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("motors.title")} />
        </Box>
        <Box
          padding="0 15px"
          margin="30px auto"
          gap={"5px !important"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Box display={"flex"} marginRight={"auto"}>
              {filterTypes.map((filter, index) => (
                <ControlledButton
                  key={index}
                  onClick={() => handleButtonClick(filter.name)}
                  className={`property-option ${
                    activeType === filter.name ? "active" : ""
                  }`}
                  value={filter.name}
                >
                  {filter.name}
                </ControlledButton>
              ))}
            </Box>
            {/* <Box marginLeft={'auto'} className="total-result">Total Results <span>{motorsList?.properties?.total}</span></Box> */}

            <Box marginLeft={"auto"} className="total-result">
              {t("rent-property.total-results")}{" "}
              <span>
                {searchPerformed
                  ? searchedMotors?.motors?.total
                  : motorsList?.motors?.total}
              </span>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <Autocomplete
                ListboxProps={{
                  className: "myCustomList",
                }}
                componentsProps={{
                  paper: {
                    sx: {
                      width: 830,
                      border: "1px solid #d1d1d1",
                      overflowX: "hidden",
                      marginTop: "10px",
                      padding: "20px",
                      fontSize: "14px !important",
                    },
                  },
                }}
                multiple
                limitTags={2}
                options={places}
                getOptionLabel={(option) => option.place_name}
                value={selectedPlaces}
                onChange={(event, newValue) =>
                  handlePlaceChange(event, newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ marginRight: 0 }}
                        >
                          <Icons.LOCATION
                            style={{
                              height: "24px",
                              width: "24px",
                              margin: "0 5px",
                            }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ marginRight: 0 }}
                        >
                          <Box className="selected-count">
                            {selectedPlaces.length}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    placeholder="City"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    sx={{
                      "& .MuiAutocomplete-input": {
                        padding: "0 !important",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "rgba(0, 0, 0, 0.7)",
                        fontSize: "14px !important",
                        fontStyle: "normal",
                        fontWeight: 400,
                      },
                    }}
                  />
                )}
                sx={{
                  "& fieldset": { border: "none", padding: 0 },
                  "& .MuiAutocomplete-inputRoot": {
                    width: "100%",
                    padding: "9px 0 !important",
                  },
                }}
                classes={{
                  root: classes.autocompleteRoot,
                  inputRoot: classes.inputRoot,
                }}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1">
                        {option.place_name}
                      </Typography>

                      <Checkbox
                        sx={{ marginRight: "8px" }}
                        checked={selected}
                        color="primary"
                      />
                    </Grid>

                    {selectedPlaces.length > 0 && (
                      <Typography
                        sx={{
                          backgroundColor: "#E4E4E4",
                          borderRadius: "50px",
                          fontSize: "14px !important",
                          padding: "12px 30px",
                          color: "#000",
                        }}
                      >
                        {selectedPlaces[0].place_name}
                      </Typography>
                    )}
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                Make <Icons.BTNICON />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  maxWidth: "100%",
                  "& .MuiPaper-root": {
                    width: "unset",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    padding: "15px 30px",
                    border: "1px solid #D1D1D1",
                    borderRadius: "8px",
                    marginTop: "7px",
                  },
                }}
              >
                {makeList?.motor_makes?.map((make) => (
                  <Box key={make.id} display={"flex"} alignItems={"center"}>
                    <MenuItem>{make.title}</MenuItem>
                  </Box>
                ))}
              </Menu>
            </Grid>

            <Grid item xs={6} md={4} lg={2}>
              <Button
                id="basic-button"
                aria-controls={filterOpennow ? "price-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={filterOpennow ? "true" : undefined}
                onClick={handleYearBtnClick}
              >
                Year <Icons.BTNICON />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={yearOpen}
                open={yearOpen}
                onClose={handleYearClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  maxWidth: "100%",
                  "& .MuiPaper-root": {
                    width: "unset",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "400px",
                    height: "220px",
                    overflowY: "auto",
                    borderRadius: "8px",
                    marginTop: "7px",
                  },
                }}
              >
                <YearPicker
                  startYear={startYear}
                  endYear={endYear}
                  onChange={handleYearChange}
                />
              </Menu>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <Button
                onClick={handlePriceBtnClick}
                id="price-button"
                aria-controls={filterOpennow ? "price-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={filterOpennow ? "true" : undefined}
                sx={{
                  border: "1px solid #d1d1d1",
                  borderRadius: "8px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Price <Icons.BTNICON />
              </Button>
              <Menu
                id="price-menu"
                anchorEl={priceOpen}
                open={priceOpen}
                onClose={handlePriceClose}
                MenuListProps={{
                  "aria-labelledby": "price-button",
                }}
                sx={{
                  maxWidth: "100%",
                  "& .MuiPaper-root": {
                    width: "unset",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "400px",
                    height: "220px",
                    overflowY: "auto",
                    borderRadius: "8px",
                    marginTop: "7px",
                  },
                }}
              >
                <PriceSlider
                  minValue={minPrice}
                  maxValue={maxPrice}
                  onChange={handlePriceChange}
                />
              </Menu>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                sx={{
                  border: "1px solid #d1d1d1",
                  borderRadius: "8px",
                  height: "48px",
                  width: "48px",
                }}
                id="filter-button"
                aria-controls={filterOpennow ? "filter-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={filterOpennow ? "true" : undefined}
                onClick={handleFilterBtnClick}
              >
                <Icons.FILTER />
              </IconButton>
              <Menu
                className="filters-menu"
                id="filter-menu"
                anchorEl={filterOpen}
                open={filterOpen}
                onClose={handleFilterClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                MenuListProps={{
                  "aria-labelledby": "#filter-button",
                }}
                sx={{
                  "& .MuiList-root": {
                    width: "1100px",
                    height: "500px",
                  },

                  "& .MuiPaper-root": {
                    minWidth: "max-content",
                    minHeight: "max-content",
                    maxHeight: "100%",
                    padding: "15px 30px",
                    border: "1px solid #D1D1D1",
                    borderRadius: "8px",
                    marginTop: "7px",
                  },
                }}
              >
                <Box>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Body Type
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                          }}
                        >
                          {bodyTYpe.map((body) => (
                            <li
                              key={body.id}
                              style={{
                                border: "1px solid #D1D1D1",
                                padding: "10px",
                                borderRadius: "10px",
                                fontSize: "14px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "400",
                                cursor: "pointer",
                                marginBottom: "15px",
                                maxWidth: "82px",
                                height: "90px",
                                textAlign: "center",
                              }}
                              onClick={() => handleBodyClick(body)}
                              className={
                                selectedBody.some(
                                  (selected) => selected.id === body.id
                                )
                                  ? "selected-amenity"
                                  : ""
                              }
                            >
                              {" "}
                              {body.title}
                            </li>
                          ))}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={12} lg={7}>
                      <Box
                        sx={{
                          marginTop: isMobile ? "20px" : "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Seat Capacity
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          {seatCapacity.map((seat) => (
                            <li
                              key={seat.id}
                              style={{
                                border: "1px solid #D1D1D1",
                                padding: "12px",
                                borderRadius: "10px",
                                fontSize: "14px",
                                fontWeight: "400",
                                cursor: "pointer",
                              }}
                              onClick={() => handleSeatCapacityClick(seat)}
                              className={
                                selectedSeatCapacity.some(
                                  (selected) => selected.id === seat.id
                                )
                                  ? "selected-amenity"
                                  : ""
                              }
                            >
                              {seat.title}
                            </li>
                          ))}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={5}>
                      <Box
                        sx={{
                          marginTop: isMobile ? "20px" : "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Transmission Type
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          {transmissionType.map((transmission) => (
                            <li
                              key={transmission.id}
                              style={{
                                border: "1px solid #D1D1D1",
                                padding: "12px",
                                borderRadius: "10px",
                                fontSize: "14px",
                                fontWeight: "400",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleTransmissionClick(transmission)
                              }
                              className={
                                selectedTransmission.some(
                                  (selected) => selected.id === transmission.id
                                )
                                  ? "selected-amenity"
                                  : ""
                              }
                            >
                              {transmission.title}
                            </li>
                          ))}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Box sx={{ marginTop: isMobile ? "20px" : "30px" }}>
                        <Typography
                          sx={{ fontWeight: 600, marginBottom: "10px" }}
                        >
                          Fuel Type
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          {fuelType.map((fuel) => (
                            <li
                              key={fuel.id}
                              style={{
                                border: "1px solid #D1D1D1",
                                padding: "12px",
                                borderRadius: "10px",
                                fontSize: "14px",
                                fontWeight: "400",
                                cursor: "pointer",
                              }}
                              onClick={() => handleFuelTypeClick(fuel)}
                              className={
                                selectedFuel.some(
                                  (selected) => selected.id === fuel.id
                                )
                                  ? "selected-amenity"
                                  : ""
                              }
                            >
                              {fuel.title}
                            </li>
                          ))}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={6} md={6} lg={3}>
                      <Box sx={{ marginTop: isMobile ? "20px" : "30px" }}>
                        <Typography
                          sx={{ fontWeight: 600, marginBottom: "10px" }}
                        >
                          Badges
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          {badges.map((badge) => (
                            <li
                              key={badge.id}
                              style={{
                                border: "1px solid #D1D1D1",
                                padding: "12px",
                                borderRadius: "10px",
                                fontSize: "14px",
                                fontWeight: "400",
                                cursor: "pointer",
                              }}
                              onClick={() => handleBadgeClick(badge)}
                              className={
                                selectedBadges.some(
                                  (selected) => selected.id === badge.id
                                )
                                  ? "selected-amenity"
                                  : ""
                              }
                            >
                              {badge.title}
                            </li>
                          ))}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={12} lg={5}>
                      <Box sx={{ marginTop: isMobile ? "20px" : "30px" }}>
                        <Typography
                          sx={{ fontWeight: 600, marginBottom: "10px" }}
                        >
                          Horsepower
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          <ShowMoreList
                            items={horsePower}
                            initialItemCount={6}
                            onItemClick={handleHorsePowerClick}
                            selectedItems={selectedHorsePower}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={12} lg={7}>
                      <Box sx={{ marginTop: isMobile ? "20px" : "30px" }}>
                        <Typography
                          sx={{ fontWeight: 600, marginBottom: "10px" }}
                        >
                          Exterior Color
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          <ShowMoreList
                            items={exteriorColor}
                            initialItemCount={7}
                            onItemClick={handleExteriorClick}
                            selectedItems={selectedExteriorColor}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={12} lg={5}>
                      <Box sx={{ marginTop: isMobile ? "20px" : "30px" }}>
                        <Typography
                          sx={{ fontWeight: 600, marginBottom: "10px" }}
                        >
                          Interior Color
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          <ShowMoreList
                            items={interiorColor}
                            initialItemCount={5}
                            onItemClick={handleInteriorClick}
                            selectedItems={selectedInteriorColor}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <Box sx={{ marginTop: isMobile ? "20px" : "30px" }}>
                        <Typography
                          sx={{ fontWeight: 600, marginBottom: "10px" }}
                        >
                          Regional Specs
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          <ShowMoreList
                            items={regionalSpecs}
                            initialItemCount={3}
                            onItemClick={handleSpecsClick}
                            selectedItems={selectedSpecs}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <Box sx={{ marginTop: isMobile ? "20px" : "30px" }}>
                        <Typography
                          sx={{ fontWeight: 600, marginBottom: "10px" }}
                        >
                          No of Cylinder
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          <ShowMoreList
                            items={no_ofCylinder}
                            initialItemCount={4}
                            onItemClick={handleCylinderClick}
                            selectedItems={selectedCylinder}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <Box
                        className="distance-slider"
                        sx={{
                          marginTop: isMobile ? "20px" : "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                          }}
                        >
                          Kilometer
                        </Typography>

                        <Box>
                          <AreaSlider
                            minAreaValue={minKilometerValue}
                            maxAreaValue={maxKilometerValue}
                            onChange={handleKiloMeterChange}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <Box
                        className="doors"
                        sx={{ marginTop: isMobile ? "150px" : "30px" }}
                      >
                        <Typography
                          sx={{ fontWeight: 600, marginBottom: "10px" }}
                        >
                          Doors
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          {doors.map((door) => (
                            <li
                              key={door.id}
                              style={{
                                border: "1px solid #D1D1D1",
                                padding: "12px",
                                borderRadius: "10px",
                                fontSize: "14px",
                                fontWeight: "400",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDoorsClick(door)}
                              className={
                                selectedDoors.some(
                                  (selected) => selected.id === door.id
                                )
                                  ? "selected-amenity"
                                  : ""
                              }
                            >
                              {door.title}
                            </li>
                          ))}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        className="extras"
                        sx={{
                          marginTop: isMobile ? "20px" : "46px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: "10px",
                          }}
                        >
                          Extras
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap !important",
                          }}
                        >
                          {displayedExtras.map((extra) => (
                            <li
                              key={extra.id}
                              style={{
                                border: "1px solid #D1D1D1",
                                padding: "12px",
                                borderRadius: "10px",
                                fontSize: "14px",
                                fontWeight: "400",
                                cursor: "pointer",
                              }}
                              onClick={() => handleAmenityClick(extra)}
                              className={
                                selectedExtras.some(
                                  (selected) => selected.id === extra.id
                                )
                                  ? "selected-amenity"
                                  : ""
                              }
                            >
                              {extra.title}
                            </li>
                          ))}

                          <Button
                            sx={{
                              background: "#000",
                              color: "#fff",
                              borderRadius: "8px",
                              fontSize: "14px !important",
                              fontWeight: "400",

                              "&:hover": {
                                background: "#000",
                              },
                            }}
                            onClick={handleExtrasButtonClick}
                          >
                            {showAll ? "Less" : "More"}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      marginTop: isMobile ? "20px" : "50px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: isMobile ? "start" : "end",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        gap: "22px",
                      }}
                    ></Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        marginTop: isMobile ? "20px" : "0px",
                        marginBottom: "20px",
                      }}
                    >
                      <Button
                        sx={{
                          background: "#fff",
                          border: "1px solid #D1D1D1",
                          width: "90px",
                          height: "38px",
                        }}
                        onClick={handleResetClick}
                      >
                        Reset
                      </Button>
                      <Button
                        sx={{
                          background: "#D1D1D1",
                          width: "90px",
                          height: "38px",
                        }}
                        onClick={handleClose}
                      >
                        Done
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Menu>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Button
                sx={{
                  background: theme.palette.primary.orange,
                  color: "#fff",
                  height: "48px",
                  borderRadius: "8px",
                  width: "100%",

                  "&:hover": {
                    backgroundColor: theme.palette.primary.orange,
                    color: "#fff",
                  },
                }}
                onClick={handleSearchButtonClick}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            gap={"30px"}
            marginTop={"50px"}
            overflowX={"hidden"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <IconButton className="icon-button">
              <Icons.BOOKMARK />
            </IconButton>
            <Box display={"flex"} gap={"20px"} className="filters-box">
              <Box display={"flex"} gap={"10px"}>
                <IconButton
                  className="icon-button"
                  sx={{
                    display: "flex",
                    gap: "10px",
                    backgroundColor: "#E4E4E4",
                  }}
                >
                  <Icons.VERIFIED /> Verified
                </IconButton>
                <IconButton
                  className="icon-button"
                  sx={{ display: "flex", gap: "10px" }}
                >
                  <Icons.MEMBER />
                  Members
                </IconButton>
              </Box>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortBy}
                  onChange={handleSortChange}
                  className="my-select"
                  sx={{
                    width: "100px",
                    height: "48px",
                    border: "1px solid #d1d1d1",
                    borderRadius: "8px",
                    fontSize: "14px !important",

                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <MenuItem
                    value="latest"
                    sx={{
                      fontSize: "15px !important",
                    }}
                  >
                    Latest
                  </MenuItem>
                  <MenuItem
                    value="default"
                    sx={{
                      fontSize: "15px !important",
                    }}
                  >
                    Default
                  </MenuItem>
                </Select>
              </FormControl>
              <Box display={"flex"} gap={"5px"}>
                <IconButton
                  className="icon-button"
                  onClick={handleListViewClick}
                >
                  <Icons.PREV />
                </IconButton>
                <IconButton
                  className="icon-button"
                  onClick={handleGridViewClick}
                >
                  <Icons.DASHBOARD />
                </IconButton>
              </Box>
            </Box>
          </Box>
          {/* property-area */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Box
                className={`properties-item ${
                  isGridView ? "grid-view" : "list-view"
                }`}
                style={{
                  display: "grid",
                  gridTemplateColumns: isMobile
                    ? "repeat(1, 0fr) !important"
                    : "repeat(3, 0fr)",
                  gridGap: "0",
                  placeContent: "start",
                }}
              >
                {filtersApplied
                  ? searchedMotors &&
                    searchedMotors.motors &&
                    searchedMotors.motors.data &&
                    searchedMotors.motors.data
                      .slice(0, adsToShow)
                      .map((ad, index) => (
                        <Box
                          key={index}
                          style={{
                            boxSizing: "border-box",
                            padding: "10px",
                            margin: "0",
                            width: "max-content",
                          }}
                        >
                          <MotorCard
                            classes={classes}
                            data={ad}
                            verifiedRankStyles={verifiedRankStyles}
                            featuredStyles={featuredStyles}
                            bedroomBathroomAspectStyles={
                              bedroomBathroomAspectStyles
                            }
                            statusStyles={statusStyles}
                            isMobile={isMobile}
                            lang={i18n.language}
                          />
                        </Box>
                      ))
                  : motorsList &&
                    motorsList.motors &&
                    motorsList.motors.data &&
                    motorsList.motors.data
                      .slice(0, adsToShow)
                      .map((ad, index) => (
                        <Box
                          key={index}
                          style={{
                            boxSizing: "border-box",
                            padding: "10px",
                            margin: "0",
                            width: "max-content",
                          }}
                        >
                          <MotorCard
                            classes={classes}
                            data={ad}
                            verifiedRankStyles={verifiedRankStyles}
                            featuredStyles={featuredStyles}
                            bedroomBathroomAspectStyles={
                              bedroomBathroomAspectStyles
                            }
                            statusStyles={statusStyles}
                            isMobile={isMobile}
                            lang={i18n.language}
                          />
                        </Box>
                      ))}
              </Box>
            </Grid>

            {/* <Grid item xs={12} md={12} lg={4}>
              <Box
                sx={{
                  background: "#E4E4E4",
                  textAlign: "center",
                  marginLeft: "40px",
                  marginTop: "10px",
                  borderRadius: "20px",
                  height: "740px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>Ad goes here</Typography>
              </Box>
            </Grid> */}
          </Grid>

          <Box sx={{ marginTop: "40px" }}>
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default MotorSearch;
