
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Message from '../../../components/message/Message';
import ChatForm from '../../../components/chat-form/ChatForm';
import { GET_CHATS } from '../../../api/queries/chat.queries';
import { useQuery } from '@apollo/client';
import { pusher } from '../../../pusher/pusher-config';
import { GET_MESSAGES } from '../../../api/queries/chat.queries';
import { useDispatch } from 'react-redux';
import conversationSlice, { chatInfo } from '../../../store/slices/conversationSlice';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { useTranslation } from 'react-i18next';
import Icons from '../../../assets/icons'
import './details.scss';
import Toaster from '../../../components/toaster/toaster.component';

const apiURL = process.env.REACT_APP_BASE_URL;

const ChatDetails = ({ selectedChat }) => {

    const [notifications, setNotifications] = useState([])

    const { i18n, t } = useTranslation("common");

    const dispatch = useDispatch();
    const [messages, setMessages] = useState([]);

    const [messageContent, setMessageContent] = useState('');
        const handleMessageChange = (text) => {
        setMessageContent(text);
    };
    const { loading: chatsloading, error: chatserror, data: chatsdata } = useQuery(GET_CHATS);

    useEffect(() => {}, [chatsloading]);

    useEffect(() => {}, [chatserror]);

    let queryVariables = null;
    if (selectedChat) {
        const { sender_id, receiver_id, category_id, item_id, id } = selectedChat;

        queryVariables = {
            chatId: parseInt(id),
            senderId: parseInt(sender_id),
            receiverId: parseInt(receiver_id),
            categoryId: parseInt(category_id),
            itemId: parseInt(item_id)
        };
    }

    const { loading, error, data } = useQuery(GET_MESSAGES, {
        variables: queryVariables,
        skip: !queryVariables
    });

    useEffect(() => {}, [loading]);

    useEffect(() => {
        if (data && data.getMessages) {
            setMessages(data.getMessages);
        }
    }, [data]);


    

    useEffect(() => {
        if (messages.length > 0) {
            const firstMessage = messages[0];
            let senderId = firstMessage.sender_id;
            let receiverId = firstMessage.receiver_id;

            const storedUser = localStorage.getItem('userData');
            const parsedUserData = storedUser ? JSON.parse(storedUser) : null;
            const userId = parsedUserData ? parsedUserData.id : null;

            receiverId = senderId == userId ? receiverId : senderId;
            senderId = userId;

            dispatch(chatInfo({ receiverId, senderId }));

            if (selectedChat && selectedChat.category_id && selectedChat.item_id && receiverId) {
                const { category_id, item_id } = selectedChat;
                const channelName = `private-chat_channel-${category_id}${item_id}${userId}${receiverId}`;
                const channel = pusher.subscribe(channelName);
                channel.bind('chat', (data) => {
                    setNotifications((prevNotifications) => [...prevNotifications, data])

                    if (data.sender_id !== userId) {
                        showNotification(data.content);
                    }           
                    setMessages((messages) => {
                        const updatedMessages = [...messages, data];
                        return updatedMessages.sort((a, b) => a.createdAt - b.createdAt);
                    });
                });

                return () => {
                    pusher.unsubscribe(channelName);
                };
            }
        }
    }, [messages, selectedChat]);

    


    useEffect(() => {
        const chatContainer = document.querySelector('.messages-container');
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }, [messages]);


    // show notifications:
    function showNotification(message) {
        if (typeof Notification !== 'undefined') {
            if (Notification.permission === 'granted') {
                new Notification('New Message', { body: message });
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        new Notification('New Message', { body: message });
                    }
                });
            }
        } else {
            alert(`New Message: ${message}`);
        }
    }
    return (
        <>
            {selectedChat ? (
                <Box className="chat-details">
                    {chatsdata &&
                        chatsdata.getChats &&
                        chatsdata.getChats.map((chat, id) => (
                            id === 0 && (
                                <>
                                    <Box
                                        key={chat.id}
                                        display={'flex'}
                                        gap={'15px'}
                                        sx={{
                                            background: '#f5f5f5',
                                            borderRadius: '15px',
                                            padding: '10px',
                                            marginTop: '30px',
                                            alignItems: 'center',
                                            border: '1px solid #e9e9e9'
                                        }}>
                                            {
                                                selectedChat.item && selectedChat.item.image && selectedChat.item.image.includes("null")? (

                                                    <Icons.NO_IMAGE    
                                                    style={{
                                                        width: '100px',
                                                        height: '100px',
                                                        borderRadius: '15px'
                                                    }}/>
                                                  
                                                )
                                                : 
                                                (
                                                    <img
                                                    src={`${apiURL}${selectedChat?.item.image}`}
                                                    
                                                    style={{
                                                        width: '100px',
                                                        height: '100px',
                                                        borderRadius: '15px'
                                                    }}
                                                    />
                                                )
                                            }
                                       
                                     
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: '20px !important',
                                                    color: '#000',
                                                    textTransform: 'capitalize',
                                                    fontWeight: '500 !important'
                                                }}>
                                                {selectedChat?.item.title}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '16px !important',
                                                    fontWeight: '500 !important',
                                                    color: '#000'
                                                }}>
                                                AED {selectedChat?.item.price}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        alignItems={'end'}>
                                        <Typography
                                            sx={{
                                                fontSize: '16px !important',
                                                color: '#808080',
                                                fontWeight: '700'
                                            }}>
                                            Today
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '16px',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                marginTop: '15px'
                                            }}>
                                            <Box
                                                sx={{
                                                    position: 'relative'
                                                }}>

                                                {
                                                    selectedChat?.receiver?.image? (
                                                        <img
                                                        src={`${apiURL}${selectedChat?.receiver?.image}`}
                                                        alt="user"
                                                        style={{
                                                            width: '56px',
                                                            height: '56px',
                                                            filter: 'grayScale(100%)',
                                                            border: '3px solid #e5e5e5',
                                                            borderRadius: '50px'
                                                        }}
                                                    />
                                                    )
                                                    :
                                                    (
                                                      <Icons.NEW_LOGO style={{
                                                        background: "#fff",
                                                        width:"57px",
                                                        height: "57px",
                                                        border:'1px solid #e5e5e5',
                                                        borderRadius:"50px",
                                                        padding:"5px",
                                                        filter: 'grayScale(100%)'
                                                      }} />
                                                    )
                                                }

                                               
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        color: '#000',
                                                        fontSize: '14px !important',
                                                        fontWeight: '600 !important'
                                                    }}>
                                                    {selectedChat?.receiver?.first_name}{' '}
                                                    {selectedChat?.receiver?.last_name}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px !important'
                                                    }}>
                                                    Live now
                                                </Typography>
                                            </Box>
                                            {/* <ChatBubbleIcon style={{ color: '#a6a6a6' }} /> */}
                                        </Box>
                                    </Box>
                                </>
                            )
                        ))}
                    <Box className="messages-container">
                        <Message selectedChat={selectedChat} messages={messages} onMessageChange={handleMessageChange} setMessages={setMessages}/>
                      
                    </Box>
                    <ChatForm selectedChat={selectedChat} onMessageChange={handleMessageChange} setMessages={setMessages} messages={messages}/>

            
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        margin: '0 auto'
                    }}>
                    <ChatBubbleIcon
                        fontSize="large"
                        sx={{
                            background: 'rgba(248, 206, 38, 0.30)',
                            width: '120px',
                            height: '100px',
                            padding: '20px',
                            borderRadius: '30px',
                            marginBottom: '20px'
                        }}
                    />
                    <Typography sx={{ fontWeight: '500' }}>{t('chat-details.no-chat')} </Typography>
                    <Typography sx={{ fontWeight: '300' }}>
                        {t('chat-details.select-chat')}
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default ChatDetails;

