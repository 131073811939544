import FormBox from "../../../components/form-box/form-box.component";
import { useTranslation } from "react-i18next";
import Icons from "../../../assets/icons";
import * as yup from 'yup';
import { useState } from "react";
import { Alert, Collapse, IconButton, useTheme } from "@mui/material";
import CustomTelInput from "../../../components/tel-input/tel-Input.component";
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { useFormik } from "formik";
const ByPhoneNumber = () => {
    const { t } = useTranslation("common");
    const theme = useTheme();
    const navigate = useNavigate();
    const [openAlert, setOpenAlert] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const byPhoneNumberSchema = yup.object().shape({
        phoneNumber: yup.string().nullable().test('is-valid-phone', t('authentication.login.invalid_no_phone'), (value) => {
            if (value === null || value === '') {
                return true;
            }
            try {
                const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
                const number = phoneUtil.parseAndKeepRawInput(value, '');
                return phoneUtil.isValidNumber(number);
            }
            catch (error) {
                return false;
            }
        }).required(t('authentication.login.phone_no_required')),
    });
    const onSubmitByPhoneNumber = async (event) => {
        event.preventDefault();
        try {
            const phoneNumber = formik.values.phoneNumber.replace(/\s/g, '');
            formik.setTouched({ phoneNumber: true });
            byPhoneNumberSchema.validateSync({ phoneNumber }, { abortEarly: false });
            navigate(ROUTES.OTP_CODE, {
                state: {
                    data: {
                        phoneNumber: phoneNumber,
                        key: 'forget-pass-by-phone'
                    }
                }
            });
            formik.resetForm();
            formik.submitForm();
        }
        catch (error) {
            if (error.name === 'ValidationError') {
                const validationErrors = {};
                error.inner.forEach((validationError) => {
                    validationErrors[validationError.path] = validationError.message;
                });
                formik.setErrors(validationErrors);
            }
            else {
                const { message } = error;
                setErrorMessage(message);
                setOpenAlert(true);
            }
        }
    };
    const handleClose = () => { setOpenAlert(false); };
    const formik = useFormik({
        initialValues: {
            phoneNumber: "",
        },
        validationSchema: byPhoneNumberSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await onSubmitByPhoneNumber(values);
                setSubmitting(false);
            }
            catch (error) {
                setSubmitting(false);
            }
        },
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
    });
    return (<>
            {errorMessage && (<Collapse in={openAlert}>
                    <Alert severity="error" action={<IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
                                <Icons.CLOSE />
                            </IconButton>}>
                        {errorMessage}
                    </Alert>
                </Collapse>)}
            <FormBox onSubmit={(event) => onSubmitByPhoneNumber(event)} initialValues={{ phoneNumber: '' }} sx={{ display: "grid" }} submitValue={t('authentication.forget_password.continue')} submitStyle={{
            borderRadius: 40,
            background: theme.palette.primary.orange,
            color: theme.palette.secondary.main,
            textTransform: 'capitalize',
            marginTop: 10
        }}>
                <CustomTelInput {...formik.getFieldProps('phoneNumber')} name={'phoneNumber'} label={'Phone Number'} error={formik.touched['phoneNumber'] && Boolean(formik.errors['phoneNumber'])} helperText={formik.touched['phoneNumber'] && formik.errors['phoneNumber']} value={formik.values['phoneNumber']} onChange={(value) => formik.setFieldValue('phoneNumber', value)}/>
            </FormBox>
        </>);
};
export default ByPhoneNumber;
