import { createTheme, responsiveFontSizes } from "@mui/material";


export let theme = createTheme({
    palette: {
        primary: {
            main: '#212121', // black
            orange: '#FE820E', //orange
            blue: "#79A7C9"  
        },
        secondary: {
            main: '#FFF' // white
        },
        error: {
            main: '#DC3545' // red
        }
    },
    typography: {
        fontFamily: 'Roboto',
        button: { textTransform: 'none' },
        fontSize: 16,
        body2: {
            fontWeight: 400,
            fontSize: 16
        },
        h1: {
            fontSize: 60,
            fontStyle: 'normal',
            fontWeight: 600,
            // textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
        },
        h2: {
            fontSize: 35,
            fontStyle: 'normal',
            fontWeight: 700
        },
        h3: {
            fontSize: '1.17em',
            fontWeight: 'bold'
        },
        h4: {
            fontSize: '1.33em',
            fontWeight: 'bold'
        },
        h5: {
            fontSize: '0.83em',
            fontWeight: 'bold'
        },
        h6: {
            fontSize: '0.67em',
            fontWeight: 'bold'
        }
    }
});
theme = responsiveFontSizes(theme);
