import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import SectionTitle from "../../../components/section-title/section-title.component";
import { Helmet } from "react-helmet";
import axios from "axios";
import Toaster from "../../../components/toaster/toaster.component";
import { setCountryId } from "../../../store/slices/countrySlice";
import Icons from "../../../assets/icons";
import { useDispatch } from "react-redux";
import * as ROUTES from "../../../constants/routes";

const apiURL = process.env.REACT_APP_REST_API_URL;

const CountrySelection = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    axios
      .get(`${apiURL}/countries`)
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  }, []);

  const handleChange = (event) => {
    const selectedCountryName = event.target.value;
    setSelectedCountry(selectedCountryName);

    const selectedCountryData = countries.find(
      (country) => country.name === selectedCountryName
    );

    if (selectedCountryData) {
      dispatch(setCountryId(selectedCountryData.id));

      console.log(selectedCountry, 'seellct country')
      if (selectedCountryData.active_for_listing === 1) {
        navigate(ROUTES.POST_AD);
      } else if (selectedCountryData.active_for_listing === 0) {
        setOpenAlert(true);
        setOpenSuccess(true);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("post-ad-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>

      <Box
        style={{
          gap: 20,
          display: "grid",
          margin: "35px 0",
          padding: 16,
        }}
      >
        <Box
          margin="auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("post-ad.place-ad")} />
          <Typography color={theme.palette.primary.main}>
            {t("post-ad.country-description")}
          </Typography>
        </Box>

        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item xs={3}>
            <Box style={{ width: "100%" }}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="country-select" htmlFor="select">
                  {t('country.select-label')}
                </InputLabel>
                <Select
                  native
                  labelId="country-select"
                  id="select"
                  value={selectedCountry}
                  onChange={handleChange}
                  sx={{
                    color: "#000 !important",
                    "&:focus": {
                      border: "none",
                    },
                  }}
                  label="Select Country"
                >
                  {countries.map((option) => (
                    <option
                      style={{ color: "#000" }}
                      key={option.id}
                      value={option.name}
                    >
                      {option.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Toaster
        open={openSuccess}
        title={`${t("country.toast-title")}!`}
        description={t("country.toast-description")}
        handleClose={handleCloseSuccess}
        icon={<Icons.CLOSE style={{ margin: "15px 0" }} />}
      />
    </>
  );
};

export default CountrySelection;
