import gql from 'graphql-tag';


const EMAIL_LOGIN_MUTATION = gql `
mutation Login($email: String, $phone: String, $type: String, $password: String, $loginId: String, $rememberMe: Int) {
  login(email: $email, phone: $phone, type: $type, password: $password, id: $loginId, remember_me: $rememberMe) {
    user {
      id
      image
      first_name
      last_name
      email
      phone
      is_premium
      createdAt
    }
    token
    refresh_token
    message
    status
  }
}
`;
const PHONE_NUMBER_LOGIN_MUTATION = gql `
  mutation Login($password: String!, $phoneNumber: String, $type: String, $loginId: String) {
    login(password: $password, phone: $phoneNumber, type: $type, id: $loginId) {
      message
      refresh_token
      status
      token
      user {
        phone
        last_name
        is_premium
        image
        id
        first_name
        email
        createdAt
      }
    }
  }
`;
const EMAIL_REGISTER_MUTATION = gql `
mutation Register($firstName: String, $lastName: String, $email: String, $password: String, $phone: String, $type: String, $registerId: String) {
  register(first_name: $firstName, last_name: $lastName, email: $email, password: $password, phone: $phone, type: $type, id: $registerId) {
    user {
      id
      image
      first_name
      last_name
      email
      phone
      is_premium
      createdAt
    }
    token
    refresh_token
    message
    status
  }
}
`;
const PHONE_NUMBER_REGISTER_MUTATION = gql `
mutation Register($password: String!, $phoneNumber: String, $lastName: String, $firstName: String) {
  register(password: $password, phone: $phoneNumber, last_name: $lastName, first_name: $firstName) {
    message
    status
    token
    user {
      email
      first_name
      id
      image
      last_name
      phone
    }
  }
}
`;
const USER_VERIFICATION_MUTATION = gql `
mutation UserVerfication($value: String, $type: String) {
  userVerfication(value: $value, type: $type) {
    isExist
    status
    token
  }
}
`;
const UPDATE_PASSWORD_BY_EMAIL_MUTATION = gql `
mutation UpdatePassword($password: String!) {
  updatePassword(password: $password) {
    message
    status
  }
}
`;


const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUser($values: JSON, $userId: Int) {
    updateUser(values: $values, user_id: $userId) {
      first_name
      last_name
      image
      email
      phone
    }
  }
`;


const DELETE_USER = gql`
mutation DeleteUser {
  deleteUser {
    user {
      id
      image
      first_name
      last_name
      email
      phone
      is_premium
    }
    token
    refresh_token
    message
    status
  }
}`;


export { EMAIL_LOGIN_MUTATION, PHONE_NUMBER_LOGIN_MUTATION, EMAIL_REGISTER_MUTATION, PHONE_NUMBER_REGISTER_MUTATION, USER_VERIFICATION_MUTATION, UPDATE_PASSWORD_BY_EMAIL_MUTATION, UPDATE_USER_PROFILE_MUTATION, DELETE_USER };
