import { Box, Typography, useTheme } from "@mui/material";
import grayShadowEn from '../../assets/images/orange-shadow-en.svg';
import grayShadowAr from '../../assets/images/gray-shadow-ar.svg';
import grayShadowSmEn from '../../assets/images/gray-shadow-sm-en.svg';
import grayShadowSmAr from '../../assets/images/gray-shadow-sm-ar.svg';
import { useTranslation } from "react-i18next";
const SectionTitle = (props) => {
    const { title, variant } = props;
    const theme = useTheme();
    const { i18n } = useTranslation('common');
    return (<Box sx={{
            backgroundImage: `url(${variant === "h1" && i18n?.language === "en" ?
                grayShadowEn
                : variant === "h1" && i18n?.language === "ar" ?
                    grayShadowAr
                    : variant !== "h1" && i18n?.language === "en" ?
                        grayShadowSmEn
                        : variant !== "h1" && i18n?.language === "ar" ?
                            grayShadowSmAr : ''})`,
            backgroundSize: 'inherit',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: i18n?.language === "en" ? 'left' : 'right',
            backgroundPositionY: 'bottom',
            position: 'relative',
            width: 'auto',
            margin: 'auto'
        }}>
            <Typography variant={variant} style={{ position: 'relative', zIndex: 1, lineHeight: variant !== "h1" ? 'unset' : '' }} color={theme.palette.primary.main}>
                {title}
            </Typography>
        </Box>);
};
export default SectionTitle;
