import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { makeStyles } from "@mui/styles";
import NoImg from "../../assets/icons/new-logo.svg";


const useStyles = (props) =>
  makeStyles(() => ({
    carouselContainer: {
      width: "100%",
      height: "100%",
      position: "relative",
      direction: "ltr",
      "& .carousel.carousel-slider": {
        height: "100%",
        borderRadius: "8px",
      },
      "& .thumbs-wrapper": {
        textAlign: "center",
      },
      "& .thumbs": {
        display: props?.hideThumbs ? "none" : "contents",
      },
      "& .thumb": {
        width: "60px",
        height: "60px ",
        cursor: "pointer",
      },
      "& .carousel": {
        position: "absolute",
        bottom: 0,
      },
      "& .control-dots": {
        display: "none",
      },
      "& .slider-wrapper .axis-horizontal": {
        height: "100%",
      },
      "& .carousel .slider": {
        height: "100%",
      },
      "& .carousel .slider-wrapper": {
        height: "100%",
      },
      "& .carousel .carousel-status": {
        display: "none",
      },
    },
  }));
const ResponsiveCarousel = (props) => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  

  const { carouselContainer } = useStyles(props)();
  return (
    <Carousel className={`${carouselContainer} crsl`} infiniteLoop onClickItem={(index) => props.onImageClick(index)}>
      {props?.images.map((img, index) => (
        <img
          loading="lazy"
          src={baseURL + img.image}
          alt={img.__typename}
          width="100%"
          height="100%"
          key={index}
       
          onError={(e) => {
            e.target.src = NoImg; 
            e.target.style.filter = "grayScale(1)";
            e.target.style.border = "1px solid #f5f5f5";
            e.target.style.padding = "5px";     
          }}
        
        />
      ))}
    </Carousel>
  );
};

export default ResponsiveCarousel;
