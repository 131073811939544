import React, { useState, useEffect } from 'react'
import { Grid, useMediaQuery, Box, Typography, useTheme, IconButton, Stack, Button } from '@mui/material'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import SectionTitle from '../../components/section-title/section-title.component'
import { CustomTab, CustomTabPanel } from '../../components/tabs/tabs.component'
import './MyAds'
import MyAds from './MyAds'
import Icons from '../../assets/icons'
import { GET_PROPERTIES } from '../../api/queries/home.queries'
import { useQuery } from '@apollo/client';
import IconWithBadge from '../../components/icon-with-badge/icon-with-badge.component'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import './ProfileForm'
import ProfileForm from './ProfileForm'
import { DELETE_USER } from '../../api/queries/auth.queries'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import * as ROUTES from "../../constants/routes.js";
import { Container } from '@mui/system'



const appURL = process.env.REACT_APP_BASE_URL

const TypoStyle1 = {
  fontSize: '16px',
  fontWeight: '700',
  color: '#000',
  width: '54px'
}

const TypoStyle2 = {
  fontSize: '16px',
  fontWeight: '400',
  color: '#000'
}


const Profile = ({ updatedUserData }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const { t, i18n } = useTranslation('common');
  const theme = useTheme();
  const location = useLocation()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [showEditForm, setShowEditForm] = useState(false);


  const handleEditProfileClick = () => {
    setShowEditForm(true);
  };
  const handleCloseForm = () => {
    setShowEditForm(false);
  };

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    navigate(`${ROUTES.PROFILE}?tab=${newValue}`); 
  };

 // for tab base routing from the menu

 const getTabFromUrl = () => {
  const params = new URLSearchParams(location.search);
  const tab = params.get('tab');
  return tab === '1'? 1 : 0;
}


  useEffect(() => {
    setTabValue(getTabFromUrl());
  }, [location]);

  let userData = localStorage.getItem('userData')
  let user = JSON.parse(userData)
  let userId = parseInt(user.id) 


  
  const { loading, error, data } = useQuery(GET_PROPERTIES, {
    variables: {
      "page": null,
      "userId": userId
    },
  })
  const totalAds = parseInt(data?.properties?.total)
  const getTitleForTab = (tabValue) => {
    switch (tabValue) {
      case 0:
        return t('profile.ads-title');
      case 1:
        return showEditForm ? t('profile.edit-profile-title') : t('profile.profile-title');
      default:
        return '';
    }
  };
  const ListedAdOptions = [
    { title: t('profile.live'), total: 24 },
    { title: t('profile.pending'), total: 14 },
    { title: t('profile.expired'), total: 10 },
    { title: t('profile.rejected'), total: 0 }
  ]

  // deelte USer 

  const [deleteUserMutation, {deleteloading, deleterror}] = useMutation(DELETE_USER)



  const handleDelteUser = async () => {

    try{
      const { data } = await deleteUserMutation({
        variables: {
          userId: userId 
        }
      });
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      console.log('user deleted')
    }
    catch (error) {
      console.error('Error deleting user:', error);
    }
  }


 

  return (



    <>
      <Helmet>
        <title>{t('profile.title')}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no" />
      </Helmet>

      <Container>

     
      <Box sx={{ minHeight: '100vh' }}>
        <Box padding="0 15px" margin="30px auto" gap={'5px !important'} display="grid" sx={{ placeItems: "center" }}>
          <SectionTitle variant={'h2'} title={getTitleForTab(tabValue)} />
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <CustomTab tabValue={tabValue} setTabValue={setTabValue}>
          
            <CustomTabPanel label={t('profile.ads-title-second')}>
              <MyAds />
            </CustomTabPanel>
            <CustomTabPanel label={t('profile.title')} >


              {
                showEditForm ? (
                  <ProfileForm handleCloseForm={handleCloseForm}/>
                )

                : 

                (

                  <Box sx={{marginTop: '100px' }}>

                  <Stack direction='row' justifyContent={'end'} sx={{
                    gap: '34px'
                  }} className="profile-actions">
                    <Button sx={{border: '1px solid #D9D9D9', borderRadius: '8px', padding:'12px 15px', display:'flex',flexDirection: i18n?.language === "en"? "row" : "row-reverse", alignItems: 'center', gap: '10px'}} onClick={handleDelteUser}>{t('profile.delete-profile')} <Icons.DELETE_ICON />  </Button>
                    <Button sx={{background: '#FE820E', color: '#fff', borderRadius: '8px', padding:'12px 15px', display:'flex', flexDirection: i18n?.language === "en"? "row" : "row-reverse", alignItems: 'center', gap: '10px',
                    '&:hover':{
                      backgroundColor:'#FE820E'
                    }
                  
                  }} onClick={handleEditProfileClick}> {t('profile.edit-profile-title')} <Icons.EDIT_ICON /> </Button>
                  </Stack>



                <IconButton className="icon-button" sx={{
                  display: 'flex', gap: '10px', background: '#EFEFEF', borderRadius: '50px !important', border: 'none !important', width: '120px', fontWeight: '400 !important'
                }}><Icons.MEMBER />{t('profile.member')}</IconButton>
                <Grid container sx={{ marginTop: '24px' }}>
                  <Grid item lg={7} xs={12}>
                    <Box sx={{
                      display: 'flex',
                      gap: '64px'
                    }}
                    className="profile-top"
                    >
                      <img src={`${appURL}${user?.image}`} alt="user" style={{
                        width: '235px',
                        borderRadius: '50%',
                        height: '235px',
                        border: '1px solid #DBDBDB'
                      }}
                      className='profile-image'
                      />
                      <Box>
                        <Stack direction='row' gap={'70px'} alignItems={'center'}>
                          <Typography sx={{
                            fontSize: '18px',
                            fontWeight: '700',
                            color: '#000'
                          }}>{user.first_name} {user.last_name}</Typography>
                          <IconWithBadge icon={<FiberManualRecordIcon style={{ width: 20, height: 20, color: '#44C030' }} />} title={'Active'} badgeStyle={{
                            margin: '8px 0px!important',
                            display: 'flex',
                            alignItems: 'center',
                            background: '#EFEFEF',
                            width: '82px',
                            justifyContent: 'center',
                            borderRadius: '50px',
                            height: '40px',
                            fotnSize: '14px',
                            color: '#000',
                            fontWeight: '500 !important'
                          }} />
                        </Stack>
                        <Typography sx={{ fontSize: '16px !important', fontWeight: '400', color: '#000', marginTop: '20px' }}>Agency : Cash Gate LLC</Typography>
                        <Box sx={{ marginTop: '50px' }}>
                          <Typography sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px', fontSize: '16px !important', fontWeight: '400', color: '#000'
                          }}><Icons.MEMBER_SINCE /> Since {user.createdAt}</Typography>
                          <Typography sx={{ fontSize: '16px !important', fontWeight: '400', color: '#000', marginTop: '20px' }}>{t('profile.language')}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Stack sx={{ marginTop: '100px', gap: '50px' }} direction="row" className='review-user' >
                      <Box>
                        {/* <Typography sx={{ fontSize: '16px !important', fontWeight: '700', color: '#000', marginBottom: '18px' }}>About Me</Typography> */}
                        {/* <Typography sx={{ width: '250px', fontSize: '15px !important' }}>Quisque imperdiet sem et enim luctus, eu dapibus orci iaculis. In tristique dignissim nulla, id placerat felis ornare fringilla.
                          Proin quis libero ac diam cursus egestas non at nibh. Aliquam in dapibus dolor.  Proin quis libero ac diam cursus egestas non at nibh. Aliquam in dapibus dolor.  </Typography> */}
                        <Box width={'120px'} className="user-review-items">
                          <Box margin={'0px 0px 0px 0px'} sx={{ cursor: 'pointer' }}>
                            <Typography style={{ color: '#000', fontSize: '16px', fontWeight: '700' }}>(261)  Reviews</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '22px', alignItems: 'flex-start' }}>
                            <IconWithBadge icon={<Icons.LIKE />} title={''} variant={'h6'} />
                            <IconWithBadge icon={<Icons.DISLIKE />} title={''} variant={'h6'} />
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ color: '#000', fontSize: '16px', fontWeight: '700' }} >(200) </Typography>
                            <Typography style={{ color: '#000', fontSize: '16px', fontWeight: '700' }}>(31)</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{marginLeft: '130px'}} className="user-contact">
                      {user.phone && user.phone !== 'null' && (
                      <Stack direction="row" sx={{ gap: '40px', marginBottom: '40px' }}>
                        <Typography style={TypoStyle1}>{t('profile.phone')}</Typography>
                        <Typography style={TypoStyle2}>{user.phone}</Typography>
                      </Stack>
                    )}
                      <Stack direction="row" sx={{ gap: '40px', marginBottom: '40px' }}>
                          <Typography style={TypoStyle1}>{t('profile.email')}</Typography>
                          <Typography style={TypoStyle2}>{user.email}</Typography>
                        </Stack>
                        {/* <Stack direction="row" sx={{ gap: '40px', marginBottom: '40px' }}>
                          <Typography style={TypoStyle1}>Address</Typography>
                          <Typography style={TypoStyle2}>+971501234455</Typography>
                        </Stack> */}
                        {/* <Stack direction="row" sx={{ gap: '40px', marginBottom: '40px' }}>
                          <Typography style={TypoStyle1}>Birthday</Typography>
                          <Typography style={TypoStyle2}>June 5, 1995</Typography>
                        </Stack> */}
                        {/* <Stack direction="row" sx={{ gap: '40px', marginBottom: '40px' }}>
                          <Typography style={TypoStyle1}>Gender</Typography>
                          <Typography style={TypoStyle2}>Male</Typography>
                        </Stack> */}
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item lg={5} xs={12} sx={{ marginTop: '50px' }} className='user-ads'>
                    <Typography sx={{ fontSize: '16px !important', fontWeight: '700', color: '#000', textDecoration: 'underline', marginBottom: '24px' }}>{t('profile.total')} {totalAds} {t('profile.listed')}</Typography>
                    <Grid container spacing={2}>
                      {ListedAdOptions.map((option, index) => (
                        <Grid item key={index} lg={6} xs={4}>
                          <Box
                            sx={{
                              boxShadow: '0px 4px 24px rgba(0,0,0,5%)',
                              borderRadius: '20px',
                              textAlign: 'center',
                              padding: '40px 0px 60px 0px',
                            }}

                            className="ads-items"
                          >
                            <Typography sx={{ marginBottom: '28px', fontSize: '16px !important', fontWeight: '600' }}>{option.title}</Typography>
                            <Typography sx={{ fontSize: '30px !important', fontWeight: '600' }} className='total-ads'>{option.total}</Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
                )
              }


            



            </CustomTabPanel>
          </CustomTab>
        </Box>
      </Box>

      </Container>
    </>
  )
}

export default Profile
