import React, { useState } from "react";
import {
  Box,
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Container,
  Stack,
  TextField,
} from "@mui/material";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SectionTitle from "../../components/section-title/section-title.component";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import "./careers.scss";
import Icons from "../../assets/icons";
import IconWithBadge from "../../components/icon-with-badge/icon-with-badge.component";
import ControlledButton from "../../components/Button/button.component";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

const CareerCard = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(ROUTES.CAREERS_DETAILS);
  };
  return (
    <Box
      sx={{
        border: "1px solid #E1E1E1",
        borderRadius: "30px",
        padding: "38px 40px",
        cursor: "pointer",
      }}
      onClick={handleCardClick}
    >
      <Typography
        variant="h3"
        sx={{ fontSize: "20px !important", marginBottom: "22px" }}
      >
        Lorem ispum dollar site amet
      </Typography>
      <Typography sx={{ fontSize: "16px !important", marginBottom: "22px" }}>
        Suspendisse sit amet luctus justo, vitae varius lacus. Nunc lacus odio,
        interdum id tristique eget, consequat eget mauris.
      </Typography>
      <Box>
        <IconWithBadge
          icon={<Icons.LOCATION style={{ width: 20, height: 20 }} />}
          title={"Dubai, UAE"}
          badgeStyle={{
            margin: "8px 0px!important",
            display: "flex",
            alignItems: "center",
            color: theme.palette.primary.main,
          }}
          type={"location"}
        />
      </Box>
    </Box>
  );
};

const Careers = () => {
  const [activeIndex, setActiveIndex] = useState(3);

  const handleChange = (_, newIndex) => setActiveIndex(newIndex);

  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  let someStyles = {
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    textWrap: "nowrap",
    textTransform: "capitalize",
    borderRadius: 41,
    boxShadow: "0px 4px 32px 0px rgba(104, 104, 104, 0.99)",
  };
  let getStartedStyles = isMobile
    ? {
        ...someStyles,
        right: i18n?.language === "en" ? "0" : "auto",
        left: i18n.language === "en" ? "auto" : "-32.5px",
        width: i18n.language === "en" ? "unset" : "90px",
      }
    : {
        ...someStyles,
        right: i18n?.language === "en" ? "-32.5px" : "auto",
        left: i18n.language === "en" ? "auto" : "-32.5px",
      };

  return (
    <>
      <Helmet>
        <title>{t("careers.title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>
      <Container>
        <Box
          margin="70px auto 0px auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("careers.banner-title")} />
        </Box>

        <Stack
          direction="row"
          display="flex"
          padding={isMobile ? "5px 0" : "7px 0px !important"}
          borderRadius={90}
          sx={{ background: "#F3F3F3", margin: "70px auto 70px auto" }}
          width={isMobile ? "100%" : "70%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"6px"}
            padding={isMobile ? "0 6px" : "0 15px"}
            sx={{ cursor: "pointer" }}
          >
            {t("careers.category")}
            <Icons.ARROW_DOWN />
          </Box>
          <Box className="search_box_wrapper">
            <Box className="search_box_item">
              <Box className="search_box">
                {/* <CustomSelect options={categoriesList?.categories} selected={selectedCategory} onSelectChange={onCategorySelectChange} getOptionLabel={(option) => option?.title || ''} placeholder={t('hero.search-vehicles')} searchable={true} indicator={() => null} clearable={true} icon={<Icons.SEARCH />} fullWidth={'100%'} styles={{ padding: '20px' }} className='input_search' borderRadius={'90px'} background={'#FFF'}/> */}

                <TextField
                  className="search-field"
                  placeholder={t("careers.search-placeholder")}
                  sx={{
                    background: "#fff",
                    borderRadius: "60px !important",
                    height: "75px",
                    width: "95%",
                    color: "#000",

                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        height: "2.4375em",
                      },
                      "&:hover fieldset": {
                        borderColor: "none",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "none",
                      },
                    },
                  }}
                />
              </Box>
              <ControlledButton
                customStyle={getStartedStyles}
                value={t("careers.search-btn")}
              />
            </Box>
          </Box>
        </Stack>

        <Box className="careers-tabs" style={{ display: "flex" }}>
          <VerticalTabs value={activeIndex} onChange={handleChange}>
            <MyTab label="USA" />
            <MyTab label="Australia" />
            <MyTab label="Egypt" />
            <MyTab label="United Arab Emirates" />
            <MyTab label="Canada" />
            <MyTab label="Pakistan" />
            <MyTab label="India" />
          </VerticalTabs>

          {activeIndex === 0 && (
            <TabContainer>
              {" "}
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <CareerCard />
                </Grid>
              </Grid>
            </TabContainer>
          )}
          {activeIndex === 1 && (
            <TabContainer>
              {" "}
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <CareerCard />
                </Grid>
                <Grid item xs={4}>
                  <CareerCard />
                </Grid>
              </Grid>
            </TabContainer>
          )}
          {activeIndex === 2 && (
            <TabContainer>
              {" "}
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} sm={12}>
                  <CareerCard />
                </Grid>
              </Grid>
            </TabContainer>
          )}
          {activeIndex === 3 && (
            <TabContainer>
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} sm={12}>
                  <CareerCard />
                </Grid>
                <Grid item lg={4} md={6} sm={12}>
                  <CareerCard />
                </Grid>
                <Grid item lg={4} md={6} sm={12}>
                  <CareerCard />
                </Grid>
              </Grid>
            </TabContainer>
          )}
          {activeIndex === 4 && (
            <TabContainer>
              {" "}
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} sm={12}>
                  <CareerCard />
                </Grid>
                <Grid item lg={4} md={6} sm={12}>
                  <CareerCard />
                </Grid>
              </Grid>
            </TabContainer>
          )}
          {activeIndex === 5 && (
            <TabContainer>
              {" "}
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} sm={12}>
                  <CareerCard />
                </Grid>
              </Grid>
            </TabContainer>
          )}
          {activeIndex === 6 && (
            <TabContainer>
              {" "}
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} sm={12}>
                  <CareerCard />
                </Grid>
                <Grid item lg={4} md={6} sm={12}>
                  <CareerCard />
                </Grid>
              </Grid>
            </TabContainer>
          )}
        </Box>
      </Container>
    </>
  );
};

const VerticalTabs = withStyles((theme) => ({
  flexContainer: {
    // flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "14px",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const MyTab = withStyles((theme) => ({
  selected: {
    color: "#000",
    borderBottom: "none",
    border: "1px solid #EBEBEB",
    background: "#EBEBEB",
  },
}))(Tab);

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" style={{ padding: "12px 24px" }}>
      {children}
    </Typography>
  );
};

export default Careers;
