import { ReactComponent as support } from './support.svg';
import { ReactComponent as getStarted } from './get-started.svg';
import { ReactComponent as grayShadowEn } from './gray-shadow-en.svg';
import { ReactComponent as grayShadowAr } from './gray-shadow-ar.svg';
import { ReactComponent as intersectLeft } from './intersect-left.svg';
import { ReactComponent as intersectRight } from './intersect-right.svg';
import { ReactComponent as adBanner } from './property.svg';
import { ReactComponent as profile } from './profile.svg';
import propertyRentImg  from './property-for-rent.png'


export default {
    SUPPORT: support,
    GET_STARTED: getStarted,
    GRAY_SHADOW_EN: grayShadowEn,
    GRAY_SHADOW_AR: grayShadowAr,
    INTERSECT_LEFT: intersectLeft,
    INTERSECT_RIGHT: intersectRight,
    AD_BANNER: adBanner,
    PROFILE: profile,
    PROPERTYFORENT: propertyRentImg
};
