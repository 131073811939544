import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import './message.scss'
import { useSelector } from 'react-redux';
import { GET_MESSAGES } from '../../api/queries/chat.queries';
import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
const apiURL = process.env.REACT_APP_BASE_URL



const Message = ({ selectedChat, messages }) => {
  const theme = useTheme()
  const {t, i18n} = useTranslation("common")
  const [audioData, setAudioData] = useState({});
  const { receiverId, senderId } = useSelector(state => state.conversations)


  let queryVariables = null;
  if (selectedChat) {
      const { sender_id, receiver_id, category_id, item_id, id } = selectedChat;

      queryVariables = {
          chatId: parseInt(id),
          senderId: parseInt(sender_id),
          receiverId: parseInt(receiver_id),
          categoryId: parseInt(category_id),
          itemId: parseInt(item_id)
      };
  }

  const { loading, error, data } = useQuery(GET_MESSAGES, {
      variables: queryVariables,
      skip: !queryVariables
  });

  useEffect(() => {}, [loading]);




  useEffect(() => {

    
      
  }, [data]);


  const formatTimestamp = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const storedUser = localStorage.getItem('userData');
  const parsedUserData = storedUser ? JSON.parse(storedUser) : null;
  const userId = parsedUserData ? parsedUserData.id : null;


  
  
 
  return (
    <Box marginTop={'30px'}>
      {messages.map((item, id) => {

    const isSender = item.sender_id === parseInt(userId);


        return (
          <Box key={id} display={'flex'} gap={2} marginBottom={'12px'} sx={{
            flexDirection: isSender? 'row-reverse' : 'row'
          }}>


            <img src={`${apiURL}${isSender? parsedUserData?.image : selectedChat.receiver.image}`} alt="user" className='user-image' style={{
              filter:'grayScale(100%)', borderRadius: '50px'
            }} />
            <Box>
              {item.type === "text" && (
                <Box>
                  <Typography sx={{
                    backgroundColor: isSender ? '#fff' : theme.palette.primary.blue,
                    color: isSender ? '#32363f' : '#fff',
                    padding: '10px 20px',
                    borderRadius: isSender ? '15px 15px 0px 15px' : '15px 15px 15px 0px',
                    fontSize: '14px !important',
                    boxShadow: isSender ? '0px 4px 10px rgba(0,0,0,.16)' : 'none',
                  }}>
                    {item.message}
                  </Typography>
                  <Typography sx={{
                    color: '#b2b2b2',
                    fontSize: '12px !important',
                    textAlign: isSender ? 'right' : 'left',
                    marginTop: '5px'
                  }}>{formatTimestamp(item.createdAt)}</Typography>
                </Box>
              )}
              {item.type === "voice" && (
                <Box>
                  <audio controls={true} style={{
                    height: '43px',
                    borderRadius: '15px',
         
                  }}>
                    <source src={`${apiURL}${item.message}`} type="audio/mp3" />
                  </audio>
                  <Typography sx={{
                    color: '#b2b2b2',
                    fontSize: '12px !important',
                    textAlign: isSender ? 'right' : 'left',
                  }}>{formatTimestamp(item.createdAt)}</Typography>
                </Box>
              )}
              {item.type === "image" && (
                <Box>
                  <img src={`${apiURL}${item.message}`} alt="" style={{
                    width:'300px',
                    height: '300px',
                    borderRadius: '8px'
                  }}/>
                  <Typography sx={{
                    color: '#b2b2b2',
                    fontSize: '12px !important',
                    textAlign: isSender ? 'right' : 'left',
                  }}>{formatTimestamp(item.createdAt)}</Typography>
                </Box>
              )}
              {item.type === "video" && (
                <Box>
                  <video src={`${apiURL}${item.message}`} alt="" style={{
                    width:'300px',
                    height: '300px'
                  }} controls/>
                  <Typography sx={{
                    color: '#b2b2b2',
                    fontSize: '12px !important',
                    textAlign: isSender ? 'right' : 'left',
                  }}>{formatTimestamp(item.createdAt)}</Typography>
                </Box>
              )}
            </Box>
           
          </Box>
        );
      })}
    </Box>
  );
}

export default Message





