import gql from 'graphql-tag';



const GET_PROPERTY = gql `
    query Property($propertyId: Int) {
        property(id: $propertyId) {
            amenities {
                id
                title
            }
            annual_community_fee
            bathrooms
            bedrooms
            category_id
            buyer_transfer_fee
            contact_number
            description
            developer
            furnished
            id
            images {
                id
                image
            }
            location
            maintenance_fee
            neighbourhood
            occupancy_status
            posted_by
            price
            ready_by
            reference_number
            seller_transfer_fee
            size
            socialmedia {
                id
                type
                value
            }
            title
            total_closing_fee
            user_id {
                email
                first_name
                id
                image
                last_name
                member_since
                phone
                total_listings
            }
        }
    }
`;




const GET_PROPERTIES_BY_CATEGORYID = gql`
query Properties($page: Int, $userId: Int, $categoryId: Int, $filters: JSON) {
  properties(page: $page, user_id: $userId, category_id: $categoryId, filters: $filters) {
    total
    per_page
    data {
      annual_community_fee
      bedrooms
      bathrooms
      amenities {
        id
        title
      }
      category_id
      images {
        id
        image
      }
      size
      title
      id
      is_featured
      posted_by
      price
      neighbourhood
      user_id {
        id
      }
    }
    current_page
  }
}
`

export { GET_PROPERTY, GET_PROPERTIES_BY_CATEGORYID };
