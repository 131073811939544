import React, { useState } from "react";
import {
  Box,
  Grid,
  useTheme,
  Typography,
  Button,
  TextField,
  Container,
} from "@mui/material";
import SectionTitle from "../../components/section-title/section-title.component";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import MAIL_US from "../../assets/images/contact-mail.png";
import CALL_US from "../../assets/images/contact-call.png";
import CustomFileInput from "../../components/custom-file-input/custom-file-input.component";
import CustomPhoneInput from "../../components/phoneInput/CustomPhoneInput";
import "./contact.scss";


const ContactUs = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();

  const textFieldStyle = {
    width: "100%",
    borderRadius: "8px",
  };

  return (
    <>
      <Helmet>
        <title>{t("contact.contact-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>
      <Container>
        <Box
          sx={{
            border: "1px solid #EFEFEF",
            padding: "18px 0px",
            display: "flex",
            justifyContent: "center",
            margin: "50px 0px 60px 0px",
          }}
        >
          <Typography
            sx={{
              background: "#EFEFEF",
              padding: "30px 60px",
              borderRadius: "8px",
            }}
          >
            For Ad
          </Typography>
        </Box>

        <Box
          margin="auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("contact.section-title")} />
        </Box>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sx={{ marginTop: "60px" }}>
            <img src={MAIL_US} alt="mail" className="custom" />

            <Box sx={{ marginTop: "36px" }}>
              <Typography
                variant="h2"
                sx={{ marginBottom: "20px", fontSize: "34px !important" }}
              >
                {t("contact.online-enquiry")}
              </Typography>
              <Typography
                sx={{ fontSize: "16px !important", lineHeight: "40px" }}
              >
                {t("contact.enquiry-info")}
              </Typography>
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sx={{ marginTop: "60px" }}>
            <img src={CALL_US} alt="call" className="custom" />
            <Box sx={{ marginTop: "36px" }}>
              <Typography
                variant="h2"
                sx={{ marginBottom: "20px", fontSize: "34px !important" }}
              >
                {t("contact.call-us")}
              </Typography>
              <Typography
                sx={{ fontSize: "16px !important", lineHeight: "40px" }}
              >
                +971 4 123456
              </Typography>
              <Typography
                sx={{ fontSize: "16px !important", lineHeight: "40px" }}
              >
                {t("contact.time")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ margin: "85px 0px 68px 0px" }}>
          <Typography sx={{ fontSize: "18px !important", fontWeight: "700" }}>
            {t("contact.submit-request")}
          </Typography>
        </Box>

        <Grid container spacing={2} className="form-area">
          <Grid item xs={6}>
            <TextField
              placeholder={t("contact.placeholders.firstName")}
              style={textFieldStyle}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  height: "55px",
                  marginBottom: "30px",
                },
              }}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: theme.palette.primary.main,
                    opacity: 1,
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              placeholder={t("contact.placeholders.lastName")}
              style={textFieldStyle}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  height: "55px",
                  marginBottom: "30px",
                },
              }}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: theme.palette.primary.main,
                    opacity: 1,
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={6}>
            <CustomPhoneInput style={textFieldStyle} />
          </Grid>

          <Grid item xs={6}>
            <TextField
              placeholder={t("contact.placeholders.email")}
              style={textFieldStyle}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  height: "55px",
                  marginBottom: "30px",
                },
              }}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: theme.palette.primary.main,
                    opacity: 1,
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: "30px" }}>
            <CustomFileInput
              title={"Attachments"}
              modalIsOpen={openDialog}
              setModalIsOpen={setOpenDialog}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "15px",
                marginBottom: "17px",
              }}
            >
              {t("contact.description")}
            </Typography>
            <TextField
              style={{ width: "100%" }}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                  height: "140px",
                  marginBottom: "25px",
                },
              }}
              multiline
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ fontSize: "14px !important" }}>
              {" "}
              {t("contact.support")}
            </Typography>
          </Grid>

          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flex={"auto"}
          >
            <Button
              sx={{
                background: theme.palette.primary.orange,
                color: "#fff",
                margin: "70px 0px 0px 0px",
                display: "flex",
                width: "370px",
                height: "55px",
                borderRadius: "8px",
                textAlign: "center",

                "&:hover": {
                  background: theme.palette.primary.orange,
                  color: "#fff",
                },
              }}
            >
              {t("contact.btn-text")}
            </Button>
          </Box>
        </Grid>

        <Box className="cta">
          <Box className="content-cta" textAlign={"center"}>
            <Typography
              sx={{
                fontSize: "34px!important",
                marginBottom: "36px",
                fontWeight: "700",
                color: "#fff",
              }}
            >
              {t("contact.cta-title")}
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              Suspendisse sit amet luctus justo, vitae varius lacus. Nunc lacus
              odio, interdum id tristique
              <br /> call us for more details +971 4 123456
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ContactUs;
