import { useState } from 'react';
import { TextField, Box, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ControlledMap from '../geo-map/map.component';
import './Places.scss';
import { makeStyles } from '@mui/styles';
const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const useStyles = makeStyles((theme) => ({
    option: {
        color: theme.palette.primary.main
    },
}));
const Places = (props) => {
    const { locationName, locationType, locationStyles, locationLabel, locationErr, locationHelperText, selectedPlace, setSelectedPlace } = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    const [end, setEnd] = useState([25.2048, 55.2708]);
    const [places, setPlaces] = useState(selectedPlace?.place_name ? [{
            place_name: selectedPlace?.place_name,
            center: selectedPlace?.center
        }] : []);
    const getPlaces = async (place) => {
        try {
            const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${place}.json?access_token=${mapboxAccessToken}`);
            const data = await response.json();
            if (data?.features) {
                setPlaces(data.features);
                
            }
        }
        catch (error) {
            console.error('Error fetching places:', error);
        }
    };
    const handleInputChange = (event) => {
        getPlaces(event.target.value);
    };
    const handleSelectChange = (selectedOption) => {
        setSelectedPlace(selectedOption);
    };
    return (<>
            <Autocomplete options={places} getOptionLabel={(option) => option['place_name']} value={selectedPlace} style={{ width: "100%" }} renderInput={(params) => (<>
                        <TextField {...params} fullWidth name={locationName} placeholder="Enter endpoint" type={locationType} sx={locationStyles} label={locationLabel} error={locationErr} helperText={locationHelperText} value={selectedPlace} onChange={(e) => handleInputChange(e)}/>
                    </>)} onChange={(event, value) => {
            if (value && typeof value !== 'string') {
                handleSelectChange(value);
            }
        }} classes={{
            option: classes.option,
        }}/>
            <Box position={'relative'} height={'50vh'} margin={'20px 0'}>
                <ControlledMap end={end} setEnd={setEnd} selectedPlace={selectedPlace} setSelectedPlace={setSelectedPlace} type={'post'}/>
            </Box>
        </>);
};
export default Places;
