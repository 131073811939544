import gql from 'graphql-tag';


const GET_MOTORS = gql `
query Motors($page: Int, $userId: Int, $categoryId: Int, $filters: JSON) {
  motors(page: $page, user_id: $userId, category_id: $categoryId, filters: $filters) {
    current_page
    per_page
    total
    data {
      id
      emirates
      trim
      specs
      year
      kilometers
      insured_in_uae
      price
      contact_info
      title
      desc
      tour_url
      fuel_type
      exterior_color
      interior_color
      warranty
      doors
      no_of_cylinders
      transmission_type
      body_type
      seating_capacity
      horsepwer
      engine_capacity
      user_id {
        id
        image
        first_name
        last_name
        email
        phone
        member_since
        total_listings
      }
      steering_side
      category_id
      seller
      type
      extras {
        id
        title
      }
      images {
        id
        image
      }
      is_featured
      is_favorite
      make
      model
    }
  }
}`


const GET_MOTOR_EXTRAS = gql `
query Motor_extras {
  motor_extras {
    id
    title
  }
}
`


const STORE_MOTOR_MUTATION = gql `
mutation Store_motors($args: JSON) {
  store_motors(args: $args) {
    message
    payment {
      currency_code
      country_code
      cart_description
      cart_id
      amount
    }
  }
}
`


const GET_MOTOR_BY_ID = gql`
query Motor($motorId: Int) {
  motor(id: $motorId) {
    id
    emirates
    make
    model
    trim
    specs
    year
    kilometers
    insured_in_uae
    price
    contact_info
    title
    desc
    tour_url
    fuel_type
    exterior_color
    interior_color
    warranty
    doors
    no_of_cylinders
    transmission_type
    body_type
    seating_capacity
    horsepwer
    engine_capacity
    user_id {
      id
      image
      first_name
      last_name
      email
      phone
      member_since
      total_listings
    }
    steering_side
    category_id
    seller
    type
    extras {
      id
      title
    }
    images {
      id
      image
    }
    is_featured
    is_favorite
  }
}`


const GET_MOTORS_MODELS = gql`
query Motor_models($makeId: Int) {
  motor_models(make_id: $makeId) {
    id
    motor_make_id
    title
  }
}`

const GET_MOTORS_MAKE = gql`
query Motor_makes($page: Int, $value: String) {
  motor_makes(page: $page, value: $value) {
    current_page
    per_page
    total
    data {
      id
      image
      title
    }
  }
}`



export { GET_MOTORS, GET_MOTOR_EXTRAS, STORE_MOTOR_MUTATION, GET_MOTOR_BY_ID, GET_MOTORS_MODELS, GET_MOTORS_MAKE }