import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    receiverId:null,
    senderId:null,
    chat:[]
}

const conversationSlice = createSlice({

    name: 'conversations',
    initialState: initialState,
    reducers: {
        addConversation: (state, action) => {
            state.chat.push(action.payload);
        },
        chatInfo:(state,action)=>{
            state.receiverId = action.payload.receiverId;
            state.senderId = action.payload.senderId;
        }
    }
})

export const { addConversation,chatInfo } = conversationSlice.actions;

export default conversationSlice.reducer;