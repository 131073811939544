import React from 'react';
import { Pagination } from '@mui/material';
import './pagiantion.scss'
import { useTheme, useMediaQuery } from '@mui/material'


const CustomPagination = ( { currentPage, totalPages, onPageChange}) => {

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (event, value) => {
        onPageChange(value);
      };


  return (
    <Pagination 
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
        color="primary"
        size='large'
        prevIcon='Previous'
        nextIcon='Next'
        sx={{
          '& .MuiPagination-ul': {
            justifyContent: isMobile? 'center' : 'flex-end',

            '& .Mui-selected': {
              background: theme.palette.primary.orange,
          
             '& .Mui-selected:hover' : {
              background: '#222222',
              color: '#fff',
             }
            },
          },
        
        }}
    />
  )
}

export default CustomPagination
