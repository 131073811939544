import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
  Container,
  Button,

} from "@mui/material";
import { formatNumberWithCommas } from "../../helpers/global";
import Icons from "../../assets/icons";
import IconWithBadge from "../icon-with-badge/icon-with-badge.component";
import SectionTitle from "../section-title/section-title.component";
import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { GET_PROPERTIES } from "../../api/queries/home.queries";
import { useQuery } from "@apollo/client";
import { generatePath, useNavigate } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import ResponsiveCarousel from "../responsive-carousel/responsive-carousel.component";
import "./featured-ads.scss";
import { GET_MOTORS } from "../../api/queries/motors.queries";
import Skeleton from "@mui/material/Skeleton";
import { useCategory } from "../../context/CategoryContext";
import { FaRegFaceFrown } from "react-icons/fa6";
import { encodeId } from '../../helpers/idEncryption'




const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: "unset",
    transition: "box-shadow 0.3s",
    cursor: "pointer",
    borderRadius: "18px !important",
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    textAlign: "center",
    height: "unset",
    "& svg": {
      width: "100%",
      height: "auto",
      maxWidth: "100%",
      objectFit: "contain",
    },
  },
  headline: {
    fontStyle: "normal",
    lineHeight: "normal !important",
    fontWeight: "bold !important",
    textAlign: "start",
    marginTop: "12px!important",
    marginBottom: "0!important",
  },
  mobileMedia: { margin: 5 },
  badges: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
}));

const AdCard = (props) => {
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    classes,
    data,
    verifiedRankStyles,
    featuredStyles,
    bedroomBathroomAspectStyles,
    statusStyles,
    isMobile,
    lang,
  } = props;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      // simulate loading delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };
    loadData();
  }, []);


  
  const handleClickProperty = (id) => {
    const encryptedId = encodeId(id);
    navigate(generatePath(`${ROUTES.PROPERTY}`, { id: encryptedId }));
  };

  return (
    <Card
      className={classes.cardRoot}
      sx={{
        borderTopRightRadius: "5px!important",
        borderTopLeftRadius: "5px!important",
        cursor: "pointer",
        boxShadow: "unset",
      }}
      onClick={() => handleClickProperty(data?.id.toString())}
    >
      {loading ? (
        <Skeleton
          variant="rect"
          width={281}
          height={183}
          style={{ borderRadius: "5px" }}
          animation="wave"
        />
      ) : (
        <Box position={"relative"} width={"281px"} height={"183px"}>
          {data?.images?.length >= 1 && (
            <ResponsiveCarousel
              images={data.images ? data?.images : <Icons.NEW_LOGO />}
              hideThumbs={true}
              onImageClick={() => handleClickProperty(data?.id.toString())}
            />
          )}
          {data?.images?.length === 0 && (
            <Box
              display={"grid"}
              height={"100%"}
              borderRadius={"8px"}
              border={"3px solid #D9D9D9"}
            >
              <Box alignSelf={"center"} justifySelf={"center"}>
                <Icons.NEW_LOGO style={{ width: "50%" }} loading="lazy" />
              </Box>
            </Box>
          )}
        </Box>
      )}

      <CardContent
        sx={{ padding: isMobile ? "16px 0px" : "16px 0px", display: "grid" }}
      >
        <Stack direction={"row"} display={"flex"}>
          {/* <IconWithBadge
            icon={<Icons.VERIFIED className={isMobile ? classes.mobileMedia : ''} />}
            title={data?.verified && 'Verified'}
            badgeStyle={verifiedRankStyles}
                /> */}
          {/* {!isMobile && (
                    <IconWithBadge
                        icon={<Icons.RANK />}
                        title={data?.rank}
                        badgeStyle={verifiedRankStyles}
                    />
                )} */}
          {/* <IconWithBadge
                    title={data?.featured && 'Featured'}
                    badgeStyle={featuredStyles}
                /> */}
        </Stack>
        <Box padding={isMobile && "0 8px"} textAlign={"start"}>
          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <Box className={classes.headline} gap={"8px"} display={"grid"}>
              <Typography
                variant="h3"
                color={theme.palette.primary.main}
              >{`${formatNumberWithCommas(
                data?.price
              )} AED / Yearly`}</Typography>
              <Typography
                variant="h3"
                fontWeight={400}
                color={theme.palette.primary.main}
                sx={{
                  width: '250px',
                  overflow: 'hidden',
                  textOverflow:'ellipsis',
                  whiteSpace: 'noWrap'
                }}
              >
                {data?.title}
              </Typography>
            </Box>
          )}

          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <IconWithBadge
              icon={<Icons.LOCATION style={{ width: 20, height: 20 }} />}
              title={data?.neighbourhood}
              badgeStyle={{
                margin: "8px 0px!important",
                display: "flex",
                alignItems: "center",
                color: theme.palette.primary.main,
              }}
              type={"location"}
              className={classes?.badges}
            />
          )}
        </Box>

        {loading ? (
          <Skeleton animation="wave" />
        ) : (
          <Typography
            color={theme.palette.primary.main}
            display={"flex"}
            gap={"10px"}
            padding={isMobile ? "6px" : 0}
            margin={"0"}
            sx={{ cursor: "pointer", flexWrap: "wrap" }}
          >
            <IconWithBadge
              icon={<Icons.BEDROOM style={{ width: 20, height: 20 }} />}
              title={data?.bedrooms}
              badgeStyle={bedroomBathroomAspectStyles}
              className={classes?.badges}
            />
            <IconWithBadge
              icon={<Icons.BATHROOM style={{ width: 20, height: 20 }} />}
              title={data?.bathrooms}
              badgeStyle={bedroomBathroomAspectStyles}
              className={classes?.badges}
            />
            <IconWithBadge
              icon={<Icons.ASPECT_RATIO style={{ width: 20, height: 20 }} />}
              title={`${formatNumberWithCommas(Number(data?.size))} ${t('property-details.sqft')}`}
              badgeStyle={bedroomBathroomAspectStyles}
              className={classes?.badges}
            />
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const MotorCard = (props) => {

  const baseURL = process.env.REACT_APP_BASE_URL;
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const navigate = useNavigate();
  const { classes, motorData , isMobile, lang } = props;

 
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };
    loadData();
  }, []);
  const handleMotorClick = (id) => {
    const encryptedId = encodeId(id);
    navigate(generatePath(`${ROUTES.MOTOR_DETAILS}`, { id: encryptedId }));
  };

  return (
    <Card
      className={classes.cardRoot}
      sx={{
        borderTopRightRadius: "5px!important",
        borderTopLeftRadius: "5px!important",
        cursor: "pointer",
        boxShadow: "unset",
        width: "281px",
      }}
      onClick={() => handleMotorClick(motorData?.id.toString())}
    >
      {loading ? (
        <Skeleton
          variant="rect"
          width={281}
          height={183}
          style={{ borderRadius: "5px" }}
          animation="wave"
        />
      ) : (
        <Box position={"relative"} width={"281px"} height={"183px"}>

          {motorData?.images?.length >= 1 && (
            <ResponsiveCarousel
              images={motorData.images ? motorData?.images : <Icons.NEW_LOGO />}
              hideThumbs={true}
            />
        
          )}

          {motorData?.images?.length === 0 && (
            <Box
              display={"grid"}
              height={"100%"}
              borderRadius={"8px"}
              border={"3px solid #D9D9D9"}
            >
              <Box alignSelf={"center"} justifySelf={"center"}>
                <Icons.NEW_LOGO style={{ width: "50%" }} loading="lazy" />
                <Typography color={theme.palette.primary.main}>
                  No Image Available
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
      <CardContent
        sx={{ padding: isMobile ? "16px 0px" : "16px 0px", display: "grid" }}
      >
        <Stack direction={"row"} display={"flex"}>
          {/* <IconWithBadge
            icon={<Icons.VERIFIED className={isMobile ? classes.mobileMedia : ''} />}
            title={data?.verified && 'Verified'}
            badgeStyle={verifiedRankStyles}
                /> */}
          {/* {!isMobile && (
                    <IconWithBadge
                        icon={<Icons.RANK />}
                        title={data?.rank}
                        badgeStyle={verifiedRankStyles}
                    />
                )} */}
          {/* <IconWithBadge
                    title={data?.featured && 'Featured'}
                    badgeStyle={featuredStyles}
                /> */}
        </Stack>
        <Box padding={isMobile && "0 8px"} textAlign={"start"}>
          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <Box className={classes.headline} gap={"8px"} display={"grid"}>
              <Typography
                variant="h3"
                color={theme.palette.primary.main}
              >{`${formatNumberWithCommas(motorData?.price)} AED`}</Typography>
              <Typography
                variant="h3"
                fontWeight={400}
                color={theme.palette.primary.main}
                sx={{
                  width: '250px',
                  overflow: 'hidden',
                  textOverflow:'ellipsis',
                  whiteSpace: 'noWrap'
                }}
              >
                {motorData?.title}
              </Typography>
            </Box>
          )}

          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <IconWithBadge
              icon={<Icons.LOCATION style={{ width: 20, height: 20 }} />}
              title={motorData?.emirates}
              badgeStyle={{
                margin: "8px 0px!important",
                display: "flex",
                alignItems: "center",
                color: theme.palette.primary.main,
              }}
              type={"location"}
              className={classes?.badges}
            />
          )}
        </Box>

        {loading ? (
          <Skeleton animation="wave" />
        ) : (
          <Typography
            color={theme.palette.primary.main}
            display={"flex"}
            padding={isMobile ? "6px" : 0}
            margin={"8px 0px 0px 0px"}
            gap={"6px"}
            sx={{ cursor: "pointer", flexWrap: "wrap" }}
          >

            {
              motorData?.year && (
                <Box
                sx={{
                  border: "1px solid #EFEFEF",
                  borderRadius: "4px",
                  padding: "6px",
                }}
              >
                <Typography sx={{ fontSize: "12px !important" }}>{t('motor-details.year')}</Typography>
                <Typography
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: "600 !important",
                    marginTop: "10px",
                    color: "#000"
                  }}
                >
                  {motorData?.year}
                </Typography>
              </Box>
              )
            }

            {
              motorData?.kilometers && (
                <Box
                sx={{
                  border: "1px solid #EFEFEF",
                  borderRadius: "4px",
                  padding: "6px",
                }}
              >
                <Typography sx={{ fontSize: "12px !important" }}>
                  {t('motor-details.kilometer')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: "600 !important",
                    marginTop: "10px",
                  }}
                >
                  {motorData?.kilometers}
                </Typography>
              </Box>
              )
            }
         

           
           {
            motorData?.specs && (
              <Box
              sx={{
                border: "1px solid #EFEFEF",
                borderRadius: "4px",
                padding: "6px",
              }}
            >
              <Typography sx={{ fontSize: "12px !important" }}>
                {t('motor-details.specs')}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px !important",
                  fontWeight: "600 !important",
                  marginTop: "10px",
                  overflow: "hidden !important",
                  whiteSpace: "nowrap !important",
                  textOverflow: "ellipsis !important",
                  width: "75px",
                }}
              >
                {motorData?.specs}
              </Typography>
            </Box>
            )
           }

         

            {/* <Box
              sx={{
                border: "1px solid #EFEFEF",
                borderRadius: "4px",
                padding: "6px",
              }}
            >
              <Typography sx={{ fontSize: "12px !important" }}>
                MODEL
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px !important",
                  fontWeight: "600 !important",
                  marginTop: "10px",
                }}
              >
                {data?.model}
              </Typography>
            </Box> */}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const FeaturedAds = () => {

  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const classes = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { selectedCategory } = useCategory() 


 

  const handleCategoryClick = (categoryId) => {
    if (Number(categoryId) === 7) {
      const motorSearchRoute = ROUTES.MOTOR_SEARCH.replace(
        ":categoryId",
        categoryId
      );
      navigate(motorSearchRoute);
    } else {
      const propertyForRentRoute = ROUTES.PROPERTY_FOR_RENT.replace(
        ":categoryId",
        categoryId
      );
      navigate(propertyForRentRoute);
    }
  };
  const {
    loading: isMotorLoading,
    error: errorLoadingMotors,
    data: motorList,
  } = useQuery(GET_MOTORS, {
    variables: {
      page: null,
      userId: null,
    },
  });



  useEffect(() => {
    if (errorLoadingMotors) {
      console.error("Error loading properties:", errorLoadingMotors);
    }
  }, [errorLoadingMotors]);
  useEffect(() => {
    console.log("loading");
  }, [isMotorLoading]);

  const {
    loading: isLoadingProperties,
    error: errorLoadingProperties,
    data: propertiesList,
  } = useQuery(GET_PROPERTIES, {
    variables: {
      page: null,
      userId: null,
    },
  });
  useEffect(() => {
    if (errorLoadingProperties) {
      console.error("Error loading properties:", errorLoadingProperties);
    }
  }, [errorLoadingProperties]);
  useEffect(() => {
    const delay = setTimeout(() => {}, 3000);
    return () => clearTimeout(delay);
  }, [isLoadingProperties]);

  //const initialAdsToShow = Number(propertiesList?.properties?.per_page);

  const [adsToShow, setAdsToShow] = React.useState(4); // Initial number of ads to show
  const [loadMoreClicked, setLoadMoreClicked] = React.useState(false);

  const handleLoadMore = () => {
    // Toggle the state to indicate that the "Load More" button has been clicked
    setLoadMoreClicked(true);

    // Set the number of ads to show to the total number of ads
    setAdsToShow(propertiesList?.properties?.data?.length || 0);
  };

  const verifiedRankStyles = {
    display: "inline-flex",
    gap: "5px!important",
    borderRadius: 100,
    background: "#EFEFEF",
    alignItems: "center",
    padding: "2px 7px",
    margin: isMobile ? "0 5px" : "0 5px 0 0",
  };
  const featuredStyles = {
    padding: "5px 9px",
    borderRadius: 100,
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  };
  const bedroomBathroomAspectStyles = {
    borderRadius: "4px!important",
    border: "1px solid #EFEFEF",
    padding: "4px 12px",
    margin: "8px 0px!important",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  };
  const statusStyles = {
    padding: "5px 9px",
    position: "absolute",
    top: "12%",
    width: "max-content",
    left: 0,
    borderRadius: "0px 3px 3px 0px",
    background: theme.palette.secondary.main,
  };

  const customButtonStyles = {
    background: "#fff",
    borderRadius: "40px",
    background: "#FFF",
    boxShadow: "0px 4px 74px 0px rgba(0, 0, 0, 0.10)",
    border: "none",
    width: "205px",
    height: "52px",
  };


  const PROPERTY_ADS_ID = 2;
  const MOTOR_ADS_ID = 7;

  return (
    <>
      <Stack
        width="100%"
        margin={isMobile ? "40px 0" : "80px auto !important"}
        position={"relative"}
      >
        <Box sx={{ placeSelf: "center" }}>
          <SectionTitle variant={"h2"} title={t("featuredAds.title")} />
        </Box>
      </Stack>
      <Container className="container">
        <Box>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0px -14px",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "bold",
                color: theme.palette.primary,
              }}
            >
              {t("featuredAds.property")}
            </Typography>
            <Button onClick={() => handleCategoryClick(3)}>
              {t("featuredAds.see-all")}
            </Button>
          </Box> */}


{
  selectedCategory === PROPERTY_ADS_ID && (
    <Box>
      {propertiesList?.properties?.data?.length > 0 ? (
        <Box
          className="ad-box-last"
          style={{
            display: "grid",
            gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(4, 1fr)",
            gridGap: "0",
            placeContent: "center",
          }}
        >
          {propertiesList.properties.data
            .slice(0, adsToShow)
            .map((property) => (
              <Box
                key={property.id}
                style={{
                  boxSizing: "border-box",
                  padding: "10px",
                  margin: "0",
                  width: "max-content",
                }}
              >
                <AdCard
                  classes={classes}
                  loading={isLoadingProperties}
                  data={property}
                  verifiedRankStyles={verifiedRankStyles}
                  featuredStyles={featuredStyles}
                  bedroomBathroomAspectStyles={bedroomBathroomAspectStyles}
                  statusStyles={statusStyles}
                  isMobile={isMobile}
                  lang={i18n.language}
                />
              </Box>
            ))}
        </Box>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
          }}
        >
          {t("property-details.no-data")} <FaRegFaceFrown />
        </Typography>
      )}
    </Box>
  )
}


          


        </Box>

       

       {
       (selectedCategory == MOTOR_ADS_ID) && (
        <Box>
        {
          motorList?.motors?.data.length > 0 ? (
            <Box>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0px -14px",
              }}>
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  color: theme.palette.primary,
                }}
              >
                {t("featuredAds.cars")}
              </Typography>
              <Button
                onClick={() => handleCategoryClick(7)}
                style={{
                  textDecoration: "none",
                  color: "#212121",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                {t("featuredAds.see-all")}
              </Button>
            </Box> */}
            <Box
            className="ad-box-last"
            style={{
              display: "grid",
              gridTemplateColumns: isMobile
                ? "repeat(1, 0fr)"
                : "repeat(4, 0fr)",
              gridGap: "0",
              placeContent: "center",
            }}
          >
            {motorList &&
              motorList?.motors?.data?.slice(0, adsToShow).map((motor) => (
                <Box
                  key={motor.id}
                  style={{
                    boxSizing: "border-box",
                    padding: "10px",
                    margin: "0",
                    width: "max-content",
                  }}
                >
                  <MotorCard
                    classes={classes}
                    motorData={motor}
                    loading={isMotorLoading}
                    statusStyles={statusStyles}
                    isMobile={isMobile}
                    lang={i18n.language}
                  />
                </Box>
              ))}
          </Box>
          </Box>
          )

          :
          (
            <Typography sx={{textAlign:"center", display:"flex", alignItems:"center", justifyContent:"center", gap:"12px"}}>{t('property-details.no-data')} <FaRegFaceFrown /></Typography>

          )
        }
        </Box>
       )
       }

     
      </Container>

      {!loadMoreClicked && (
            <Box textAlign="center" marginTop="20px">
                <Button variant="outlined" onClick={handleLoadMore} style={customButtonStyles}>
                    {t('property-details.load-more')}
                </Button>
            </Box>
        )}
    </>
  );
};
export default FeaturedAds;
