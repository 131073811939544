import React from "react";
import Select from "react-select";
import { Box, FormControl, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ValueContainer = ({ children, innerProps, innerRef, ...props }) => {
  return (
    <Box
      {...props}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"row-reverse"}
      style={{ cursor: "pointer" }}
    >
      {!!children && (
        <>
          <KeyboardArrowDownIcon />
        </>
      )}
      {children}
    </Box>
  );
};

const CustomSelect = (props) => {
  const {
    options = [],
    selected,
    onSelectChange,
    clearable,
    getOptionLabel,
    placeholder,
    searchable,
    indicator,
    icon,
    fullWidth,
    styles,
    className,
    borderRadius,
    background,
    innerRef,
    innerProps,
  } = props;
  const theme = useTheme();

  const handleChange = (selectedOption) => {
    onSelectChange &&
      onSelectChange(selectedOption ? selectedOption.value : null);
  };

  const reactSelectOptions = options.map((option) => ({
    value: option.value,
    label: getOptionLabel ? getOptionLabel(option) : option.label,
  }));

  const isSearchable = searchable !== undefined ? searchable : true;
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: borderRadius,
      boxShadow: "none",
      backgroundColor: background || "transparent",
      caretColor: "transparent",
      cursor: "pointer",

      width: fullWidth,
      "&:hover": {
        borderColor: "transparent",
      },
      ...styles,
    }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
      backgroundColor: theme.palette.secondary.main,
      zIndex: 1000,
    }),
    option: (base, props) => ({
      ...base,
      backgroundColor:
        props.isFocused || props["isHovered"] ? "#fff" : "transparent",
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "#FE820E",
        color: "#fff",
        cursor: "pointer",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.palette.primary.main,
      fontSize: "16px",
    }),
    valueContainer: (base, state) => ({
      ...base,
      justifyContent: "center",
      padding: "15px 8px",
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: theme.palette.primary.main,
        fontSize: "16px !important",
        width: "max-content",
      };
    },
  };

  const components = indicator
    ? {
        DropdownIndicator: indicator,
        IndicatorSeparator: () => null,
        ValueContainer,
      }
    : { IndicatorSeparator: () => null, ValueContainer };
  return (
    <FormControl
      style={{
        justifyContent: "center",
        width: fullWidth ? fullWidth : "max-content",
      }}
      sx={{ textAlign: indicator ? "center" : "unset" }}
    >
      <div>
        <Select
          value={reactSelectOptions.find(
            (option) => option.value === (selected ? selected.value : null)
          )}
          onChange={handleChange}
          options={reactSelectOptions}
          styles={customStyles}
          components={components}
          placeholder={placeholder}
          isSearchable={isSearchable}
          className={className}
        />
      </div>
    </FormControl>
  );
};
export default CustomSelect;
