import { forwardRef, useImperativeHandle } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInput-root': {
            height: "100%"
        },
        '& .MuiOutlinedInput-input': {
            color: theme.palette.primary.main
        }
    },
}));
const BasicDatePicker = forwardRef((props, ref) => {
    const classes = useStyles();
    const { value, setValue, label, handleChange } = props;
    useImperativeHandle(ref, () => ({
        setValue: (newValue) => setValue(newValue),
    }));
    return (<LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label={label} value={dayjs(value)} onChange={(e) => {
            if (handleChange) {
                handleChange(e);
            }
        }} className={classes.root} slotProps={{ textField: { fullWidth: true } }}/>
        </LocalizationProvider>);
});
export default BasicDatePicker;
