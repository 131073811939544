import api from '../apiV2';


// const VERIFY_TOKEN = async (data) => {
  
//     const response = await api.get(`/verify-token`, {
//         headers: {
//             Authorization: `Bearer ${data}`,
//         },
//     });
//     return response.data;
// };
// const REFRESH_TOKEN = async (token) => {
//     const response = await api.post(`/refresh-token`, {
//         headers: {
//             'X-Refresh-Token': token
//         }
//     });

//     return response.data;
// };


const VERIFY_TOKEN = async (token) => {
    try {
        const response = await api.get(`/verify-token`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error verifying token:', error);
        throw error;
    }
};

const REFRESH_TOKEN = async (refreshToken) => {
    try {
        const response = await api.post(`/refresh-token`, {
            refreshToken: refreshToken,
        });
        return response.data;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};




export { VERIFY_TOKEN, REFRESH_TOKEN };
