import { forwardRef } from "react";
import { MuiTelInput } from "mui-tel-input";
import { createStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
const useStyles = makeStyles((theme) =>
  createStyles({
    errorLabel: {
      color: theme.palette.primary.main,
    },
  })
);
const CustomPhoneInput = forwardRef((props, ref) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <MuiTelInput
      {...props}
      ref={ref}
      defaultCountry="AE"
      value={props?.value === undefined ? "" : props?.value}
      fullWidth={true}
      autoComplete="off"
      sx={{
        margin: "0px 0",
        padding: "0px 0",

        "& .MuiInputBase-input": {
          fontSize: "14px !important",
          fontWeight: "500",
        },
        "& fieldset": {
          borderColor: props?.error ? theme.palette.error.main : "#cccccc",
          borderWidth: "1px",
          height: "60px",
          borderRadius: "8px !important",
        },
      }}
      inputProps={{
        sx: {
          "&::placeholder": {
            color: theme.palette.primary.main,
            opacity: 1,
            fontSize: "14px",
            fontWeight: "500",
          },
        },
      }}
      required={props?.required}
      id={props?.id}
      style={props?.style}
      name={props?.name}
    />
  );
});
export default CustomPhoneInput;
