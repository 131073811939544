import { useEffect, useState, memo } from "react";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import BackTo from "../../components/back-to/back-to.component";
import { GET_CATEGORY_CHILD_BY_ID } from "../../api/queries/categories.queries";
import { FETCH_FORM } from "../../api/queries/form.queries";
import MultiStepFormProvider from "../../components/multi-step-form/multi-step-form.component";
import DynamicForm from "./fetch-frm/DynamicForm.component";


const ChildCategory = ({ list, mainCategoryId }) => {
  const [subChildCategoryList, setSubChildCategoryList] = useState(null);
  const [showFetchedForm, setShowFetchedForm] = useState(false);
  const [showSubChildClicked, setShowSubChildClicked] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState("");
  const {
    loading: isLoadingSubChildCategoryList,
    error: errorSubChildCategoryList,
    refetch: refetchChildCategory,
  } = useQuery(GET_CATEGORY_CHILD_BY_ID, {
    variables: {
      afl: 1,
    },
  });
  const { loading: isLoadingFetchForm, error: errorFetchForm } = useQuery(
    FETCH_FORM,
    {
      variables: {
        categoryId: Number(mainCategoryId),
        afl: 1,
      },
    }
  );

  useEffect(() => {
    if (errorSubChildCategoryList) {
      console.error("Error sub child category:", errorSubChildCategoryList);
    }
  }, [errorSubChildCategoryList]);
  useEffect(() => {
    if (subCategoryId && errorFetchForm) {
      console.error("Error fetch form:", errorFetchForm);
    }
  }, [errorFetchForm]);
  useEffect(() => {
    console.log("loading");
  }, [isLoadingSubChildCategoryList]);
  useEffect(() => {
    if (subCategoryId && isLoadingFetchForm) {
      console.log("loading fetch form");
    }
  }, [isLoadingFetchForm, subCategoryId]);

  const onChildCategoryClick = async (id, hasChild) => {

    if (list && list?.categories?.length > 0 && hasChild && id) {
      const fetchedData = await refetchChildCategory({
        categoryId: Number(id),
        afl: null,
        showOnScreen: null,
      });
      setSubChildCategoryList(fetchedData?.data);
      setShowSubChildClicked(true);
      setShowFetchedForm(false);
    } else if (list && list?.categories?.length > 0 && !hasChild && id) {
      setShowFetchedForm(true);
      setShowSubChildClicked(true);
      setSubCategoryId(id);
    } else {
      resetList();
      setSubCategoryId(id);
      setShowFetchedForm(true);
    }
  };

  const onBackClick = () => {
    resetList();
    setShowFetchedForm(false);
  };
  const resetList = () => {
    setShowSubChildClicked(false);
    setSubChildCategoryList(null);
    setSubCategoryId("");
  };

  const onSubCategoryClick = (id) => {
    console.log('sub child is clicked')
    if (id) {
      setShowFetchedForm(true);
      setShowSubChildClicked(false);
      setSubCategoryId(id);
    }
  };


  return (
    <MultiStepFormProvider>
      <Box sx={{ textAlign: "-webkit-center" }}>
        {(showSubChildClicked || showFetchedForm) && (
          <BackTo onClickFunc={onBackClick} func="back" />
        )}
      </Box>
      {!showFetchedForm &&
        list?.categories?.map((category, index) => (
          <Box
            key={index}
            display="flex"
            width="100%"
            onClick={() => {
              onChildCategoryClick(category?.id, category?.has_child);
            }}
            sx={{ cursor: "pointer" }}
          >
            {!showSubChildClicked && (
              <BackTo
                title={category?.title}
                func="next"
                has_child={category?.has_child}
              />
            )}
          </Box>
        ))}
      {!showFetchedForm &&
        list?.categories?.map((category, index) => (
          <Box display="block" key={index}>
            {showSubChildClicked &&
              category?.has_child &&
              subChildCategoryList?.categories?.map((subCategory) => (
                <Box
                  key={subCategory?.id}
                  onClick={() => onSubCategoryClick(subCategory?.id)}
                  sx={{ cursor: "pointer" }}
                >
                  <BackTo
                    title={subCategory?.title}
                    func="next"
                    isClicked={showSubChildClicked}
                  />
                </Box>
              ))}
          </Box>
        ))}
      <ChildCategoryListProvider
        subCategoryId={subCategoryId}
        mainCategoryId={mainCategoryId}
        showFetchedForm={showFetchedForm}
        setShowFetchedForm={setShowFetchedForm}
      />
    </MultiStepFormProvider>
  );
};
const ChildCategoryListProvider = (props) => {
  const { showFetchedForm, mainCategoryId, subCategoryId, setShowFetchedForm } =
    props;
  const [fetchedFormProperties, setFetchedFormProperties] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const { refetch: refetchFetchForm } = useQuery(FETCH_FORM, {
    variables: {
      afl: 1,
    },
  });
  useEffect(() => {
    const fetchFormData = async (id) => {
      const fetchedForm = await refetchFetchForm({
        categoryId: Number(id),
        steps: activeStep + 1,
      });

      setFetchedFormProperties(fetchedForm?.data);
    };
    if (mainCategoryId) {
      fetchFormData(mainCategoryId);
      if (mainCategoryId === 7) {
        setShowFetchedForm(true);
      }
    }
  }, [mainCategoryId, activeStep]);
  return (
    showFetchedForm &&
    fetchedFormProperties && (
      <DynamicForm
        fetchForm={fetchedFormProperties}
        mainCategoryId={mainCategoryId}
        categoryId={subCategoryId}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    )
  );
};
export default ChildCategory;