import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, } from "@apollo/client";
import crypto from "crypto-js";
import { SECRET_KEY } from "../constants/keys";
import { getLanguagePreference } from "../helpers/global";
import { setContext } from '@apollo/client/link/context';

const apiURL = process.env.REACT_APP_API_URL;

const httpLink = new HttpLink({
    uri: apiURL,
});



// Create an ApolloLink interceptor to handle requests

    const requestInterceptor = new ApolloLink((operation, forward) => {

    const copiedVariables = JSON.parse(JSON.stringify(operation.variables))

    // hash for requests with variables
    const hash = crypto.SHA256(SECRET_KEY + JSON.stringify(copiedVariables));
    const hexDigest = hash.toString(crypto.enc.Hex)

    // hash for the requests without the variables
    const newHash =  crypto.SHA256(SECRET_KEY)
    const finalDigest = newHash.toString(crypto.enc.Hex)
    const selectedLanguage = getLanguagePreference();
    const storedUser = localStorage.getItem('token');

    operation.setContext(() => ({
        headers: {
           "X-Digital-Signature": copiedVariables && Object.keys(copiedVariables).length > 0 ? hexDigest : finalDigest,
            "X-App-Language": selectedLanguage,
            Authorization: `Bearer ${storedUser}`,
            "Access-Control-Allow-Origin": "*"
        },
    }));
    return forward(operation);
});

// Create the Apollo Client with the interceptor
const client = new ApolloClient({
    link: ApolloLink.from([requestInterceptor, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools:true,
 
});
export default client;
