const citiesEn = [
    {
        value: 'all',
        name: 'All'
    },
    {
        value: 'dubai',
        name: 'Dubai'
    },
    {
        value: 'abu-dhabi',
        name: 'Abu Dhabi'
    },
    {
        value: 'ras-al-khaimah',
        name: 'Ras Al Khaimah'
    },
    {
        value: 'sharjah',
        name: 'Sharjah'
    },
    {
        value: 'fujairah',
        name: 'Fujairah'
    },
    {
        value: 'ajman',
        name: 'Ajman'
    },
    {
        value: 'umm-al-quwain',
        name: 'Umm Al Quwain'
    },
    {
        value: 'al-ain',
        name: 'Al Ain'
    }
];
const citiesAr = [
    {
        value: 'all',
        name: 'الكل'
    },
    {
        value: 'dubai',
        name: 'دبي'
    },
    {
        value: 'abu-dhabi',
        name: 'أبو ظبي'
    },
    {
        value: 'ras-al-khaimah',
        name: 'رأس الخيمة'
    },
    {
        value: 'sharjah',
        name: 'الشارقة'
    },
    {
        value: 'fujairah',
        name: 'الفجيرة'
    },
    {
        value: 'ajman',
        name: 'عجمان'
    },
    {
        value: 'umm-al-quwain',
        name: 'أم القيوين'
    },
    {
        value: 'al-ain',
        name: 'العين'
    },
];
const languages = [
    {
        value: 'en'
    },
    {
        value: 'ar'
    }
];

const extras = [
    {
      id: 1,
      title: "Air Conditioning",
    },
    {
      id: 2,
      title: "Alarm/Anti-Theft System",
    },

    {
      id: 3,
      title: "AM/FM Radio",
    },
    {
      id: 4,
      title: "Aux Audio In",
    },

    {
      id: 5,
      title: "Bluetooth System",
    },

    {
      id: 6,
      title: "Body Kit",
    },

    {
      id: 7,
      title: "Brush Gaurd",
    },

    {
      id: 8,
      title: "Cassette Player",
    },

    {
      id: 9,
      title: "CD Player",
    },
    {
      id: 10,
      title: "Climate Control",
    },
    {
      id: 11,
      title: "Cooled Seat",
    },
    {
      id: 12,
      title: "DVD Player",
    },
    {
      id: 13,
      title: "Fog Lights",
    },
    {
      id: 14,
      title: "Heat",
    },
    {
      id: 15,
      title: "Heated Seats",
    },
    {
      id: 16,
      title: "Keyless Entry",
    },
    {
      id: 17,
      title: "Keyless Start",
    },
    {
      id: 18,
      title: "Leather Seats",
    },
    {
      id: 19,
      title: "Moonroof",
    },
    {
      id: 20,
      title: "Navigation System",
    },
    {
      id: 21,
      title: "Off-Road Kit",
    },
    {
      id: 22,
      title: "Off-Road Tyre",
    },
    {
      id: 23,
      title: "Parking Sensor",
    },
    {
      id: 24,
      title: "Performance Types",
    },
    {
      id: 25,
      title: "Power Locks",
    },
    {
      id: 26,
      title: "Power Mirrors",
    },
    {
      id: 27,
      title: "Power Sunroof",
    },
    {
      id: 28,
      title: "Power Windows",
    },

    {
      id: 29,
      title: "Premium Lights",
    },
    {
      id: 30,
      title: "Primum Paint",
    },
    {
      id: 31,
      title: "Premium Sound System",
    },
    {
      id: 32,
      title: "Premium Wheel/Rims",
    },
    {
      id: 33,
      title: "Racing Seats",
    },
    {
      id: 34,
      title: "Rear View Camera",
    },
    {
      id: 35,
      title: "Roof Rack",
    },
    {
      id: 36,
      title: "Satellite Radio",
    },
    {
      id: 37,
      title: "Spoiler",
    },
    {
      id: 38,
      title: "Sunroof",
    },
    {
      id: 39,
      title: "VHS Player",
    },
    {
      id: 40,
      title: "Winch",
    },
  ];
   // seat capacity options

   const seatCapacity = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "2 Seater",
    },
    {
      id: 3,
      title: "3 Seater",
    },
    {
      id: 4,
      title: "4 Seater",
    },
    {
      id: 5,
      title: "5 Seater",
    },
    {
      id: 6,
      title: "6 Seater",
    },
    {
      id: 7,
      title: "7 Seater",
    },
    {
      id: 8,
      title: "8 Seater",
    },
    {
      id: 9,
      title: "8+ Seater",
    },
  ];

  const transmissionType = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Manual Transmission",
    },
    {
      id: 3,
      title: "Automatic Transmission",
    },
  ];

  // fuel types

  const fuelType = [
    {
      id: 1,
      title: "Petrol",
    },
    {
      id: 2,
      title: "Diesel",
    },
    {
      id: 3,
      title: "Hybrid",
    },
    {
      id: 4,
      title: "Electric",
    },
  ];

  // badges

  const badges = [
    {
      id: 1,
      title: "First Owner",
    },
    {
      id: 2,
      title: "In Warranty",
    },
    {
      id: 3,
      title: "Service History",
    },
    {
      id: 4,
      title: "No Accident",
    },
  ];
  // horsepower

  const horsePower = [
    {
      id: 1,
      title: "0-100 HP",
    },
    {
      id: 2,
      title: "100-200 HP",
    },
    {
      id: 3,
      title: "200-300 HP",
    },
    {
      id: 4,
      title: "300-400 HP",
    },
    {
      id: 5,
      title: "400-500 HP",
    },
    {
      id: 6,
      title: "500-600 HP",
    },
    {
      id: 7,
      title: "700-800 HP",
    },

    {
      id: 8,
      title: "900+ HP",
    },
  ];

  // exterior color

  const exteriorColor = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Black",
    },
    {
      id: 3,
      title: "White",
    },
    {
      id: 4,
      title: "Brown",
    },
    {
      id: 5,
      title: "Blue",
    },
    {
      id: 6,
      title: "Burgundy",
    },
    {
      id: 7,
      title: "Gold",
    },
    {
      id: 8,
      title: "Grey",
    },
    {
      id: 9,
      title: "Orange",
    },
    {
      id: 10,
      title: "Green",
    },
    {
      id: 11,
      title: "Purple",
    },
    {
      id: 12,
      title: "Red",
    },
    {
      id: 13,
      title: "Silver",
    },
    {
      id: 14,
      title: "Beige",
    },
    {
      id: 15,
      title: "Tan",
    },
    {
      id: 16,
      title: "Teal",
    },
    {
      id: 17,
      title: "Yellow",
    },
  ];

  // interior color

  const interiorColor = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Black",
    },
    {
      id: 3,
      title: "White",
    },
    {
      id: 4,
      title: "Brown",
    },
    {
      id: 5,
      title: "Blue",
    },
    {
      id: 6,
      title: "Burgundy",
    },
    {
      id: 7,
      title: "Gold",
    },
    {
      id: 8,
      title: "Grey",
    },
    {
      id: 9,
      title: "Orange",
    },
    {
      id: 10,
      title: "Green",
    },
    {
      id: 11,
      title: "Purple",
    },
    {
      id: 12,
      title: "Red",
    },
    {
      id: 13,
      title: "Silver",
    },
    {
      id: 14,
      title: "Beige",
    },
    {
      id: 15,
      title: "Tan",
    },
    {
      id: 16,
      title: "Teal",
    },
    {
      id: 17,
      title: "Yellow",
    },
  ];

  // regional specs
  const regionalSpecs = [
    {
      id: 1,
      title: "GCC Specs",
    },
    {
      id: 2,
      title: "American Specs",
    },
    {
      id: 3,
      title: "Canadian Specs",
    },
    {
      id: 4,
      title: "European Specs",
    },
    {
      id: 5,
      title: "Japanese Specs",
    },
    {
      id: 6,
      title: "Korean Specs",
    },
    {
      id: 7,
      title: "Chinese Specs",
    },
    {
      id: 8,
      title: "Other",
    },
  ];

  // no of clyinders

  const no_ofCylinder = [
    {
      id: 1,
      title: "3",
    },
    {
      id: 2,
      title: "4",
    },
    {
      id: 3,
      title: "5",
    },
    {
      id: 4,
      title: "6",
    },
    {
      id: 5,
      title: "8",
    },
    {
      id: 6,
      title: "10",
    },
    {
      id: 7,
      title: "12",
    },
  ];

  // doors
  const doors = [
    {
      id: 1,
      title: "1 door",
    },
    {
      id: 2,
      title: "2 doors",
    },
    {
      id: 3,
      title: "3 doors",
    },
    {
      id: 4,
      title: "4 doors",
    },
    {
      id: 5,
      title: "5 doors",
    },
  ];
  // body type
  const bodyTYpe = [
    {
      id: 1,
      title: "SUV",
    },

    {
      id: 2,
      title: "Coupe",
    },

    {
      id: 3,
      title: "Sedan",
    },

    {
      id: 4,
      title: "Crossover",
    },

    {
      id: 5,
      title: "Hard Top Convertable",
    },

    {
      id: 6,
      title: "Pick up Truck",
    },

    {
      id: 7,
      title: "Hatchback",
    },

    {
      id: 8,
      title: "Soft Top Convertable",
    },
    {
      id: 9,
      title: "Sports Car",
    },
    {
      id: 10,
      title: "Van",
    },
    {
      id: 11,
      title: "Wagon",
    },
    {
      id: 12,
      title: "Utility Truck",
    },
  ];
    export { citiesEn, citiesAr, languages, extras, seatCapacity,transmissionType, fuelType,badges, exteriorColor, interiorColor,regionalSpecs, no_ofCylinder,doors, bodyTYpe,horsePower   };
