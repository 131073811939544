import { createContext, useContext } from "react";
const MultiStepFormContext = createContext(undefined);
export const useMultiStepFormContext = () => {
    const context = useContext(MultiStepFormContext);
    if (!context) {
        throw new Error("useMultiStepFormContext must be used within a MultiStepFormProvider");
    }
    return context;
};
export default MultiStepFormContext;
