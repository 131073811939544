import React, { useState, useEffect, useRef } from "react";
import create360Viewer from "360-image-viewer";
import canvasFit from "canvas-fit";
import { Box, CircularProgress } from "@mui/material";
const PanoramaImageViewer = (props) => {
    const { src } = props;
    const ref = useRef(null);
    const viewer = useRef(null);
    const image = useRef(new Image());
    const [isReady, setIsReady] = useState(false);
    useEffect(() => {
        image.current.src = src;
        image.current.crossOrigin = "anonymous";
    }, [src]);
    useEffect(() => {
        image.current.onload = () => {
            viewer.current = create360Viewer({
                image: image.current,
                rotateSpeed: -0.15,
                fov: 1.22173,
                theta: -Math.PI / 2,
            });
            if (ref.current && viewer.current) {
                ref.current.appendChild(viewer.current.canvas);
                const fit = canvasFit(viewer.current.canvas, window, window.devicePixelRatio);
                window.addEventListener("resize", fit, false);
                fit();
                viewer.current.start();
                setIsReady(true);
            }
        };
        return () => {
            if (viewer.current) {
                viewer.current.destroy();
            }
        };
    }, [src]);
    const canvasStyle = {
        position: "unset"
    };
    return (<Box ref={ref} position={"relative"} overflow={"hidden"} width={"100%"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"} borderRadius={"8px"} sx={{ cursor: "pointer" }}>
            {isReady ? (viewer.current && (<canvas style={canvasStyle} ref={(el) => el && viewer.current?.canvas}/>)) : <CircularProgress />}
        </Box>);
};
export default PanoramaImageViewer;
