import { Box, Typography } from "@mui/material";
const IconWithBadge = (props) => {
    const { badgeStyle, icon, title, variant, type, className } = props;
    return (<Typography sx={badgeStyle} variant={variant} component={'span'} width={'max-content'}>
            {icon}&nbsp;
            <Box style={{
            whiteSpace: 'nowrap',
            width: type ? 200 : 'max-content',
            minWidth: 'initial',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'Roboto'
        }} className={className}>{title}</Box>
        </Typography>);
};
export default IconWithBadge;
