import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./modal-popup.scss";
import { makeStyles } from "@mui/styles";

const useStyles = (props) =>
  makeStyles(() => ({
    root: {
      "& .MuiDialog-paper": {
        ...props?.styles,
      },
    },
    closeButton: {
      marginLeft: "auto",
      position: "relative",
      right: "5px",
      top: "5px",
    },
  }));
const ModalPopup = (props) => {
  const { closePopup, open, title, children } = props;
  const classes = useStyles(props)();
  return (
    <Dialog
      open={open}
      onClose={closePopup}
      fullWidth
      maxWidth="md"
      className={classes.root}
      sx={{
        position: "absolute",
        zIndex: "999999999",
      }}
    >
      <IconButton onClick={closePopup} style={{ marginLeft: "auto" }}>
        <CloseIcon color="primary"></CloseIcon>
      </IconButton>
      <DialogTitle className="title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};
export default ModalPopup;
