import React from "react";
import {
  Container,
  Box,
  TextField,
  Typography,
  Stack,
  Link,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CustomAttachment from "../../components/custom-attachment-input/custom-attachment-input.component";

const CareerDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Container>
        <Box
          sx={{
            border: "1px solid #EFEFEF",
            padding: "18px 0px",
            display: "flex",
            justifyContent: "center",
            margin: "50px 0px 60px 0px",
          }}
        >
          <Typography
            sx={{
              background: "#EFEFEF",
              padding: "30px 60px",
              borderRadius: "8px",
            }}
          >
            For Ad
          </Typography>
        </Box>

        <Stack>
          <Breadcrumbs
            sx={{
              "& .MuiBreadcrumbs-separator": {
                marginRight: "0px",
                marginLeft: "0px",
              },
            }}
            separator={<NavigateNextIcon fontSize="15px" />}
          >
            <Link sx={{ textDecoration: "none", fontSize: "15px" }}>
              Career
            </Link>
            <Link sx={{ textDecoration: "none", fontSize: "15px" }}>
              Job Title
            </Link>
          </Breadcrumbs>
        </Stack>

        <Box className="job-info">
          <Typography
            variant="h3"
            sx={{
              fontSize: "18px !important",
              fontWeight: "700",
              margin: "50px 0px",
            }}
          >
            Lorem ispum dollor site amt
          </Typography>

          <Typography
            variant="body1"
            sx={{ fontSize: "15px !important", marginBottom: "20px" }}
          >
            In hac habitasse platea dictumst. Ut vitae ante eget elit vulputate
            finibus nec sed dui. In in turpis dolor. Praesent id massa sed
            libero malesuada gravida. Integer sed nunc vel dolor viverra
            lacinia. Quisque a nunc eget mauris elementum accumsan vitae ut
            neque. Sed metus nibh, tristique sed arcu ut, elementum lobortis
            mauris.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "15px !important", marginBottom: "20px" }}
          >
            Nam vel felis a est commodo tristique. Curabitur semper facilisis
            leo nec dictum. Aenean venenatis bibendum ligula eu facilisis.
            Mauris dignissim mi ut posuere rutrum. Duis porttitor in est vel
            accumsan. Duis nec velit nisl. Fusce aliquam iaculis diam eget
            aliquam. Fusce eu molestie ipsum, non condimentum neque. Etiam
            lobortis, augue at convallis scelerisque, justo velit gravida purus,
            et semper dolor odio ut erat. In ultricies quam eget nunc tristique
            consectetur. Interdum et malesuada fames ac ante ipsum primis in
            faucibus. Ut sit amet pharetra lectus. Proin commodo justo in lacus
            lobortis dictum. Proin a metus ac est luctus vehicula vel eget
            neque.
          </Typography>

          <Typography
            variant="body1"
            sx={{ fontSize: "15px !important", marginBottom: "20px" }}
          >
            Maecenas nec ante libero. Proin eget leo mauris. Proin ac leo a enim
            hendrerit sollicitudin. Quisque turpis mi, interdum vitae tincidunt
            in, iaculis sit amet lorem. Ut blandit, odio at vestibulum
            dignissim, ipsum eros gravida sem, vitae consectetur nisi arcu
            venenatis lorem. Morbi nulla tortor, bibendum ut nulla non, suscipit
            pretium metus. Duis quis sem sed quam elementum lobortis. Proin at
            neque a sapien blandit molestie sit amet sagittis sapien. Nulla
            facilisi. In quam ligula, tristique in malesuada quis, iaculis sed
            justo. Nulla facilisi. Nulla aliquam urna ac egestas cursus. Duis
            posuere gravida felis, sit amet ultrices mi vulputate nec.
          </Typography>

          <Typography
            variant="body1"
            sx={{ fontSize: "15px !important", marginBottom: "20px" }}
          >
            In at augue in elit malesuada accumsan. Sed quis dignissim ante. Ut
            nisl tortor, fringilla id felis sit amet, pretium commodo enim.
            Fusce risus eros, sagittis et maximus eget, sagittis sed urna. Etiam
            id feugiat arcu. Praesent in dolor auctor, maximus dolor eu, rutrum
            ex. In congue accumsan feugiat. Suspendisse mattis dolor sed ligula
            imperdiet consequat. Phasellus scelerisque ex lorem, ac aliquam
            neque pulvinar in. Proin at elit semper, ultrices dui nec, ornare
            neque. Duis suscipit, tortor nec mollis condimentum, nibh dui dictum
            quam, in ultrices nulla libero in neque. Vestibulum porta erat nec
            dignissim varius. Integer sit amet imperdiet lectus. Nullam mollis
            mi at risus
          </Typography>
        </Box>

        <Box className="form" sx={{ marginBottom: "100px" }}>
          <Typography
            variant="h3"
            sx={{
              fontSize: "18px !important",
              fontWeight: "700",
              margin: "50px 0px",
            }}
          >
            Apply for this position
          </Typography>

          <Grid container spacing={2}>
            <Grid item sm={8} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    placeholder="First Name"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "8px",
                        height: "55px",
                        marginBottom: "20px",
                      },
                    }}
                  ></TextField>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    placeholder="Last Name"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "8px",
                        height: "55px",
                        marginBottom: "20px",
                      },
                    }}
                  ></TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    placeholder="Phone Number"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "8px",
                        height: "55px",
                        marginBottom: "20px",
                      },
                    }}
                  ></TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    placeholder="Email"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "8px",
                        height: "55px",
                        marginBottom: "20px",
                      },
                    }}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4} xs={12}>
              <CustomAttachment title="Attach Cover" />
            </Grid>

            <Grid item sm={8} xs={12}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "15px",
                  margin: "20px 0px 17px 0px",
                }}
              >
                Message
              </Typography>
              <TextField
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    height: "180px",
                    marginBottom: "25px",
                  },
                }}
                multiline
              ></TextField>
            </Grid>

            <Grid item sm={4} xs={12}>
              <Box sx={{ marginTop: isMobile ? "20px" : "100px" }}>
                <CustomAttachment title="Attach Resume" />
              </Box>
            </Grid>

            <Grid item sm={8} xs={12}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flex={"auto"}
              >
                <Button
                  sx={{
                    background: "#000000",
                    color: "#fff",
                    margin: "20px 0px 0px 0px",
                    display: "flex",
                    width: "370px",
                    height: "55px",
                    borderRadius: "8px",
                    textAlign: "center",

                    "&:hover": {
                      background: "#000000",
                      color: "#fff",
                    },
                  }}
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default CareerDetails;
