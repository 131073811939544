import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as ROUTES from '.././constants/routes';
export const LoggedOutRoute = (props) => {
    const { outlet } = props;
    // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn) {
        return <Navigate to={ROUTES.HOME} replace/>;
    }
    return outlet;
};
export const ProtectedRoute = (props) => {
    const { outlet } = props;
    // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
     const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn) {
        return outlet;
    }
    return <Navigate to={ROUTES.LOGIN} replace/>;
};
