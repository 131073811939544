import { useState, forwardRef } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import Icons from "../../assets/icons";

const FormInput = forwardRef((props, ref) => {
  const theme = useTheme();
  const [showPass, setShowPass] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPass(!showPass);
  };

  const handleInputChange = (e) => {
    props?.onChange?.(e);
  };

  const inputValue =
    props.value !== undefined && props.value !== null ? props.value : "";

  return (
    <TextField
      key={props.name}
      {...props}
      ref={ref}
      type={
        props.type === "password" && showPass
          ? "text"
          : props.type === "password" && !showPass
          ? "password"
          : "text"
      }
      autoComplete="off"
      InputProps={{
        shrink: inputValue !== "",
        endAdornment: (
          <>
            {props.type === "password" && (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPass ? <Icons.EYE /> : <Icons.EYE_OFF />}
                </IconButton>
              </InputAdornment>
            )}
            {props.currency && (
              <Typography
                color={theme.palette.primary.main}
                sx={{ opacity: "0.5" }}
              >
                {props?.currency}
              </Typography>
            )}
          </>
        ),
        startAdornment: props?.icon,
        rows: props?.name === "description" ? 4 : "unset",
      }}
      InputLabelProps={{
        style: {
          color: theme.palette.primary.main,
        },
      }}
      style={{ flex: 1 }}
      sx={{
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            border: props?.required
              ? `1px solid ${theme.palette.primary.main}`
              : props?.error
              ? "1px solid red"
              : "1px solid rgba(0, 0, 0, 0.20)",
          },
          "& fieldset": {
            border: props?.required
              ? `1px solid ${theme.palette.primary.main}`
              : "1px solid rgba(0, 0, 0, 0.20)",
          },
        },
        "&:hover": {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: props?.required
                ? `1px solid ${theme.palette.primary.main}`
                : "1px solid rgba(0, 0, 0, 0.20)",
            },
          },
        },
        "&:focus-within": {
          opacity: 0.5,
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root::placeholder": {
          color: theme.palette.secondary.main,
          opacity: 1,
        },
        ...props?.sx,
      }}
      value={inputValue}
      onChange={handleInputChange}
      name={props?.name}
      label={props?.label}
    />
  );
});

export default FormInput;
