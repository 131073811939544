import React, { useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SectionTitle from "../../components/section-title/section-title.component";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import "../AboutUs/about-us.scss";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const typographyStyles = {
  fontSize: "14px",
  marginTop: "20px",
};

const PrivacyPolicy = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleChange = (_, newIndex) => setActiveIndex(newIndex);

  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Helmet>
        <title>{t("privacy-policy.title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>
      <Container>
        <Box
          margin="70px auto 0px auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle
            variant={"h2"}
            title={t("privacy-policy.banner-title")}
          />
        </Box>

        <Box className="about-tabs" style={{ display: "flex" }}>
          <VerticalTabs value={activeIndex} onChange={handleChange}>
            <MyTab label="Introduction" />
            <MyTab label="Collection of Information" />
            <MyTab label="Use of Information" />
            <MyTab label="Disclosure of Information" />
            <MyTab label="User Choices and Preferences" />
            <MyTab label="Security Measures" />
            <MyTab label="Changes to this Policy" />
            <MyTab label="Contact Us" />
          </VerticalTabs>

          {activeIndex === 0 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Introduction
              </Typography>
              <Typography style={typographyStyles}>
                Our web application ("the App", `we` or `us`) is committed to
                protecting the privacy of its users. This privacy policy
                explains how we collect, use, and disclose your personal
                information. By using the App, you agree to the terms of this
                privacy policy.
              </Typography>
            </TabContainer>
          )}
          {activeIndex === 1 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Collection of Information
              </Typography>
              <Typography style={typographyStyles}>
                We may collect the following types of personal information from
                users:
              </Typography>

              <List sx={{ padding: "0px" }}>
                <ListItem style={typographyStyles}>
                  Contact information: such as name, email address, or phone
                  number.
                </ListItem>
                <ListItem style={typographyStyles}>
                  Payment Information: such as Paypal Account details, Bank Card
                  details, Bank account details.
                </ListItem>
                <ListItem style={typographyStyles}>
                  Device information: such as device type, operating system, and
                  unique device identifiers.
                </ListItem>
                <ListItem style={typographyStyles}>
                  Usage information: such as search queries, videos watched, and
                  interactions with other users.
                </ListItem>
              </List>
            </TabContainer>
          )}
          {activeIndex === 2 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Use of Information
              </Typography>

              <Typography style={typographyStyles}>
                We may use the personal information we collect to:
              </Typography>
              <List sx={{ padding: "0px" }}>
                <ListItem style={typographyStyles}>
                  Improve the App's functionality and personalize the user
                  experience.
                </ListItem>
                <ListItem style={typographyStyles}>
                  Deliver targeted advertising and content.
                </ListItem>
                <ListItem style={typographyStyles}>
                  Communicate with users regarding their account or to respond
                  to inquiries.
                </ListItem>
                <ListItem style={typographyStyles}>
                  Comply with legal obligations.
                </ListItem>
              </List>
            </TabContainer>
          )}
          {activeIndex === 3 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Disclosure of Information
              </Typography>
              <Typography style={typographyStyles}>
                All credit/debit cards details and personally identifiable
                information will NOT be stored, sold, shared, rented or leased
                to any third parties.
              </Typography>
              <Typography style={typographyStyles}>
                We may share personal information with the following third
                parties:
              </Typography>
              <List sx={{ padding: "0px" }}>
                <ListItem style={typographyStyles}>
                  Service providers: such as payment gateways, analytics
                  companies and advertising partners.
                </ListItem>
                <ListItem style={typographyStyles}>
                  Other users: such as when users interact with each other on
                  the App.
                </ListItem>
                <ListItem style={typographyStyles}>
                  Law enforcement or other authorities: if required by law or if
                  we believe it is necessary to protect our rights, property, or
                  safety.
                </ListItem>
                <ListItem style={typographyStyles}>
                  Comply with legal obligations.
                </ListItem>
              </List>
            </TabContainer>
          )}
          {activeIndex === 4 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                User Choices and Preferences
              </Typography>
              <Typography style={typographyStyles}>
                Users may access, manage, or delete their personal information
                by following the instructions in the App. Users may also choose
                to opt-out of receiving promotional communications from us by
                following the instructions in those communications.
              </Typography>
            </TabContainer>
          )}
          {activeIndex === 5 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Security Measures
              </Typography>
              <Typography style={typographyStyles}>
                We take reasonable measures to protect user personal information
                from unauthorized access, disclosure, or destruction. These
                measures may include encryption, firewalls, and secure server
                facilities.
              </Typography>
            </TabContainer>
          )}

          {activeIndex === 6 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Changes to this Policy
              </Typography>
              <Typography style={typographyStyles}>
                Our Policies and Terms & Conditions may be changed or updated
                occasionally to meet the requirements and standards. Therefore,
                the customers are encouraged to frequently visit these sections
                in order to be updated about the changes on the website.
                Modifications will be effective on the day they are posted.
              </Typography>
            </TabContainer>
          )}

          {activeIndex === 7 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Contact Us
              </Typography>
              <Typography style={typographyStyles}>
                If you have any questions or concerns about our privacy policy,
                you may contact us at info@kulushae.com
              </Typography>
            </TabContainer>
          )}
        </Box>
      </Container>
    </>
  );
};

const VerticalTabs = withStyles((theme) => ({
  flexContainer: {
    flexDirection: "column",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const MyTab = withStyles((theme) => ({
  selected: {
    color: "#000",
    borderBottom: "2px solid #000000",
  },
}))(Tab);

const TabContainer = ({ children }) => {
  return (
    <Typography
      component="div"
      style={{ padding: "12px 24px", width: "fit-content" }}
    >
      {children}
    </Typography>
  );
};

export default PrivacyPolicy;
