import { Typography, Box, Button } from "@mui/material";
const CustomizedButton = (props) => {
  const { title, name, logo, btnStyle, nameStyle, titleStyle, sx } = props;
  return (
    <Button style={btnStyle} sx={sx}>
      {logo}
      <Box width="max-content">
        <Typography style={nameStyle}>{name}</Typography>
        {title && <Typography style={titleStyle}>{title}</Typography>}
      </Box>
    </Button>
  );
};
export default CustomizedButton;
