import { Box, Typography, useTheme } from "@mui/material";
import Icons from "../assets/icons";
import { Link } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import app from "../includes/firebase";
import { EMAIL_REGISTER_MUTATION } from "../api/queries/auth.queries";
import { useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

const SocialAuthentication = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const [emailRegisterMutation] = useMutation(EMAIL_REGISTER_MUTATION);

  const handleGoogleSignIn = async () => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    try {
      const userData = await signInWithPopup(auth, provider);

      // Extract user data from Firebase response
      const displayName = userData.user.displayName;
      const [firstName, lastName] = displayName.split(" ");
      const email = userData.user.email;
      const id = userData.user.uid;

      // Save user data to localStorage
      const userdata = {
        id,
        firstName,
        lastName,
        email,
        displayName,
      };

      localStorage.setItem("userData", JSON.stringify(userdata));
      localStorage.setItem("token", userData.user.accessToken);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem(
        "refreshToken",
        userData.user.stsTokenManager.refreshToken
      );

      // Navigate to home
      navigate(ROUTES.HOME);
    } catch (error) {
      console.error(error.message);
      setErrorMessage(error.message);
    }
  };

  const handleFacebookLogin = async () => {
    const auth = getAuth(app);
    const provider = new FacebookAuthProvider();
    try {
      const userData = signInWithPopup(auth, provider);
      console.log(userData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const socialNetwork = [
    {
      name: "Facebook",
      logo: <Icons.FACEBOOK_AUTH />,
      onClick: () => {
        console.log("clicked");
      },
    },
    {
      name: "Google",
      logo: <Icons.GOOGLE_AUTH />,
      onClick: () => {
        handleGoogleSignIn();
      },
    },
    {
      name: "Apple",
      logo: <Icons.APPLE />,
      onClick: () => {
        handleFacebookLogin();
      },
    },
  ];
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        marginTop="24px"
      >
        {socialNetwork.map((item, index) => (
          <Box
            key={index}
            sx={{ cursor: "pointer" }}
            alignItems={"center"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
            width={80}
            onClick={item.onClick}
          >
            {item.logo}
          </Box>
        ))}
      </Box>
      <Typography
        sx={{
          marginTop: "15px",
          textAlign: "center",
        }}
      >
        {errorMessage}
      </Typography>
    </>
  );
};
export default SocialAuthentication;
