import { useTranslation } from "react-i18next";
import { Box, Container, Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  CustomTab,
  CustomTabPanel,
} from "../../../components/tabs/tabs.component";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import ByEmail from "../../../pages/Authentication/ForgetPassword/ByEmail";
import ByPhoneNumber from "../../../pages/Authentication/ForgetPassword/ByPhoneNumber";
import SectionTitle from "../../../components/section-title/section-title.component";
const useStyles = makeStyles((theme) => ({
  muiBox: {
    "& .MuiTabs-root": {
      width: "100%",
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.08)",
      borderRadius: "16px !important",
    },
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      width: "100%",
    },
    "& .MuiButtonBase-root": {
      borderRadius: 15,
      background: theme.palette.secondary.main,
      boxShadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.08)",
      color: theme.palette.primary.main,
      width: "50%",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "unset",
    },
    "& .MuiButtonBase-root.Mui-selected": {
      background: theme.palette.primary.orange,
      color: theme.palette.secondary.main,
    },
    "& .MuiButtonBase-root.Mui-selected .MuiBox-root": {
      color: "unset !important",
    },
  },
}));
const ForgetPassword = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const classes = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [tabValue, setTabValue] = useState(0);
  return (
    <>
      <Container>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "40px 0px 46px 0px",
            }}
          >
            {" "}
            <SectionTitle
              variant={"h2"}
              title={t("authentication.forget_password.forget_pwd")}
            />
          </Box>

          <Stack
            className="autharea"
            sx={{
              overflow: "hidden",
              display: "flex",
              justifyItems: "center",
              width: isMobile ? "100%" : "508px",
              border: "1px solid #cccccc",
              borderRadius: "20px",
              margin: isMobile ? "0px auto 120px auto" : "0px auto 240px auto",
            }}
          >
            <CustomTab
              tabValue={tabValue}
              setTabValue={setTabValue}
              classes={classes}
            >
              <CustomTabPanel
                label={
                  <Box
                    color={theme.palette.primary.main}
                    fontSize={14}
                    textTransform="capitalize"
                    sx={{ placeItems: "center" }}
                  >
                    {t("authentication.login.social.email")}
                  </Box>
                }
              >
                <ByEmail />
              </CustomTabPanel>
              <CustomTabPanel
                label={
                  <Box
                    color={theme.palette.primary.main}
                    fontSize={14}
                    textTransform="capitalize"
                    sx={{ placeItems: "center" }}
                  >
                    {t("authentication.login.mobile")}
                  </Box>
                }
              >
                <ByPhoneNumber />
              </CustomTabPanel>
            </CustomTab>
          </Stack>
        </Box>
      </Container>
    </>
  );
};
export default ForgetPassword;
