import FormBox from "../../../components/form-box/form-box.component";
import FormInput from "../../../components/form-input/form-input.component";
import { useTranslation } from "react-i18next";
import Icons from "../../../assets/icons";
import * as yup from "yup";
import { useState } from "react";
import { Alert, Box, Collapse, IconButton, Stack } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import Toaster from "../../../components/toaster/toaster.component";
import BackTo from "../../../components/back-to/back-to.component";
import * as ROUTES from "../../../constants/routes";
import client from "../../../api/client";
import { UPDATE_PASSWORD_BY_EMAIL_MUTATION } from "../../../api/queries/auth.queries";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import SectionTitle from "../../../components/section-title/section-title.component";
const CreateNewPassword = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const ByEmailSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required(t("authentication.create_password.new_password_required")),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), null],
        t("authentication.create_password.passwords_must_match")
      )
      .required(t("authentication.create_password.confirm_password_required")),
  });
  const onSubmitCreateNewPassword = async (event) => {
    event.preventDefault();
    try {
      const { newPassword, confirmPassword } = formik.values;
      formik.setTouched({ newPassword: true, confirmPassword: true });
      ByEmailSchema.validateSync(
        { newPassword, confirmPassword },
        { abortEarly: false }
      );
      const response = await client.mutate({
        mutation: UPDATE_PASSWORD_BY_EMAIL_MUTATION,
        variables: {
          password: confirmPassword,
        },
      });
      if (response?.data) {
        setOpenSuccess(true);
        formik.resetForm();
        formik.submitForm();
        localStorage.clear();
        setTimeout(() => {
          setOpenSuccess(false);
          navigate(ROUTES.LOGIN);
        }, 2000);
      } else {
        setErrorMessage(t("update_pass_failed"));
        setOpenAlert(true);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        formik.setErrors(validationErrors);
      } else {
        const { message } = error;
        setErrorMessage(message);
        setOpenAlert(true);
      }
    }
  };
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const handleClose = () => {
    setOpenAlert(false);
  };
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: ByEmailSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await onSubmitCreateNewPassword(values);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
  });
  return (
    <>
      <Box sx={{ minHeight: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "40px 0px 46px 0px",
          }}
        >
          {" "}
          <SectionTitle
            variant={"h2"}
            title={t("authentication.create_password.create_new_pass")}
          />
        </Box>
        <Stack
          className="autharea"
          sx={{
            overflow: "hidden",
            display: "flex",
            justifyItems: "center",
            width: isMobile ? "100%" : "508px",
            border: "1px solid #cccccc",
            borderRadius: "20px",
            margin: isMobile ? "0px auto 120px auto" : "0px auto 240px auto",
          }}
        >
          {errorMessage && (
            <Collapse in={openAlert}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleClose}
                  >
                    <Icons.CLOSE />
                  </IconButton>
                }
              >
                {errorMessage}
              </Alert>
            </Collapse>
          )}
          <FormBox
            onSubmit={(event) => onSubmitCreateNewPassword(event)}
            sx={{ display: "grid" }}
            submitValue={t("common.verify")}
            submitStyle={{
              borderRadius: 40,
              border: `1px solid ${theme.palette.primary.main}`,
              background: theme.palette.secondary.main,
              color: theme.palette.primary.main,
              textTransform: "capitalize",
              margin: "25px 0",
            }}
          >
            <Box margin={"10px 0"}>
              <FormInput
                {...formik.getFieldProps("newPassword")}
                name="newPassword"
                required
                fullWidth
                placeholder={t("authentication.create_password.new_password")}
                type="password"
                error={
                  formik.touched["newPassword"] &&
                  Boolean(formik.errors["newPassword"])
                }
                helperText={
                  formik.touched["newPassword"] && formik.errors["newPassword"]
                }
                label={"New Password"}
                value={
                  formik.values && formik.values["newPassword"]
                    ? formik.values["newPassword"]
                    : ""
                }
                icon={<Icons.LOCK style={{ margin: "0px 5px" }} />}
              />
            </Box>
            <Box margin={"10px 0"}>
              <FormInput
                {...formik.getFieldProps("confirmPassword")}
                name="confirmPassword"
                required
                fullWidth
                placeholder={t(
                  "authentication.create_password.confirm_password"
                )}
                type="password"
                error={
                  formik.touched["confirmPassword"] &&
                  Boolean(formik.errors["confirmPassword"])
                }
                helperText={
                  formik.touched["confirmPassword"] &&
                  formik.errors["confirmPassword"]
                }
                label={"Confirm Password"}
                value={
                  formik.values && formik.values["confirmPassword"]
                    ? formik.values["confirmPassword"]
                    : ""
                }
                icon={<Icons.LOCK style={{ margin: "0px 5px" }} />}
              />
            </Box>
          </FormBox>
        </Stack>
      </Box>
      <Toaster
        open={openSuccess}
        title={`${t("common.great")}!`}
        description={t("authentication.create_password.new_password_created")}
        handleClose={handleCloseSuccess}
        icon={<Icons.AGREE style={{ margin: "15px 0" }} />}
      />
    </>
  );
};
export default CreateNewPassword;
