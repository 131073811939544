import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import IconWithBadge from "../../../components/icon-with-badge/icon-with-badge.component";
import { GET_PROPERTY } from "../../../api/queries/featured-ads.queries";
import * as ROUTES from "../../../constants/routes";
import Icons from "../../../assets/icons";
import { makeStyles } from "@mui/styles";
import PanoramaImageViewer from "../../../components/panorama-image-viewer/panorama-image-viewer.component";
import ResponsiveCarousel from "../../../components/responsive-carousel/responsive-carousel.component";
import ControlledMap from "../../../components/geo-map/map.component";
import { formatNumberWithCommas } from "../../../helpers/global";
import { Container } from "@mui/system";
import "./single-property.scss";
import RelevantListings from "./RelevantListings";
import AdsImg from "../../../assets/images/ads-Image.png";
import { Helmet } from "react-helmet";
import { Link } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { VERIFY_TOKEN } from "../../../api/services/jwt.service";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactImageGallery from "react-image-gallery";
import { FaXmark } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { formatDate } from '../../../helpers/global'
import { useSelector } from "react-redux";
import { addFavorite, removeFavorite } from "../../../store/slices/favoriteSlice";
import { ADD_TO_FAVOURITE_MUTATION } from "../../../api/queries/home.queries";
import { decodeId, encodeId } from "../../../helpers/idEncryption";
import Skeleton from "@mui/material/Skeleton";
import { generatePath } from "react-router-dom";



const apiURL = process.env.REACT_APP_REST_API_URL;
const appURL = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles(() => ({
  icon: {
    padding: 9,
    margin: 5,
    borderRadius: 9,
    width: 44,
    height: 44,
    border: "1px solid #D9D9D9",
    cursor: "pointer",
  },
  sliderIcons: {
    padding: 10,
    margin: 5,
    borderRadius: 9,
    width: 48,
    height: 48,
    backgroundColor: "#00000080",
    cursor: "pointer",
  },
  selectedIcon: {
    backgroundColor: "#000",
  },
}));




const PropertyDetails = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [initialImageIndex, setInitialImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const { id: paramId } = useParams();
  const { id: encryptedId } = useParams(); 
  const decryptedId = decodeId(encryptedId);

// if user open url like property/1 then hashing the parameter here:
  useEffect(() => {
    const unhashedId = decodeId(paramId);
    if(!unhashedId){
      const hashedId = encodeId(paramId);
       if(hashedId){
        navigate(generatePath(`${ROUTES.PROPERTY}`, { id: hashedId }));
       }
       else{
        console.log("invalid id")
       }
    }
    else{
      console.log("Valid unhashed ID (decoded):", unhashedId);
    }
  }, [paramId, navigate])



  // Prepare images for the gallery,
 
  const openGallery = (index) => {
    setInitialImageIndex(index);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };


  const dispatch = useDispatch();
  const [addToFavorite] = useMutation(ADD_TO_FAVOURITE_MUTATION);







  const [textMessage, setTextMessage] = useState("");
 

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    loading: isLoadingProperty,
    error: isErrorProperty,
    data,
  } = useQuery(GET_PROPERTY, {
    variables: {
      propertyId: Number(decryptedId),
    },
  });

  useEffect(() => {
    if (isErrorProperty) {
      console.error("Error property:", isErrorProperty);
    }
  }, [isErrorProperty]);
  useEffect(() => {
    console.log("loading");
  }, [isLoadingProperty]);
  const property = data?.["property"];


  const isFavorite = useSelector((state) => state.favorites.items.includes(property?.id))

   const handleToggleFavorite = async () => {
        try {
            const like = !isFavorite;
            const { data } = await addToFavorite({
                variables: { like, itemId: property?.id, type: 'property' },
            });
            if (like) {
                dispatch(addFavorite(property?.id));
            } else {
                dispatch(removeFavorite(property?.id));
            }
        } catch (error) {
            console.error('Error adding/removing from favorites:', error);
        }
    };
 



  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const verifyTokenExtractUser = async () => {
      try {
        const token = localStorage.getItem("token");

        if (token) {
          const user = await VERIFY_TOKEN(token);
          setCurrentUser(user);
        }
      } catch (error) {
        console.log("error verifying the token");
      }
    };

    verifyTokenExtractUser();
  }, []);

  const storedUser = localStorage.getItem("userData");
  const parsedUserData = storedUser ? JSON.parse(storedUser) : null;
  const userId = parsedUserData ? parsedUserData.id : null;

  const handleSendMessage = async () => {
    if (textMessage) {
      try {
        const {
          id,
          category_id,
          user_id: { id: receiverid },
        } = property;
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("item_id", id);
        formData.append("category_id", category_id);
        formData.append("receiver_id", receiverid);
        formData.append("sender_id", userId);

        if (textMessage) {
          formData.append("message", textMessage);
          formData.append("type", "text");
        }

        const response = await axios.post(
          `${apiURL}/pusher/send-message`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setTextMessage("");
          const chatId = response.data.chat_id;
          navigate(`${ROUTES.CHAT_BY_ID.replace(":chat_id", chatId)}`);
        } else {
          console.error("Failed to send message:", response.statusText);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };



  const isOwner = property?.user_id?.id === currentUser?.id;

  const memberStyles = {
    borderRadius: "100px !important",
    background: "#EFEFEF",
    padding: "9px 12px !important",
    display: "flex",
    gap: "5px!important",
    margin: "12px 20px !important",
    position: "absolute",
    right: 0,
    alignItems: "center",
    color: theme.palette.primary.main,
  };
  const userBadge = {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  };
  const contactBadge = {
    borderRadius: "8px",
    border: "1px solid #EFEFEF",
    padding: "12px 18px",
    margin: "8px 0px!important",
    display: "flex",
    alignItems: "center",
    fontSize: "16px!important",
    color: "#000!important ",
  };
  const [selectedIcon, setSelectedIcon] = useState("responsive-carousel");
  const handleIconClick = (iconType) => {
    setSelectedIcon(iconType);
  };
  const showPanorama = selectedIcon === "panorama-viewer";
  const showVideoBox = selectedIcon === "video";
  const showCarousel = selectedIcon === "responsive-carousel";
  const showMap = selectedIcon === "geo-map";
  const youtubeUrl = property?.socialmedia
    ?.map((item) => {
      if (item.type === "youtube_url") {
        return item.value;
      }
      return null;
    })
    .find((value) => value !== null);
  const isValidYouTubeUrl = (url) => {
    const youtubeUrlRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    return youtubeUrlRegex.test(url);
  };
  const MediaNotAvailable = ({ child, text }) => (
    <Box display={"grid"} height={"100%"}>
      <Box alignSelf={"center"} justifySelf={"center"}>
        {child}
        <Typography color={theme.palette.primary.main}>{text}</Typography>
      </Box>
    </Box>
  );

  const handleBackClick = () => {
    navigate(ROUTES.HOME)
  }

  const baseURL = process.env.REACT_APP_BASE_URL;

  const galleryImages = property?.images.map((img) => ({
    original: baseURL + img.image,
    thumbnail: baseURL + img.image,
  }));



  useEffect(() => {
    const loadData = async () => {
      // simulate loading delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };
    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("property-detail-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>
      <Container sx={{ minHeight: "100vh" }} className="container">
        <Grid
          maxWidth={1320}
          container
          direction={"row"}
          style={
            isMobile
              ? {
                  padding: 0,
                  display: "grid",
                  margin: "20px 0",
                  gap: "5px",
                }
              : {
                  padding: "20px 0px",
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          <Grid item xs={8}>
             <Box sx={{
              display:"flex",
              alignItems:"center",
              gap:"8px",
              fontSize:"18px !important",
              color:"#000",
              cursor: "pointer",
              fontWeight: "400",
              width: "70px"
             }} onClick={handleBackClick}> {i18n?.language === "en"? <FaAngleLeft size={18} /> : <FaAngleRight  size={18} />}  {t('common.back')} </Box>
          </Grid>
          <Grid item xs />
          <Grid item xs textAlign={"end"}>
           
            <Icons.HEART
              className={classes.icon}
              style={{ fill: isFavorite? "red" : "white" }}
             onClick={handleToggleFavorite}
            />
            <Icons.SHARE className={classes.icon} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={
            isMobile
              ? {
                  display: "block",
                  width: "100%",
                  margin: "0px",
                  padding: 0,
                }
              : {
                  flexDirection: "row",
                  display: "flex",
                  height: "100%",
                  margin: "unset",
                  width: "unset",
                }
          }
        >
          <Grid
            item
            xs={8}
            style={
              isMobile
                ? {
                    width: "100%",
                    maxWidth: "100%",
                    padding: "unset",
                  }
                : {
                    height: "max-content",
                    padding:
                      i18n?.language === "en" ? "0 16px 0 0" : "0 0px 0 16px",
                  }
            }
          >
            <Box
              position={"relative"}
              height={
                (selectedIcon === "responsive-carousel" && isMobile) ||
                (showPanorama && isMobile) ||
                (selectedIcon === "video" && isMobile)
                  ? "40vh"
                  : "60vh"
              }
              border={
                property?.images?.length === 0 ||
                property?.socialmedia?.length === 0
                  ? "1px solid #D9D9D9"
                  : "unset"
              }
              borderRadius={"8px"}
            >
              <Grid
                container
                direction="row"
                alignItems={"center"}
                position={"absolute"}
                top={"2%"}
                zIndex={999}
              >
                {/* <Grid item xs>
                    <IconWithBadge
                        title={property?.category_id}
                        badgeStyle={{ padding: '5px 9px', width: 'max-content', borderRadius: '0px 3px 3px 0px', fontSize: 12, background: theme.palette.secondary.main }}
                    />
                </Grid> */}
                <Grid item xs />
                <Grid
                  item
                  xs={6}
                  textAlign={"end"}
                  padding={"0 10px"}
                  style={{ maxWidth: "100%", flexBasis: "unset" }}
                >
                  <Icons.VIDEO
                    className={`${classes.sliderIcons} ${
                      selectedIcon === "video" ? classes.selectedIcon : ""
                    }`}
                    onClick={() => handleIconClick("video")}
                  />
                  <Icons.IMAGE
                    className={`${classes.sliderIcons} ${
                      selectedIcon === "responsive-carousel"
                        ? classes.selectedIcon
                        : ""
                    }`}
                    onClick={() => handleIconClick("responsive-carousel")}
                  />
                  <Icons.MAP
                    className={`${classes.sliderIcons} ${
                      selectedIcon === "geo-map" ? classes.selectedIcon : ""
                    }`}
                    onClick={() => handleIconClick("geo-map")}
                  />
                  <Icons.FLIP_CAMERA
                    className={`${classes.sliderIcons} ${
                      selectedIcon === "panorama-viewer"
                        ? classes.selectedIcon
                        : ""
                    }`}
                    onClick={() => handleIconClick("panorama-viewer")}
                  />
                </Grid>
              </Grid>
              {showPanorama && property?.images?.length > 0 && (
                <PanoramaImageViewer
                  src={
                    "https://c7.alamy.com/360/RP99CC/minsk-belarus-august-2017-full-seamless-360-degrees-angle-view-panorama-in-interior-guestroom-hall-with-furniture-in-modern-hotel-in-equirectang-RP99CC.jpg"
                  }
                />
              )}
              {showPanorama && property?.images?.length === 0 && (
                <MediaNotAvailable
                  child={<Icons.NO_IMAGE />}
                  text={"No 360 Image Found"}
                />
              )}
              {showVideoBox &&
                property?.socialmedia?.length > 0 &&
                isValidYouTubeUrl(youtubeUrl) && (
                  <iframe
                    width="100%"
                    height="100%"
                    src={youtubeUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ borderRadius: "8px" }}
                  />
                )}
              {showVideoBox &&
                property?.socialmedia?.length >= 0 &&
                !isValidYouTubeUrl(youtubeUrl) && (
                  <MediaNotAvailable
                    child={<Icons.NO_FILM_MOVIE />}
                    text={"No Recorded Film Found"}
                  />
                )}
              {showMap && (
                <ControlledMap
                  end={property?.location?.split(",")}
                  selectedPlace={{
                    place_name: property?.neighbourhood,
                    center: property?.location?.split(","),
                  }}
                />
              )}
            {showCarousel && property?.images?.length > 0 && (
              loading ? (
                <Skeleton 
                variant="rect"
                style={{ borderRadius: "5px" }}
                animation="wave"
                width={750}
                height={545}
                />
              ) : (
                <ResponsiveCarousel
                  images={property?.images}
                  hideThumbs={false}
                  onImageClick={openGallery}
                />
              )
            )}
                   {isGalleryOpen && (
              
                  <div className="gallery-modal">
                    <button onClick={closeGallery} className="gallery-close-button"><FaXmark size={25} /></button>
                    
                    {
                    property?.images?.length > 0 &&(
                    
                      <ReactImageGallery items={galleryImages} startIndex={initialImageIndex} showNav={true} showFullscreenButton={false} showPlayButton={false}/>
                    )
                  }
                  </div>
                
                )}

              {showCarousel && property?.images?.length === 0 && (
                <MediaNotAvailable
                  child={<Icons.NO_IMAGE />}
                  text={"No Gallery Found"}
                />
              )}
            </Box>
          </Grid>
          {property?.user_id && (
            <Grid
              item
              xs={12}
              md={4}
              paddingTop={"0px !important"}
              style={
                isMobile ? { paddingLeft: "0px" } : { paddingLeft: "10px" }
              }
            >
              <Box
                borderRadius={"8px !important"}
                border={"1px solid #D9D9D9"}
                position={"relative"}
                style={
                  isMobile
                    ? {
                        maxWidth: "100%",
                        padding: "0",
                        marginTop: "120px",
                      }
                    : {
                        height: "96%",
                        padding: "0 0px",
                        marginTop: "0px",
                      }
                }
              >
                <IconWithBadge
                  icon={<Icons.MEMBER />}
                  title={property?.user_id?.member_since ? `${t('property-details.member')}` : ""}
                  badgeStyle={memberStyles}
                />
                <Box textAlign={"center"}>
                  
                {property?.user_id?.image ? (
                <img
                  src={`${appURL}${property?.user_id?.image}`}
                  alt="user"
                  onError={(e) => {
                    e.target.onerror = null; 
                    e.target.src = require("../../../assets/icons/new-logo.svg").default;
                    e.target.style.filter = "grayScale(1)";
                    e.target.style.margin = "30px auto";

                  }}
                />
              ) : (
                <Icons.NEW_LOGO style={{ margin: "30px auto", filter: "grayScale(1)" }} />
              )}
                  <Typography
                    style={{ fontSize: "18px", fontWeight: "600", textTransform:"capitalize" }}
                    color={theme.palette.primary.main}
                    variant="h6"
                  >{property?.user_id?.first_name ? property.user_id.first_name : ""}{" "}
                   {property?.user_id?.last_name ? property.user_id.last_name : ""}</Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    Agency : Cash Gate LLC
                  </Typography>
                  <Box
                    display={"flex"}
                    color={theme.palette.primary.main}
                    fontSize={14}
                    fontWeight={400}
                    marginTop={"25px"}
                    sx={{
                      placeContent: "center",
                      borderTop: "1px solid #D9D9D9",
                      borderBottom: "1px solid #D9D9D9",
                      padding: "12px 0px",
                    }}
                  >
                    <IconWithBadge
                      icon={<Icons.USER />}
                      title={`${property?.user_id?.member_since}`}
                      badgeStyle={userBadge}
                    />
                    <Typography
                      color={theme.palette.primary.main}
                      dangerouslySetInnerHTML={{
                        __html: `&nbsp; - &nbsp;<span style="text-decoration: underline; font-weight: 700; font-size: 14px; color: #000;">${t('property-details.total')} ${property?.user_id?.total_listings} ${t('property-details.listed')}</span>`,
                      }}
                    />
                  </Box>

                  <Box>
                    <Typography
                      style={{ fontSize: "16px !important", color: "#000" }}
                      fontWeight={400}
                      marginTop={2}
                    >
                      {t("property-details.user-languages")}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    margin={"10px 0"}
                    sx={{ cursor: "pointer" }}
                  >
                    {property?.user_id?.email && (
                      <Link
                        href={`mailto:${property.user_id.email}`}
                        style={{
                          textDecoration: "unset",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <IconWithBadge
                          icon={<Icons.MAIL />}
                          title={t("property-details.email")}
                          badgeStyle={contactBadge}
                        />
                      </Link>
                    )}

                    {
                      property?.user_id?.phone && (
                        <Link
                        href={`tel:${property?.user_id?.phone}`}
                        style={{
                          textDecoration: "unset",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <IconWithBadge
                          icon={<Icons.CALL_PHONE />}
                          title={t("property-details.call")}
                          badgeStyle={contactBadge}
                        />
                      </Link>
                      )
                    }
                   

                    {
                      property?.user_id?.phone && (
                        <Link
                        href={`https://wa.me/${property?.user_id?.phone}`}
                        target="blanket"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "unset",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <IconWithBadge
                          icon={<Icons.WHATSAPP />}
                          title={t("property-details.Whatsapp")}
                          badgeStyle={contactBadge}
                        />
                      </Link>
                      )
                    }

                   
                  </Box>

                  {/* review section */}
                  {/* <Box
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    margin={"25px 0 0 0"}
                    sx={{ cursor: "pointer" }}
                  >
                    <IconWithBadge
                      icon={<Icons.REVIEW />}
                      title={t("property-details.review")}
                      badgeStyle={contactBadge}
                      variant={"h6"}
                    />
                    <IconWithBadge
                      icon={<Icons.LIKE />}
                      title={""}
                      badgeStyle={contactBadge}
                      variant={"h6"}
                    />
                    <IconWithBadge
                      icon={<Icons.DISLIKE />}
                      title={""}
                      badgeStyle={contactBadge}
                      variant={"h6"}
                    />
                  </Box> */}

                  {/* <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    margin={"4px 0 25px 0px"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      (261) Reviews
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      (200){" "}
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      (31)
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          spacing={2}
          style={
            isMobile
              ? {
                  marginLeft: 0,
                  width: "100%",
                }
              : {
                  flexDirection: "row",
                  display: "flex",
                  height: "100%",
                }
          }
        >
          <Grid
            item
            xs={8}
            style={
              isMobile
                ? {
                    maxWidth: "100%",
                    padding: "0",
                    flexBasis: "unset",
                  }
                : {
                    height: "max-content",
                    padding: "0 15px !important",
                  }
            }
          >
            <Grid
              container
              direction="row"
              alignItems={"center"}
              paddingTop={"60px"}
              marginTop={"30px"}
            >
              <Grid item xs>
                <Typography
                  color={theme.palette.primary.main}
                  variant="h4"
                  sx={{ fontSize: "34px" }}
                >
                  {formatNumberWithCommas(property?.price)} AED / Yearly
                </Typography>
              </Grid>
              <Grid item xs />
              <Grid item xs justifyContent={"end"} display={"flex"} gap={"6px"}>
                {property?.verified !== undefined && (
                  <IconWithBadge
                    icon={<Icons.VERIFIED />}
                    title={`${property?.verified} Verified`}
                    badgeStyle={{
                      display: "flex",
                      borderRadius: "60px",
                      background: "#EFEFEF",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "10px 15px",
                      alignItems: "center",
                    }}
                  />
                )}
                <IconWithBadge
                  title={t("property-details.featured")}
                  badgeStyle={{
                    padding: "10px 30px",
                    background: theme.palette.primary.blue,
                    color: "#fff !important",
                    fontSize: "11px",
                    borderRadius: "60px",
                    fontWeight: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  titleStyle={{
                    color: "#fff !important",
                  }}
                />
              </Grid>
            </Grid>
            <Box margin={"30px 0"}>
              <IconWithBadge
                icon={<Icons.LOCATION style={{ width: 20, height: 20 }} />}
                title={property?.neighbourhood}
                badgeStyle={{
                  margin: "8px 0px!important",
                  display: "flex",
                  fontSize: "14px",
                  alignItems: "center",
                  color: theme.palette.primary.main,
                }}
                type={"location"}
              />
              <Typography
                fontWeight={400}
                color={theme.palette.primary.main}
                variant="h4"
                sx={{
                  textTransform: "capitalize",
                  fontSize: isMobile ? "25px !important" : "35px !important",
                }}
              >
                {property?.title}
              </Typography>
            </Box>
            <Typography
              fontSize={16}
              color={theme.palette.primary.main}
              sx={{
                fontSize: "16px !important",
              }}
              dangerouslySetInnerHTML={{
                __html: `${t(
                  "property-details.property-type"
                )}: <span style="font-weight: 600">${
                  property?.__typename
                }</span> - ${t('property-details.available-by')}: <span style="font-weight: 600; font-size: '16px'">${formatDate(property?.ready_by)}</span>`,
              }}

              
            />
       
            <Typography
              display={"flex"}
              gap={"20px"}
              margin={"25px 0"}
              sx={{ cursor: "pointer", flexWrap: "wrap" }}
            >
              <Box>
                <IconWithBadge
                  className="icon-badge"
                  icon={<Icons.BEDROOM />}
                  title={`${property?.bedrooms} ${t('property-details.bedrooms')}`}
                  badgeStyle={contactBadge}
                />
           
              </Box>
              <Box>
                <IconWithBadge
                  icon={<Icons.BATHROOM />}
                  title={`${property?.bathrooms} ${t('property-details.bathrooms')}`}
                  badgeStyle={contactBadge}
                />
              </Box>
              <Box>
                <IconWithBadge
                  icon={<Icons.ASPECT_RATIO />}
                  title={`${formatNumberWithCommas(
                    Number(property?.size)
                  )} ${t('property-details.sqft')}`}
                  badgeStyle={contactBadge}
                />
              </Box>
              <Box>
                {property?.image !== undefined && (
                  <IconWithBadge
                    icon={<Icons.MAID_ROOM />}
                    title={`${property?.maidrooms}`}
                    badgeStyle={contactBadge}
                  />
                )}
              </Box>
            </Typography>
            <Box gap={"20px"} display={"grid"}>
              <Typography color={theme.palette.primary.main} fontWeight={600}>
                {t("property-details.amenitites")}
              </Typography>
              <Typography
                display={"flex"}
                gap={"20px"}
                margin={"0"}
                sx={{ cursor: "pointer", flexWrap: "wrap" }}
              >
                {property?.amenities?.length > 0 &&
                  property?.amenities?.map((item, index) => (
                    <Typography
                      key={index}
                      sx={{ background: theme.palette.secondary.main }}
                      borderRadius={"8px"}
                      border={"1px solid #EFEFEF"}
                      padding={"12px"}
                      justifyContent={"center"}
                      color={theme.palette.primary.main}
                    >
                      {item?.title}
                    </Typography>
                  ))}
              </Typography>
            </Box>
            <Box margin={"30px 0"} gap={"20px"} display={"grid"}>
              <Typography color={theme.palette.primary.main} fontWeight={600}>
                {t("property-details.description")}
              </Typography>
              <Typography color={theme.palette.primary.main}>
                {property?.description}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            style={
              isMobile
                ? {
                    display: "none",
                  }
                : {
                    height: "unset",
                    padding: "0 15px !important",
                    borderRadius: "8px !important",
                    position: "relative",
                  }
            }
          >
            <Box>
              {!isOwner && (
                <Button
                  onClick={handleClickOpen}
                  sx={{
                    background: theme.palette.primary.blue,
                    color: "#fff !important",
                    marginTop: "0px !important",
                    borderRadius: "8px !important",
                    display: "flex",
                    alignItems: "center",
                    flexDirection:
                      i18n?.language === "en" ? "row" : "row-reverse",
                    "&:hover": {
                      background: theme.palette.primary.blue,
                    },
                  }}
                  className="chat"
                >
                  <ChatBubbleOutlineIcon style={{ marginRight: "10px" }} />
                  {t("chat")}
                </Button>
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                  component: "form",
                }}
              >
                <DialogTitle>{t('property-details.send-message')}</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="email"
                    value={textMessage}
                    onChange={(e) => setTextMessage(e.target.value)}
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>{t('property-details.cancel')}</Button>
                  <Button onClick={handleSendMessage}>{t('property-details.send')}</Button>
                </DialogActions>
              </Dialog>
            </Box>
            <Box
              item
              xs={4}
              display="flex"
              borderRadius={"8px !important"}
              border={"1px solid #D9D9D9"}
              position={"relative"}
              style={
                isMobile
                  ? {
                      maxWidth: "100%",
                      padding: "0",
                      marginTop: "40px",
                    }
                  : {
                      height: "unset",
                      padding: "40px",
                      marginTop: "24px",
                    }
              }
            >
              <img
                src={AdsImg}
                alt="ads-Image"
                style={{ margin: "0 auto", maxWidth: "100%" }}
              />
            </Box>
            <Box
              item
              xs={4}
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"#F4F4F4"}
              borderRadius={"8px !important"}
              border={"1px solid #D9D9D9"}
              style={
                isMobile
                  ? {
                      maxWidth: "100%",
                      padding: "0",
                      marginTop: "40px",
                      height: "unset",
                    }
                  : {
                      height: "236px",

                      marginTop: "24px",
                    }
              }
            >
              <Typography sx={{ textAlign: "center" }}>Google ad</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} padding={"0px"}>
            <RelevantListings />
          </Grid>
        </Grid>

      </Container>

      
    </>
  );
};
export default PropertyDetails;
