import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { Box, Typography, useTheme } from "@mui/material";
import SectionTitle from "../../components/section-title/section-title.component";
import { GET_CATEGORIES } from "../../api/queries/categories.queries";
import * as ROUTES from "../../constants/routes";
import { Helmet } from "react-helmet";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import {
  Card,
  CardContent,
} from "@mui/material";
import Icons from '../../assets/icons'
import { makeStyles } from "@mui/styles";


export const useCategoryCardStyles = makeStyles((theme) => ({
  cardRoot: {
    width: "165px",
    height: "165px",
    maxWidth: "165px",
    transition: "box-shadow 0.3s",
    cursor: "pointer",
    borderRadius: "20px !important",
    boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.07) !important",
    color: theme.palette.primary.main,
    textAlign: "center",
    "&:active": {
     border: "4px solid #FE820E",
    },
  },
  disabledCard: {
    pointerEvents: "none",
    opacity: 0.5,
  
  },
  cardContent: {
    position: "relative",
    height: "100% !important",
    width: "100% !important",
  },
  spanStyle: {
    position: "relative",
    top: 10,
  },
  icon: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
    margin: "0px auto 15px",
    alignItems: "center",
  },
}));
export const CategoryCard = (props) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { data, classes, onClick  } = props;
  const theme = useTheme();
  const { t } = useTranslation("common");
  const cardBackgroundColor = data?.bgColor;
  return (
    <Card
      className={`${classes?.cardRoot}`}

      style={{ background: cardBackgroundColor}} 
      onClick={onClick}
    >
      <CardContent className={classes?.cardContent}>
        <Box
          height={"100%"}
          width={"100%"}
          textAlign={"center"}
          display={"grid"}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <Box className={classes?.icon}>
            {data?.image && data.image !== "undefined" ? (
              <img src={`${baseURL + data.image}`} alt={data?.title} />
            ) : (
              <Icons.NO_IMAGE />
            )}
          </Box>
          <Typography variant="h3" color={theme.palette.primary.main}>
            {data?.title}
          </Typography>
          {/* <span className={classes.spanStyle}>0 {t('categories.adds')}</span> */}
        </Box>
      </CardContent>
    </Card>
  );
};

const PostAd = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useCategoryCardStyles(theme);
  const {
    loading: isLoadingCategories,
    error: errorLoadingCategories,
    data: categoriesList,
  } = useQuery(GET_CATEGORIES, {
    variables: {
      showOnScreen: 1,
    },
  });
   
  useEffect(() => {
    if (errorLoadingCategories) {
      console.error("Error loading categories:", errorLoadingCategories);
    }
  }, [errorLoadingCategories]);


  useEffect(() => {}, [isLoadingCategories]);
  const onClickCategory = (data) => {
    let path;
    switch (data?.title) {
      case "Classified":
        path = ROUTES.CLASSIFIED;
        break;
      case "Rent Property":
        path = ROUTES.RENT_PROPERTY;
        break;
      case "Buy Property":
        path = ROUTES.BUY_PROPERTY;
        break;
      case "Vehicle":
        path = ROUTES.VEHICLE;
        break;
      case "Motors":
        path = ROUTES.RENT_PROPERTY;
        break;
      case "Travelling":
        path = ROUTES.TRAVELING;
        break;
      case "Electronics":
        path = ROUTES.ELECTRONICS;
        break;
      case "Home Appliances":
        path = ROUTES.HOME_APPLIANCE;
        break;
      case "Vendor Services":
        path = ROUTES.VENDOR_SERVICES;
        break;
      case "Mobile & Tablets":
        path = ROUTES.MOBILE_AND_TABLETS;
        break;
      case "Furniture":
        path = ROUTES.FURNITURE;
        break;
      case "Community":
        path = ROUTES.COMMUNITY;
        break;
      case "Jobs":
        path = ROUTES.JOBS;
        break;
      case "Property":
        path = ROUTES.ADS;
        break;
      default:
        path = ROUTES.HOME;

    }
 
    let id = data?.id;
    navigate(generatePath(`${path}`, { id }));

  };



  return (
    <>
      <Helmet>
        <title>{t("post-ad-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>

      <Box
        style={{
          gap: 20,
          display: "grid",
          margin: "35px 0",
          padding: 16,
        }}
      >
        <Box
          margin="auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("post-ad.place-ad")} />
          <Typography color={theme.palette.primary.main}>
            {t("post-ad.description")}
          </Typography>
        </Box>
        <Box
          margin={"0 auto"}
          display="flex"
          justifyContent="center"
          maxWidth={1200}
          sx={{ flexWrap: "wrap" }}
        >
          {categoriesList?.categories?.map((category, index) => (
              <div key={index} onClick={() => onClickCategory(category)}>
                <CategoryCard data={category}  classes={classes}/>
              </div>
            ))}
        </Box>
      </Box>
    </>
  );
};
export default PostAd;
