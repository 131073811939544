import { forwardRef } from "react";
import { MuiTelInput } from "mui-tel-input";
import { createStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
const useStyles = makeStyles((theme) =>
  createStyles({
    errorLabel: {
      color: theme.palette.primary.main,
    },
  })
);
const CustomTelInput = forwardRef((props, ref) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <MuiTelInput
      {...props}
      ref={ref}
      defaultCountry="AE"
      value={props?.value === undefined ? "" : props?.value}
      fullWidth={true}
      autoComplete="off"
      sx={{
        margin: "10px 0",
        borderRadius: "12px",
        padding: "5px 0",
        "& fieldset": {
          borderColor: props?.error
            ? theme.palette.error.main
            : theme.palette.primary.main,
          borderWidth: "1px",
        },
      }}
      required={props?.required}
      id={props?.id}
      style={props?.style}
      name={props?.name}
      label={
        <span style={{ color: theme.palette.primary.main }}>
          {props?.label}{" "}
          <span
            style={{
              color: props?.error
                ? theme.palette.error.main
                : theme.palette.primary.main,
            }}
          >
            *
          </span>
        </span>
      }
    />
  );
});
export default CustomTelInput;
