import Header from "../../components/Header/header.component";
import Footer from "../../components/Footer/footer.component";
import { useTranslation } from "react-i18next";
import Pricing from "./Pricing";
const PricingLayout = () => {
  const { i18n } = useTranslation();
  return (
    <>
      <Header />
      <div className={i18n.language === "en" ? "ltr-layout" : "rtl-layout"}>
        <main>
          <Pricing />
        </main>
        <Footer />
      </div>
    </>
  );
};
export default PricingLayout;
