import { useState } from "react";
import MultiStepFormContext from "../multi-step-form/MultiStepFormContext";
const MultiStepFormProvider = ({ children }) => {
    const [stepData, setStepData] = useState({});
    const [currentStep, setCurrentStep] = useState(0);
    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };
    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };
    const contextValue = {
        stepData,
        setStepData,
        next: handleNext,
        prev: handlePrev,
        currentStep
    };
    return (<MultiStepFormContext.Provider value={contextValue}>
            {children}
        </MultiStepFormContext.Provider>);
};
export default MultiStepFormProvider;
