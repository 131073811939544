import React, { useEffect } from 'react'; 
import { isIOS, isAndroid, isMobile } from 'react-device-detect';
const MobileRedirect = () => {
    useEffect(() => {
        if (isMobile) {
            const timer = setTimeout(() => {
                if (isIOS) {
                    window.location.href = "https://apps.apple.com/ae/app/kulushae/id6478753424";
                } else if (isAndroid) {
                    window.location.href = "https://play.google.com/store/apps/details?id=com.cashgate.kulushae&pcampaignid=web_share";
                }
            }, 3000); 
            return () => clearTimeout(timer);
        }
        return null;
    }, []);
    
    return null;
};
export default MobileRedirect;
