import React, { useState } from "react";
import {
  Slider,
  Box,
  TextField,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const YearPicker = ({ startYear, endYear, onChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [value, setValue] = useState([startYear, endYear]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const handleMinInputChange = (e) => {
    let newValue = parseInt(e.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > endYear) {
      newValue = endYear;
    }
    setValue([newValue, Math.max(newValue, value[1])]);
    onChange([newValue, Math.max(newValue, value[1])]);
  };

  const handleMaxInputChange = (e) => {
    let newValue = parseInt(e.target.value);
    if (newValue > 2024) {
      newValue = 2024;
    } else if (newValue < startYear) {
      newValue = startYear;
    }
    setValue([Math.min(newValue, value[0]), newValue]);
    onChange([Math.min(newValue, value[0]), newValue]);
  };

  function valueText(value) {
    return `${value}`;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        backgroundColor: "#fff",
        border: "1px solid #D1D1D1 ",
        borderRadius: "8px",
        padding: "20px",
        zIndex: "100",
        boxShadow: "0px 21px 34px rgba(0,0,0,15%)",
        marginTop: "-8px",
      }}
      width={isMobile ? "300px" : "400px"}
    >
      <Box sx={{ display: "flex", gap: "13px" }}>
        <TextField
          variant="outlined"
          value={value[0]}
          onChange={handleMinInputChange}
          sx={{
            "& .MuiInputBase-root": {
              height: 42,
              borderRadius: "8px",
            },
          }}
        />

        <Typography
          sx={{
            border: "1px solid #D1D1D1 ",
            padding: "20px",
            borderRadius: "8px",
            height: "38px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          -
        </Typography>

        <TextField
          variant="outlined"
          value={value[1]}
          onChange={handleMaxInputChange}
          sx={{
            "& .MuiInputBase-root": {
              height: 42,
              borderRadius: "8px",
            },
          }}
        />
      </Box>

      <Slider
        getAriaLabel={() => "Year range"}
        value={value}
        onChange={handleChange}
        getAriaValueText={valueText}
        min={1995}
        max={2024}
        disableSwap
        sx={{
          marginTop: "30px",
          "& .MuiSlider-thumb": {
            backgroundColor: "#fff !important",
            border: "2px solid #000",
            width: "25px",
            height: "25px",
          },
        }}
      />

      <Box display={"flex"} justifyContent={"end"}>
        <Button
          sx={{
            background: "#D1D1D1",
            width: "90px",
            height: "38px",
            marginTop: "30px",
          }}
        >
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default YearPicker;
