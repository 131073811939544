import gql from 'graphql-tag';

const GET_CHATS = gql`
query GetChats {
    getChats {
      id
      receiver_id
      sender_id
      category_id
      type
      item_id
      item {
        id
        title
        price
        image
      }
      receiver {
        id
        image
        first_name
        last_name
        email
        phone
        is_premium
        createdAt
      }
      sender {
        id
        image
        first_name
        last_name
        email
        phone
        is_premium
        createdAt
      }
      createdAt
    }
  }
`

const GET_MESSAGES = gql`
    query GetMessages($chatId: Int, $senderId: Int, $receiverId: Int, $categoryId: Int, $itemId: Int) {
        getMessages(chat_id: $chatId, sender_id: $senderId, receiver_id: $receiverId, category_id: $categoryId, item_id: $itemId) {
        chat_id
        createdAt
        id
        message
        receiver_id
        sender_id
        type
        }
    }
    `


const DELETE_ITEM_MUTATION = gql`
mutation Delete_item($ids: [DeleteItem]) {
  delete_item(ids: $ids)
}
`




export { GET_CHATS, GET_MESSAGES, DELETE_ITEM_MUTATION }