import React from "react";
import { Box } from "@mui/material";
import ControlledButton from "../Button/button.component";


function FormBox({
  onSubmit,
  initialValues,
  children,
  sx,
  submitValue,
  submitStyle,
  disableSubmit,
}) {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit && onSubmit(event, initialValues);
  };
  const handleButtonClick = (event) => {
    handleSubmit(event);
  };
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={sx}
    >
      {children}
      <ControlledButton
        value={submitValue}
        onClick={handleButtonClick}
        loadingButtonProps={{
          type: "submit",
          fullWidth: true,
          sx: { py: "0.8rem", mt: "1rem", borderRadius: "8px !important" },
        }}
        customStyle={submitStyle}
        disabled={disableSubmit}
      />
    </Box>
  );
}
export default FormBox;
