import React from 'react'
import Header from '../../../components/Header/header.component'
import Footer from '../../../components/Footer/footer.component'
import { Outlet } from 'react-router-dom'
import PropertyForRent from './index'
import { useTranslation } from 'react-i18next';

const MainLayout = () => {

    const { i18n } = useTranslation();
  return (
    <>
    <Header />
    <div className={i18n.language === 'en' ? 'ltr-layout' : 'rtl-layout'}>
        <main>
            <PropertyForRent />
        </main>
        <Footer />
    </div>
</>
  )
}

export default MainLayout
