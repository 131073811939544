import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import { useQuery } from '@apollo/client';
import { Stack, Box, Typography, Card, CardContent, useMediaQuery} from "@mui/material";
import IconWithBadge from "../../components/icon-with-badge/icon-with-badge.component";
import * as ROUTES from '../../constants/routes'
import SectionTitle from "../../components/section-title/section-title.component";
import Icons from "../../assets/icons";
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { GET_PROPERTIES_BY_CATEGORYID } from '../../api/queries/featured-ads.queries'
import { Helmet } from 'react-helmet';
import ResponsiveCarousel from '../../components/responsive-carousel/responsive-carousel.component'
import { formatNumberWithCommas } from '../../helpers/global';
import { Skeleton } from '@mui/material'


const useStyles = makeStyles(() => ({
    selectRoot: {
        width: '100%',
        height: 'min-content',
        borderRadius: '8px',
        fontSize: '14px',
        padding: 0,
        '& .MuiSelect-select': {
            padding: '9.5px 14px',
            fontSize: '14px'
        }
    },
    autocompleteRoot: {
        padding: 0,
        borderRadius: '8px',
        border: '1px solid #D9D9D9',
    },
    inputRoot: {
        padding: '0',
    },
    MuiInputBaseRoot: {
        borderRadius: '8px !important',
        borderColor: '#D9D9D9',
    },
    bedsBathsSelect: {
        width: '100%',
        height: 'min-content',
        borderRadius: '8px',
        fontSize: '14px',
        padding: 0,
    },
    bedsBathsMenuPaper: {
        width: '300px',
    }
}));






const AdCard = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { classes, data, verifiedRankStyles, featuredStyles, bedroomBathroomAspectStyles, statusStyles, isMobile, lang, loading } = props;
    const handleClickProperty = (id) => {
        navigate(generatePath(`${ROUTES.PROPERTY}`, { id }));


        console.log(loading, 'card loading')
    };

    return (


        <Card className={classes.cardRoot} sx={{ borderTopRightRadius: '5px!important', borderTopLeftRadius: '5px!important', cursor: 'pointer', boxShadow: "unset" }} onClick={() => handleClickProperty(data?.id.toString())}>
            <Box position={'relative'} width={'281px'} height={'183px'}>

                {data?.images?.length >= 1 &&
                    <ResponsiveCarousel images={data.images ? data?.images : <Icons.NO_IMAGE />} hideThumbs={true} />}
                {data?.images?.length === 0 && (

                    <Box display={'grid'} height={'100%'} borderRadius={'8px'} border={'3px solid #D9D9D9'}>
                        <Box alignSelf={'center'} justifySelf={'center'}>
                            <Icons.NO_IMAGE style={{ width: '50%' }} />
                            <Typography color={theme.palette.primary.main}>No Image Available</Typography>
                        </Box>
                    </Box>)}
            </Box>
            <CardContent sx={{ padding: isMobile ? '16px 0px' : '16px', display: 'grid' }} >
                <Stack direction={'row'} display={'flex'}>
                    {/* <IconWithBadge
                    icon={<Icons.VERIFIED className={isMobile ? classes.mobileMedia : ''} />}
                    title={data?.verified && 'Verified'}
                    badgeStyle={verifiedRankStyles}
                        />
                                {!isMobile && (
                            <IconWithBadge
                                icon={<Icons.RANK />}
                                title={data?.rank}
                                badgeStyle={verifiedRankStyles}
                            />
                        )}
                                <IconWithBadge
                            title={data?.featured && 'Featured'}
                            badgeStyle={featuredStyles}
                        /> */}
                </Stack>
                <Box padding={isMobile && '0 8px'} textAlign={'start'}>
                    <Box className={classes.headline} gap={'8px'} display={'grid'}>
                        {
                            loading? (

                                <Skeleton variant="" animation="wave" height={10}/>

                            )
                            :
                            (
                                <Typography variant="h3" color={theme.palette.primary.main}>{`${formatNumberWithCommas(data?.price)} AED / Yearly`}</Typography>
                            )
                        }
                        
                       
                        <Typography variant="h3" fontWeight={400} color={theme.palette.primary.main} sx={{
                  width: '250px',
                  overflow: 'hidden',
                  textOverflow:'ellipsis',
                  whiteSpace: 'noWrap'
                }}>{data?.title}</Typography>
                    </Box>
                    <IconWithBadge icon={<Icons.LOCATION style={{ width: 20, height: 20 }} />} title={data?.neighbourhood} badgeStyle={{
                        margin: '8px 0px!important',
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.palette.primary.main,
                    }} type={'location'} className={classes?.badges} />
                </Box>
                <Typography color={theme.palette.primary.main} display={'flex'} justifyContent={'space-between'} padding={isMobile ? '6px' : 0} margin={'0'} sx={{ cursor: 'pointer', flexWrap: 'wrap' }}>
                    <IconWithBadge icon={<Icons.BEDROOM style={{ width: 20, height: 20 }} />} title={data?.bedrooms} badgeStyle={bedroomBathroomAspectStyles} className={classes?.badges} />
                    <IconWithBadge icon={<Icons.BATHROOM style={{ width: 20, height: 20 }} />} title={data?.bathrooms} badgeStyle={bedroomBathroomAspectStyles} className={classes?.badges} />
                    <IconWithBadge icon={<Icons.ASPECT_RATIO style={{ width: 20, height: 20 }} />} title={formatNumberWithCommas(Number(data?.size))} badgeStyle={bedroomBathroomAspectStyles} className={classes?.badges} />
                </Typography>
            </CardContent>
        </Card>


    );
};


const SearchedAds = () => {

    const params = useParams();

    // getting category ID
    const { categoryId } = params;
    const categoryIdInt = parseInt(categoryId)
    const classes = useStyles();
    const { t, i18n } = useTranslation('common');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    let { loading: isLoadingProperties, error: errorLoadingProperties, data: propertiesList } = useQuery(GET_PROPERTIES_BY_CATEGORYID, {
        variables: {
            page: null,
            userId: null,
            categoryId: categoryIdInt,
        },
    });

    const verifiedRankStyles = {
        display: 'inline-flex',
        gap: '5px!important',
        borderRadius: 100,
        background: '#EFEFEF',
        alignItems: 'center',
        padding: '2px 7px',
        margin: isMobile ? '0 5px' : '0 5px 0 0'
    };
    const featuredStyles = {
        padding: '5px 9px',
        borderRadius: 100,
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main
    };
    const bedroomBathroomAspectStyles = {
        borderRadius: '4px!important',
        border: '1px solid #EFEFEF',
        padding: '4px 12px',
        margin: '8px 0px!important',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
    };
    const statusStyles = {
        padding: '5px 9px',
        position: 'absolute',
        top: '12%',
        width: 'max-content',
        left: 0,
        borderRadius: '0px 3px 3px 0px',
        background: theme.palette.secondary.main
    };
    const customButtonStyles = {
        background: '#fff',
        borderRadius: '40px',
        background: '#FFF',
        boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.10)',
        border: 'none',
        width: '205px',
        height: '52px'

    }

    return (
        <>

            <Helmet>
                <title>{t('search-title')}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no" />
            </Helmet>
            <Box padding="0 15px" margin="30px auto" gap={'5px !important'} display="grid" sx={{ placeItems: "center" }}>

                <SectionTitle variant={'h2'} title={t('search.title')} />
            </Box>



            <Box className={`properties-item`} style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? 'repeat(1, 0fr) !important' : 'repeat(4, 0fr)',
                gridGap: '0',
                placeContent: 'center',

            }}>


                {
                    propertiesList && propertiesList.properties && propertiesList.properties.data.map((ad, index) => (
                        <Box key={index} style={{ boxSizing: 'border-box', padding: '10px', margin: '0', width: 'max-content' }}>
                            <AdCard
                                classes={classes}
                                data={ad}
                                verifiedRankStyles={verifiedRankStyles}
                                featuredStyles={featuredStyles}
                                bedroomBathroomAspectStyles={bedroomBathroomAspectStyles}
                                statusStyles={statusStyles}
                                isMobile={isMobile}
                                lang={i18n.language}
                                loading={isLoadingProperties}
                            />
                        </Box>
                    ))
                }
            </Box>
        </>
    )
}

export default SearchedAds
