import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/section-title/section-title.component";
import { Helmet } from "react-helmet";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#D9D9D9",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: "#000",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
  "& .MuiSwitch-root": {
    marginRight: "8px",
  },
}));

const PriceCard = ({ title, price, features = [], duration }) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Box
        sx={{
          borderRadius: "20px",
          border: "1px solid #e5e5e5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "48px 0px 40px 0px",
          flexDirection: "column",
        }}
      >
        <Box sx={{ borderBottom: "1px solid #e5e5e5", width: "100%" }}>
          <Typography sx={{ fontSize: "35px !important", fontWeight: "500" }}>
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "35px !important",
              fontWeight: "500",
              marginBottom: "31px",
            }}
          >
            {price} <span style={{ fontSize: "15px" }}>{duration}</span>
          </Typography>
        </Box>

        <Stack>
          <Box>
            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "50px 0px 23px 0px",
              }}
            >
              {features.map((feature, index) => (
                <ListItem
                  key={index}
                  sx={{
                    justifyContent: "center",
                    fontSize: "18px",
                    marginBottom: "20px",
                  }}
                >
                  {feature}
                </ListItem>
              ))}
            </List>
          </Box>

          <Button
            sx={{
              width: "303px !important",
              backgroundColor: "#FE820E",
              borderRadius: "8px",
              height: "55px",
              fontSize: "16px",
              fontWeight: "600",
              color: "#fff",

              "&:hover": {
                backgroundColor: "#FE820E",
                color: "#fff",
              },
            }}
          >
            {t("pricing.btn-text")}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

const Pricing = () => {
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isAnnual, setisAnnual] = React.useState(false);
  const handleChange = () => {
    setisAnnual((previsAnnual) => !previsAnnual);
  };
  return (
    <>
      <Helmet>
        <title>{t("pricing.meta")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>

      <Container>
        <Box
          padding="0 15px"
          margin="30px auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("pricing.title")} />
          <Typography
            sx={{
              fontSize: "16px !important",
              color: "#000",
              marginTop: "15px",
            }}
          >
            {t("pricing.title-caption")}
          </Typography>
        </Box>

        <Box
          sx={{
            margin: "60px 0px 30px 0px",
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent={i18n?.language === "en" ? "end" : "start"}
          >
            <Typography color={isAnnual ? "#e5e5e5" : "#000"}>
              {t("pricing.monthly")}
            </Typography>
            <AntSwitch
              defaultChecked
              checked={isAnnual}
              onChange={handleChange}
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography color={isAnnual ? "#000" : "#e5e5e5"}>
              {t("pricing.anually")}
            </Typography>
          </Stack>
        </Box>

        <Grid container spacing={2} marginBottom={isMobile ? "90px" : "192px"}>
          {isAnnual ? (
            <>
              <Grid item xs={4}>
                <PriceCard
                  title="Basic"
                  price="250 AED"
                  duration="/year"
                  features={[
                    "5 ads daily",
                    "Fully Editing Control",
                    "No hidden charges",
                    "Cancel Anytime",
                  ]}
                />
              </Grid>

              <Grid item xs={4}>
                <PriceCard
                  title="Advance"
                  price="550 AED"
                  duration="/year"
                  features={[
                    "10 ads daily",
                    "Fully Editing Control",
                    "No hidden charges",
                    "Cancel Anytime",
                  ]}
                />
              </Grid>

              <Grid item xs={4}>
                <PriceCard
                  title="Premium"
                  price="700 AED"
                  duration="/year"
                  features={[
                    "50 ads daily",
                    "Fully Editing Control",
                    "No hidden charges",
                    "Cancel Anytime",
                  ]}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  align="right"
                  sx={{
                    marginTop: "38px",
                    fontSize: "14px !important",
                  }}
                >
                  {t("pricing.disclaimer")}
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={4}>
                <PriceCard
                  title={t("pricing.price-cards.basic-title")}
                  price={t("pricing.price-cards.basic-charges")}
                  duration={t("pricing.price-cards.basic-period")}
                  features={[
                    t("pricing.price-cards.basic-features.f1"),
                    t("pricing.price-cards.basic-features.f2"),
                    t("pricing.price-cards.basic-features.f3"),
                    t("pricing.price-cards.basic-features.f4"),
                  ]}
                />
              </Grid>

              <Grid item xs={4}>
                <PriceCard
                  title={t("pricing.advance.title")}
                  price={t("pricing.advance.charges")}
                  duration={t("pricing.advance.period")}
                  features={[
                    t("pricing.advance.features.f1"),
                    t("pricing.advance.features.f2"),
                    t("pricing.advance.features.f3"),
                    t("pricing.advance.features.f4"),
                  ]}
                />
              </Grid>

              <Grid item xs={4}>
                <PriceCard
                  title={t("pricing.premium.title")}
                  price={t("pricing.premium.charges")}
                  duration={t("pricing.premium.period")}
                  features={[
                    t("pricing.premium.features.f1"),
                    t("pricing.premium.features.f2"),
                    t("pricing.premium.features.f3"),
                    t("pricing.premium.features.f4"),
                  ]}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  align="right"
                  sx={{
                    marginTop: "38px",
                    fontSize: "14px !important",
                  }}
                >
                  {t("pricing.disclaimer")}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Pricing;
