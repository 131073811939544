import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ConversationList from '../../components/coversation/conversation-list/ConversationList';
import { useTranslation } from 'react-i18next';
import ChatDeatils from './chat-details/ChatDeatils';
import SectionTitle from '../../components/section-title/section-title.component';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IconButton, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { Helmet } from 'react-helmet';
import './chat.scss'
import Icons from '../../assets/icons'
import { DELETE_ITEM_MUTATION } from '../../api/queries/chat.queries';
import { useMutation } from '@apollo/client';
import { GET_CHATS } from '../../api/queries/chat.queries';
import { useQuery } from '@apollo/client';



const Chat = () => {




  const [selectedChat, setSelectedChat] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedTimeRange, setSelectedTimeRange] = useState(10)
  const [filteredChats, setFilteredChats] = useState([]);
  const [ deleteItem ] = useMutation(DELETE_ITEM_MUTATION)
  const { loading, error, data } = useQuery(GET_CHATS);

  const handleConversationItemClick = (chat) => {
    setSelectedChat(chat);
  };

  const handleTimeRangeChange = (event) => {
    setSelectedTimeRange(event.target.value);
  };

  const handleEditToggle = () => {
    setIsEditMode(!isEditMode);
  };

  // Delete Chat
  const handleDeleteChat = async (id) => {
    try {
      const variables = {
        ids: [
          {
            id: Number(id),
            type: "chat"
          }
        ]
      };

      console.log(variables, 'variables')
      const response = await deleteItem({variables});
  
      console.log('Deleted successfully:', response);
    } catch (error) {
      console.error('Error deleting item:', error.message);
    }
  };
  
  
  // sorting chats based on time 

  useEffect(() => {


    if(data && data.chats) {
      const allChats = data.chats;
      let filtered = [];
      const now = new Date();


      if(selectedTimeRange === 10) {
        filtered = allChats.filter(chat => {
          const today = new Date();
          return new Date(chat.timestamp).toDateString() === today.toDateString();
        })
      }
      else if (selectedTimeRange === 20) {
        filtered = allChats.filter(chat => {
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          return new Date(chat.timestamp).toDateString() === yesterday.toDateString();
        })
      }
      else if (selectedTimeRange === 30) {
        // Last week
        filtered = allChats.filter(chat => {
          const lastWeek = new Date();
          lastWeek.setDate(lastWeek.getDate() - 7);
          return new Date(chat.timestamp) > lastWeek;
        });
      } else if (selectedTimeRange === 40) {
        // Last month
        filtered = allChats.filter(chat => {
          const lastMonth = new Date();
          lastMonth.setMonth(lastMonth.getMonth() - 1);
          return new Date(chat.timestamp) > lastMonth;
        });
      }
      setFilteredChats(filtered);
    }
  }, [data, selectedTimeRange])


  const { t,i18n } = useTranslation('common');



  return (
    <>
          <Helmet>
            <title>{t('chat-page-title')}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"/>
        </Helmet>


      <Box maxWidth={'1320px'} margin={'0 auto'}>
        <Box sx={{ padding: '20px 30px', margin: '0 auto' }}>
          <Box margin="auto" gap={'5px !important'} display="grid" sx={{ placeItems: 'center' }}>
            <SectionTitle variant={'h2'} title={t('chat')} />
          </Box>

          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box display={'flex'} justifyContent={'space-between'} gap={'120px'}>
              <Box display={'flex'} alignItems={'center'} gap={'12px'}>
                <IconButton
                  sx={{
                    border: isEditMode?  '1px solid #FE820E' : '1px solid #000',
                    borderRadius: '12px',
                    width: '50px',
                  }}
                  variant="contained"
                  onClick={handleEditToggle}
                >
                  {!isEditMode && <EditIcon style={{ color: '#000' }} />}
                  {isEditMode && <Icons.DELETE_ICON  onClick={() => handleDeleteChat(selectedChat?.id)} style={{ color: '#000' }} />}
                </IconButton>

                {isEditMode && (
                  <Typography
                    sx={{
                      color: '#000',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    {t('chat-details.delete')}
                  </Typography>
                )}
              </Box>

              <Box>
                {isEditMode && (
                  <IconButton
                    sx={{
                      border: '1px solid #000',
                      borderRadius: '12px',
                      width: '50px',
                    }}
                    variant="contained"
                    onClick={handleEditToggle}
                  >
                    <ClearIcon style={{ color: '#000' }} />
                  </IconButton>
                )}
              </Box>
            </Box>

            <Box sx={{ minWidth: 100, color: '#000', fontWeight: '600' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t('chat-details.time')}</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Age"
                    value={selectedTimeRange}
                    onChange={handleTimeRangeChange}
                >
                  <MenuItem value={10}>{t('chat-details.today')}</MenuItem>
                  <MenuItem value={20}>{t('chat-details.yesterday')}</MenuItem>
                  <MenuItem value={30}>{t('chat-details.last-week')}</MenuItem>
                  <MenuItem value={40}>{t('chat-details.last-month')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box className="list-chat-box" sx={{
            display: 'flex',
            gap: '20px'
          }}>
            {/* Components */}
           
            <ConversationList isEditMode={isEditMode} onClick={handleConversationItemClick} handleDeleteChat={handleDeleteChat} chats={filteredChats}/>
            <ChatDeatils selectedChat={selectedChat} />

            
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Chat;
