import React from 'react'
import Header from "../../components/Header/header.component";
import Footer from "../../components/Footer/footer.component";
import { useTranslation } from 'react-i18next';
import Profile from './profile.component';



const ProfileLayout = () => {

    const { i18n } = useTranslation();
  return (<>
    <Header />
    <div className={i18n.language === 'en' ? 'ltr-layout' : 'rtl-layout'}>
        <main>
            <Profile />
        </main>
        <Footer />
    </div>
</>
  )
}

export default ProfileLayout
