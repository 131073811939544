import React, { useState } from 'react'
import { Grid, Box, Button, TextField } from '@mui/material'
import Icons from '../../assets/icons'
import InputAdornment from '@mui/material/InputAdornment';
import './profilemain.scss'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { UPDATE_USER_PROFILE_MUTATION } from '../../api/queries/auth.queries';
import { useMutation } from '@apollo/client';
import { useRef } from 'react';
import { UPLOAD_IMAGES } from '../../api/queries/form.queries'
import Toaster from '../../components/toaster/toaster.component';
import { useTranslation } from 'react-i18next';
import { useMutation as useReactQueryMutation } from 'react-query';


const appURL = process.env.REACT_APP_BASE_URL

const FieldStyles = {
  border: '1px solid #DBDBDB',
  borderRadius: '8px',
  marginBottom: '18px',
  color: '#000'
}
const ProfileForm = ({ handleCloseForm, updatedUserData }) => {
  const { t, i18n } = useTranslation("common");
  const storedUser = localStorage.getItem('userData')
  const user = JSON.parse(storedUser);
  const userId = parseInt(user.id);


  const fileInputRef = useRef(null);

  const [uploadedImage, setUploadedImage] = useState(null)

  const [isUploading, setIsuploading] = useState(false)

  const [openSuccess, setOpenSuccess] = useState(false);

  const handleChooseFile = () => {
    fileInputRef.current.click();
  };

  const handleCloseSuccess = () => { setOpenSuccess(false); };


  //   const [socialLinks, setSocialLinks] = useState([{ type: '', value: '' }]);

  // const handleChangeSocial = (index, field, value) => {
  //   const updatedLinks = [...socialLinks];
  //   updatedLinks[index][field] = value;
  //   setSocialLinks(updatedLinks);
  // };

  // const handleAddMore = () => {
  //   setSocialLinks([...socialLinks, { type: '', value: '' }]);
  // };




  const [userData, setUserData] = useState({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      image: user.image,

  });




  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({ ...prevUserData, [name]: value }));
  };


  // const [showPassword, setShowPassword] = React.useState(false);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };


  // handle image

  const uploadImagesFunc = useReactQueryMutation(UPLOAD_IMAGES);
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const data = new FormData();
      data.append('type', 'USER_PROFILE_URL');
      data.append('file', file);
      data.append('id', userId)

      try {

        const response = await uploadImagesFunc.mutateAsync(data);
        const file_name = response['file_name']

        console.log(file_name, 'file name')
        const image = `${file_name}`;

        console.log(image, 'image')
        setUserData(prevUserData => ({
          ...prevUserData,
          image: image
        }));

        

      } catch (error) {
        console.log(error.response?.data.error);
      }
    }
  };

  const [updateUser, { loading, error }] = useMutation(UPDATE_USER_PROFILE_MUTATION);

  const handleSave = async () => {
    try {
      const userId = parseInt(user.id);
      const { data } = await updateUser({
        variables: {
          values: {
            email: userData.email,
            first_name: userData.first_name,
            image: userData.image,
            last_name: userData.last_name,
            phone: userData.phone,
          
          },


          userId: userId,
        },



      });

      const updatedUserData = {
        ...userData,
        first_name: data.updateUser.first_name,
        last_name: data.updateUser.last_name,
        email: data.updateUser.email,
        phone: data.updateUser.phone,
        image: data.updateUser.image,
      };


      setUserData(updatedUserData);
      localStorage.setItem('userData', JSON.stringify(updatedUserData));

      console.log(updatedUserData, 'updatedData')

    
      setOpenSuccess(true);
    } catch (error) {
      // Handle error
      console.error('Error updating profile:', error);
    }
  };




  return (

    <>

      <Grid container justifyContent={'space-between'} sx={{ marginTop: '58px' }}>
        <Grid item lg={3} xs={12}>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img
              src={`${appURL}${userData.image}`}
              alt="user"
              style={{
                width: '235px',
                borderRadius: '50%',
                height: '235px',
                border: '1px solid #DBDBDB',
              }}
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
            <Button
              sx={{
                border: '1px solid #D9D9D9',
                borderRadius: '8px',
                padding: '12px 15px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginTop: '40px'
              }}
              onClick={handleChooseFile}
            >
              {t('profile.upload-avatar')}
            </Button>
            <Button
              sx={{ marginTop: '28px', textDecoration: 'underline' }}

            >
              {t('profile.delete-photo')}
            </Button>
          </Box>



        </Grid>



        <Grid lg={8} xs={12} sx={{ marginTop: '70px' }}>


          <form>


            <Grid container spacing={2}>

              <Grid item xs={6}>
                <TextField
                  name="first_name"
                  style={FieldStyles}
                  value={userData.first_name}
                  fullWidth
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icons.FORMUSER />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>


              <Grid item xs={6}>
                <TextField
                  name="last_name"
                  style={FieldStyles}
                  fullWidth
                  value={userData.last_name}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icons.FORMUSER />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="phone"
                  fullWidth
                  style={FieldStyles}
                  value={userData.phone}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icons.FORMPHONE />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>


              <Grid item xs={12}>
                <TextField
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  style={FieldStyles}
                  fullWidth
                  label={t('profile.email')}

                />
              </Grid>


              {/* <Grid item xs={12}>
                            <TextField 
                            name="address"
                            style={FieldStyles}
                               fullWidth 
                               onChange={handleChange}
                            placeholder='Address'
                             InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Icons.FORMUSER />
                                  </InputAdornment>
                                ),
                              }}
                            />
                        </Grid> */}


              {/* <Grid item xs={12}>
                            <TextField 
                            name="birthday"
                            style={FieldStyles}
                            onChange={handleChange}
                               fullWidth 
                            placeholder='Birthday'
                             InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Icons.FORMCAKE />
                                  </InputAdornment>
                                ),
                              }}

                          
                            />
                        </Grid> */}


              {/* <Grid item xs={12}>
                            <TextField 
                            name="about_me"
                             onChange={handleChange}
                            style={FieldStyles}
                            multiline
                            rows={6}
                               fullWidth 
                            label='About Me'
                       
                            />
                        </Grid> */}



              {/* <Grid item xs={12}>
                        <FormControl variant="outlined" style={{marginTop: '50px'}}>
                          <Typography sx={{textAlign: 'right', fontWeight: '600', fontize: '15px !important', marginBottom:'14px'}}>Edit</Typography>
                        <OutlinedInput
                        naem="passowrd"
                          sx={{ width: '100%',     border: '1px solid #DBDBDB',
                          borderRadius: '8px',
                          marginBottom: '18px',
                          
                          color: '#000' }}
                          id="outlined-adornment-password"
                       
                          type={showPassword ? 'text' : 'password'}
                          startAdornment={
                            <InputAdornment position="start">

                              <Icons.LOCK />
                            
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOffOutlinedIcon sx={{color: '#000'}}/> : <VisibilityOutlinedIcon sx={{color: '#000'}}/>}
                              </IconButton>
                            </InputAdornment>
                          }
                        />

                        </FormControl>
                        </Grid> */}
              {/* <Grid item xs={12}>
                          {socialLinks.map((link, index) => (
                          <Grid item xs={12} key={index}>
                            <TextField
                            naem="social_links"
                              style={FieldStyles}
                              placeholder='Social Links'
                              fullWidth
                              value={link.value}
                              onChange={(event) => handleChangeSocial(index, 'value', event.target.value)}
                            />                       
                          </Grid>
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                          <Button sx={{ fontSize: '15px !important', fontWeight: '600' }} onClick={handleAddMore}>
                            Add More
                          </Button>
                        </Box>
                          </Grid> */}






            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '35px', marginTop: '50px' }}>
              <Button sx={{ border: '1px solid #D9D9D9', borderRadius: '8px', padding: '12px 15px', display: 'flex', flexDirection: i18n?.language === "en"? "row" : "row-reverse", alignItems: 'center', gap: '10px' }} onClick={handleCloseForm}>{t('profile.cancel')} <ClearOutlinedIcon />  </Button>
              <Button sx={{
                background: '#FE820E', color: '#fff', borderRadius: '8px', padding: '12px 15px', display: 'flex',flexDirection: i18n?.language === "en"? "row" : "row-reverse", alignItems: 'center', gap: '10px', width: '110px',
                '&:hover': {
                  backgroundColor: '#FE820E'
                }

              }} onClick={handleSave}> {t('profile.save')} <CheckOutlinedIcon /> </Button>

            </Box>
          </form>

        </Grid>
      </Grid>
      <Toaster open={openSuccess} title={`${t('common.congratulations')}!`} description={t('profile.update.success')} handleClose={handleCloseSuccess} icon={<Icons.SUCCESS style={{ margin: '15px 0' }} />} />

    </>
  )
}

export default ProfileForm
