import React, { useEffect } from 'react'
import { useQuery as useApolloQuery } from '@apollo/client'
import { GET_MOTORS } from '../../api/queries/motors.queries'
import { Box, Stack, Typography, Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import Icons from '../../assets/icons'
import { generatePath, useNavigate } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import IconWithBadge from '../../components/icon-with-badge/icon-with-badge.component';
import ResponsiveCarousel from '../../components/responsive-carousel/responsive-carousel.component';
import { formatNumberWithCommas } from '../../helpers/global'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';



const useStyles = makeStyles((theme) => ({
    cardRoot: {
        width: 'unset',
        transition: "box-shadow 0.3s",
        cursor: 'pointer',
        borderRadius: "18px !important",
        background: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        textAlign: "center",
        height: 'unset',
        '& svg': {
            width: '100%',
            height: 'auto',
            maxWidth: '100%',
            objectFit: 'contain',
        }
    },
    headline: {
        fontStyle: 'normal',
        lineHeight: 'normal !important',
        fontWeight: 'bold !important',
        textAlign: 'start',
        marginTop: '12px!important',
        marginBottom: '0!important',
    },
    mobileMedia: { margin: 5 },
    badges: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400
    }
}));

const MotorCard = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { classes, data, verifiedRankStyles, featuredStyles, bedroomBathroomAspectStyles, statusStyles, isMobile, lang } = props;
    const handleMotorClick = (id) => {
        navigate(generatePath(`${ROUTES.MOTOR_DETAILS}`, {id}))
    }
    return (

        <Card className={classes.cardRoot} sx={{ borderTopRightRadius: '5px!important', borderTopLeftRadius: '5px!important', cursor: 'pointer', boxShadow: "unset" }} onClick={() => handleMotorClick(data?.id.toString())}>
            <Box position={'relative'} width={'281px'} height={'183px'}>

                {data?.images?.length >= 1 &&
                    <ResponsiveCarousel images={data.images ? data?.images : <Icons.NO_IMAGE />} hideThumbs={true} />}
                {data?.images?.length === 0 && (

                    <Box display={'grid'} height={'100%'} borderRadius={'8px'} border={'3px solid #D9D9D9'}>
                        <Box alignSelf={'center'} justifySelf={'center'}>
                            <Icons.NO_IMAGE style={{ width: '50%' }} />
                            <Typography color={theme.palette.primary.main}>No Image Available</Typography>
                        </Box>
                    </Box>)}
            </Box>
            <CardContent sx={{ padding: isMobile ? '16px 0px' : '16px', display: 'grid' }} >
                <Stack direction={'row'} display={'flex'}>
                    {/* <IconWithBadge
            icon={<Icons.VERIFIED className={isMobile ? classes.mobileMedia : ''} />}
            title={data?.verified && 'Verified'}
            badgeStyle={verifiedRankStyles}
                /> */}
                    {/* {!isMobile && (
                    <IconWithBadge
                        icon={<Icons.RANK />}
                        title={data?.rank}
                        badgeStyle={verifiedRankStyles}
                    />
                )} */}
                    {/* <IconWithBadge
                    title={data?.featured && 'Featured'}
                    badgeStyle={featuredStyles}
                /> */}
                </Stack>
                <Box padding={isMobile && '0 8px'} textAlign={'start'}>
                    <Box className={classes.headline} gap={'8px'} display={'grid'}>
                        <Typography variant="h3" color={theme.palette.primary.main}>{data?.price ? `${formatNumberWithCommas(data.price)} AED / Yearly` : 'Price not available'}</Typography>
                        <Typography variant="h3" fontWeight={400} color={theme.palette.primary.main}   sx={{
                  width: '250px',
                  overflow: 'hidden',
                  textOverflow:'ellipsis',
                  whiteSpace: 'noWrap'
                }}>{data?.title}</Typography>
                    </Box>
                    <IconWithBadge icon={<Icons.LOCATION style={{ width: 20, height: 20 }} />} title={data?.emirates} badgeStyle={{
                        margin: '8px 0px!important',
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.palette.primary.main,
                    }} type={'location'} className={classes?.badges} />
                </Box>

            </CardContent>
        </Card>

    );
};



const AllMotorsAds = () => {

    const { t, i18n } = useTranslation('common');
    const theme = useTheme();
    const classes = useStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { loading: isLoadingMotors, error: errorLoadingMotors, data:motorList } = useApolloQuery(GET_MOTORS, {
        variables: {
            "page": null,
            "userId": null,
            "categoryId": null,
            "filters": null
        },
    })


    useEffect(() => {
        console.log(isLoadingMotors, 'laoding')
    }, [isLoadingMotors])

    useEffect(() => {
        console.log(errorLoadingMotors, 'error')
    }, [errorLoadingMotors])

    const verifiedRankStyles = {
        display: 'inline-flex',
        gap: '5px!important',
        borderRadius: 100,
        background: '#EFEFEF',
        alignItems: 'center',
        padding: '2px 7px',
        margin: isMobile ? '0 5px' : '0 5px 0 0'
    };
    const featuredStyles = {
        padding: '5px 9px',
        borderRadius: 100,
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main
    };
    const bedroomBathroomAspectStyles = {
        borderRadius: '4px!important',
        border: '1px solid #EFEFEF',
        padding: '4px 12px',
        margin: '8px 0px!important',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
    };
    const statusStyles = {
        padding: '5px 9px',
        position: 'absolute',
        top: '12%',
        width: 'max-content',
        left: 0,
        borderRadius: '0px 3px 3px 0px',
        background: theme.palette.secondary.main
    };


    const customButtonStyles = {
        background: '#fff',
        borderRadius: '40px',
        background: '#FFF',
        boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.10)',
        border: 'none',
        width: '205px',
        height: '52px'

    }

    return (
        <>

            <Box className="ad-box-last" style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? 'repeat(1, 0fr)' : 'repeat(4, 0fr)',
                gridGap: '0',
                placeContent: 'center'
            }}>
                {motorList && motorList?.motors?.data?.map((ad, index) => (<Box key={index} style={{ boxSizing: 'border-box', padding: '10px', margin: '0', width: 'max-content' }}>
                    <MotorCard classes={classes} data={ad} verifiedRankStyles={verifiedRankStyles} featuredStyles={featuredStyles} bedroomBathroomAspectStyles={bedroomBathroomAspectStyles} statusStyles={statusStyles} isMobile={isMobile} lang={i18n.language} />
                </Box>))}
            </Box>

        </>
    )
}

export default AllMotorsAds
