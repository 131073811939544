import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import LoginLayout from "./pages/Authentication/Login/LoginLayout.jsx";
import LoginMethods from "./pages/Authentication/Login/LoginMethods";
import RegisterLayout from "./pages/Authentication/Register/RegisterLayout.jsx";
import ForgetPassword from "./pages/Authentication/ForgetPassword/ForgetPassword";
import OTPCode from "./pages/Authentication/OtpCode/OtpCode";
import PostAd from "./pages/PostAd/PostAd";
import CreateNewPasswordLayout from "./pages/Authentication/ForgetPassword/CreateNewPasswordLayout";
import RentProperty from "./pages/PostAd/RentProperty";
import PropertyForRent from "./pages/Properties/PropertyForRent";
import PropertyDetails from "./pages/Properties/PropertyDetails";
import * as ROUTES from "./constants/routes.js";
import ScrollToTop from "./helpers/scrollToTop/scroll-to-top.component";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { theme as customTheme } from "./constants/theme";
import { LoggedOutRoute, ProtectedRoute } from "./helpers/routes";
import Main from "./pages/chat/Main";
import MainLayout from "./pages/Properties/PropertyForRent/MainLayout";
import Chat from "./pages/chat/Chat";
import ProfileLayout from "./pages/profile/ProfileLayout";
import SearchedLayout from "./pages/searchedads/SearchedLayout";
import MotorDetails from "./pages/motors/MotorDetails.jsx";
import AllMotorsAds from "./pages/motors/AllMotorsAds.jsx";
import ContactUs from "./pages/Contactus/ContactUs.jsx";
import AboutUs from "./pages/AboutUs/AboutUs.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.jsx";
import Careers from "./pages/Careers/Careers.jsx";
import CareerDetails from "./pages/Careers/CareerDetails.jsx";
import CountrySelection from "./pages/PostAd/country-selection/CountrySelection.jsx";
import MotorSearchLayout from "./pages/motors/motors-search/MotorSearchLayout.jsx";
import TermsConditions from "./pages/termsConditions/TermsConditions.jsx";
import ForgetPasswordLayout from "./pages/Authentication/ForgetPassword/ForgetPasswordLayout.jsx";
import PricingLayout from "./pages/Pricing/PricingLayout.jsx";
import Favorites from "./pages/favorites/Favorites.jsx";
import MobileRedirect from "./includes/MobileRedirect.js";


const App = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop>
          <MobileRedirect />
          <Routes>
            <Route path={ROUTES.HOME} element={<Layout />}>
              <Route index element={<Home />} />
              <Route path={ROUTES.PROPERTY} element={<PropertyDetails />} />
              <Route path={ROUTES.MOTOR_DETAILS} element={<MotorDetails />} />
              <Route path={ROUTES.ALL_MOTORS_ADS} element={<AllMotorsAds />} />
              <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
              <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
              <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route path={ROUTES.CAREERS} element={<Careers />} />
              <Route path={ROUTES.TERMS_CONDITIONS} element={<TermsConditions />} />
              <Route path={ROUTES.CAREERS_DETAILS} element={<CareerDetails />}/>
              <Route path={ROUTES.FAVORITES} element={<ProtectedRoute outlet={<Favorites />} />}/>
              <Route path={ROUTES.TERMS_CONDITIONS} element={<TermsConditions />}/>
              <Route path={ROUTES.CAREERS_DETAILS} element={<CareerDetails />}/>
               <Route
              path={ROUTES.FAVORITES}
              element={<ProtectedRoute outlet={<Favorites />} />} />
            </Route>

            
            <Route path={ROUTES.LOGIN} element={<LoggedOutRoute outlet={<LoginLayout />} />}/>
            <Route path={ROUTES.LOGIN_METHODS}  element={<LoggedOutRoute outlet={<LoginLayout />} />} />
            <Route path={ROUTES.REGISTER} element={<LoggedOutRoute outlet={<RegisterLayout />} />}/>
            <Route path={ROUTES.FORGET_PASSWORD} element={<LoggedOutRoute outlet={<ForgetPasswordLayout />} />} />
            <Route path={ROUTES.OTP_CODE} element={<LoggedOutRoute outlet={<OTPCode />} />}  />
            <Route path={ROUTES.CREATE_NEW_PASSWORD} element={<LoggedOutRoute outlet={<CreateNewPasswordLayout />} />}/>
            <Route path={ROUTES.POST_AD} element={<ProtectedRoute outlet={<PostAd />} />}/>
            <Route path={ROUTES.SELECT_COUNTRY} element={<ProtectedRoute outlet={<CountrySelection />} />}/>
            <Route path={ROUTES.RENT_PROPERTY} element={<ProtectedRoute outlet={<RentProperty />} />}/>
            <Route path={ROUTES.MOTORS} element={<ProtectedRoute outlet={<RentProperty />} />}/>
            <Route path={ROUTES.PROPERTY_FOR_RENT} element={<MainLayout />} />
            <Route path={ROUTES.CHAT} element={<ProtectedRoute outlet={<Main />} />}/>
            <Route path={ROUTES.CHAT_BY_ID} element={<ProtectedRoute outlet={<Main />} />} />
            <Route path={ROUTES.CHAT_DETAILS} element={<ProtectedRoute outlet={<Chat />} />}/>  
            <Route path={ROUTES.PROFILE} element={<ProtectedRoute outlet={<ProfileLayout />} />}/>
            <Route path={ROUTES.SEARCHED_ADS} element={<SearchedLayout />} />
            <Route path={ROUTES.MOTOR_SEARCH} element={<MotorSearchLayout />} />
            <Route path={ROUTES.PRICING} element={<PricingLayout />} />
            <Route path="*" element={<Navigate replace to={ROUTES.HOME} />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default App;
