import React, { useEffect } from "react";
import {
  Box,
  useTheme,
  Card,
  CardContent,
  useMediaQuery,
  Typography,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Icons from "../../../assets/icons";
import { useTranslation } from "react-i18next";
import { GET_PROPERTIES } from "../../../api/queries/home.queries";
import { useQuery } from "@apollo/client";
import * as ROUTES from "../../../constants/routes";
import { generatePath, useNavigate } from "react-router-dom";
import ResponsiveCarousel from "../../../components/responsive-carousel/responsive-carousel.component";

import IconWithBadge from "../../../components/icon-with-badge/icon-with-badge.component";
import { formatNumberWithCommas } from "../../../helpers/global";
import IncodeId, { encodeId } from '../../../helpers/idEncryption'

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: "unset",
    transition: "box-shadow 0.3s",
    cursor: "pointer",
    borderRadius: "18px !important",
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    textAlign: "center",
    height: "unset",
    "& svg": {
      width: "100%",
      height: "auto",
      maxWidth: "100%",
      objectFit: "contain",
    },
  },
  headline: {
    fontStyle: "normal",
    lineHeight: "normal !important",
    fontWeight: "bold !important",
    textAlign: "start",
    marginTop: "12px!important",
    marginBottom: "0!important",
  },
  mobileMedia: { margin: 5 },
  badges: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
}));

const AdCard = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    classes,
    data,
    verifiedRankStyles,
    featuredStyles,
    bedroomBathroomAspectStyles,
    statusStyles,
    isMobile,
    lang,
  } = props;

  const handleClickProperty = (id) => {
    const encryptedId = encodeId(id)
    navigate(generatePath(`${ROUTES.PROPERTY}`, { id: encryptedId }));
  };

  return (
    <Card
      className={classes.cardRoot}
      sx={{
        borderTopRightRadius: "5px !important",
        borderTopLeftRadius: "5px !important",
        cursor: "pointer",
        boxShadow: "unset",
      }}
      onClick={() => handleClickProperty(data?.id.toString())}
    >
      <Box position={"relative"} width={"281px"} height={"183px"}>
        {data?.images?.length >= 1 && (
          <ResponsiveCarousel
            images={data.images ? data.images : <Icons.NO_IMAGE />}
            hideThumbs={true}
            OnImageClick={() => handleClickProperty(data?.id.toString())}
          />
        )}
        {data?.images?.length === 0 && (
          <Box
            display={"grid"}
            height={"100%"}
            borderRadius={"8px"}
            border={"3px solid #D9D9D9"}
          >
            <Box alignSelf={"center"} justifySelf={"center"}>
              <Icons.NO_IMAGE style={{ width: "50%" }} />
              <Typography color={theme.palette.primary.main}>
                NO Image Available
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      <CardContent
        sx={{ padding: isMobile ? "16px 0px" : "16px", display: "grid" }}
      >
        <Stack direction={"row"} display={"flex"}>
          {/* <IconWithBadge
            icon={<Icons.VERIFIED className={isMobile ? classes.mobileMedia : ''} />}
            title={data?.verified && 'Verified'}
            badgeStyle={verifiedRankStyles}
                /> */}
          {/* {!isMobile && (
                    <IconWithBadge
                        icon={<Icons.RANK />}
                        title={data?.rank}
                        badgeStyle={verifiedRankStyles}
                    />
                )} */}
          {/* <IconWithBadge
                    title={data?.featured && 'Featured'}
                    badgeStyle={featuredStyles}
                /> */}
        </Stack>
        <Box padding={isMobile && "0 8px"} textAlign={"start"}>
          <Box className={classes.headline} gap={"8px"} display={"grid"}>
            <Typography
              variant="h3"
              color={theme.palette.primary.main}
            >{`${formatNumberWithCommas(
              data?.price
            )} AED / Yearly`}</Typography>
            <Typography
              variant="h3"
              fontWeight={400}
              color={theme.palette.primary.main}
              sx={{
                width: '250px',
                overflow: 'hidden',
                textOverflow:'ellipsis',
                whiteSpace: 'noWrap'
              }}
            >
              {data?.title}
            </Typography>
          </Box>
          <IconWithBadge
            icon={<Icons.LOCATION style={{ width: 20, height: 20 }} />}
            title={data?.neighbourhood}
            badgeStyle={{
              margin: "8px 0px!important",
              display: "flex",
              alignItems: "center",
              color: theme.palette.primary.main,
            }}
            type={"location"}
            className={classes?.badges}
          />
        </Box>
        <Typography
          color={theme.palette.primary.main}
          display={"flex"}
          justifyContent={"space-between"}
          padding={isMobile ? "6px" : 0}
          margin={"0"}
          sx={{ cursor: "pointer", flexWrap: "wrap" }}
        >
          <IconWithBadge
            icon={<Icons.BEDROOM style={{ width: 20, height: 20 }} />}
            title={data?.bedrooms}
            badgeStyle={bedroomBathroomAspectStyles}
            className={classes?.badges}
          />
          <IconWithBadge
            icon={<Icons.BATHROOM style={{ width: 20, height: 20 }} />}
            title={data?.bathrooms}
            badgeStyle={bedroomBathroomAspectStyles}
            className={classes?.badges}
          />
          <IconWithBadge
            icon={<Icons.ASPECT_RATIO style={{ width: 20, height: 20 }} />}
            title={formatNumberWithCommas(Number(data?.size))}
            badgeStyle={bedroomBathroomAspectStyles}
            className={classes?.badges}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};

const RelevantListings = () => {
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const classes = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const {
    loading: isLoadingProperties,
    error: errorLoadingProperties,
    data: propertiesList,
  } = useQuery(GET_PROPERTIES, {
    variables: {
      page: null,
      userId: null,
    },
  });
  useEffect(() => {
    if (errorLoadingProperties) {
      console.error("Error loading properties:", errorLoadingProperties);
    }
  }, [errorLoadingProperties]);
  useEffect(() => {
    console.log("loading");
  }, [isLoadingProperties]);

  //const initialAdsToShow = Number(propertiesList?.properties?.per_page);

  const [adsToShow, setAdsToShow] = React.useState(8); // Initial number of ads to show
  const [loadMoreClicked, setLoadMoreClicked] = React.useState(false);

  const handleLoadMore = () => {
    // Toggle the state to indicate that the "Load More" button has been clicked
    setLoadMoreClicked(true);

    // Set the number of ads to show to the total number of ads
    setAdsToShow(propertiesList?.properties?.data?.length || 0);
  };

  const verifiedRankStyles = {
    display: "inline-flex",
    gap: "5px!important",
    borderRadius: 100,
    background: "#EFEFEF",
    alignItems: "center",
    padding: "2px 7px",
    margin: isMobile ? "0 5px" : "0 5px 0 0",
  };
  const featuredStyles = {
    padding: "5px 9px",
    borderRadius: 100,
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  };
  const bedroomBathroomAspectStyles = {
    borderRadius: "4px!important",
    border: "1px solid #EFEFEF",
    padding: "4px 12px",
    margin: "8px 0px!important",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  };
  const statusStyles = {
    padding: "5px 9px",
    position: "absolute",
    top: "12%",
    width: "max-content",
    left: 0,
    borderRadius: "0px 3px 3px 0px",
    background: theme.palette.secondary.main,
  };

  const customButtonStyles = {
    background: "#fff",
    borderRadius: "40px",
    background: "#FFF",
    boxShadow: "0px 4px 74px 0px rgba(0, 0, 0, 0.10)",
    border: "none",
    width: "205px",
    height: "52px",
  };
  const title = "Relevant Listings";

  return (
    <>
      <Stack
        width="100%"
        margin={isMobile ? "40px 0" : "30px auto !important"}
        position={"relative"}
      >
        <Box>
          <Typography
            sx={{
              textAlign: isMobile ? "center" : "left",
              fontSize: "16px",
              fontWeight: "600",
              color: "#000",
            }}
          >
            {title}
          </Typography>
        </Box>
      </Stack>

      <Box
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "repeat(1, 0fr)" : "repeat(4, 0fr)",
          gridGap: "0",
          placeContent: isMobile ? "center" : "center",
        }}
      >
        {propertiesList &&
          propertiesList?.properties?.data?.slice(0, 4).map((ad, index) => (
            <Box
              key={index}
              style={{
                boxSizing: "border-box",
                padding: "10px",
                margin: "0",
                width: "max-content",
              }}
            >
              <AdCard
                classes={classes}
                data={ad}
                verifiedRankStyles={verifiedRankStyles}
                featuredStyles={featuredStyles}
                bedroomBathroomAspectStyles={bedroomBathroomAspectStyles}
                statusStyles={statusStyles}
                isMobile={isMobile}
                lang={i18n.language}
              />
            </Box>
          ))}
      </Box>
    </>
  );
};

export default RelevantListings;
