import { useTheme } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name, optionSelected, theme, itemStyle) {
    return {
        fontWeight: optionSelected.indexOf(name) === -1
            ? theme?.palette?.typography?.fontWeightRegular
            : theme?.palette?.typography?.fontWeightMedium,
        ...itemStyle
    };
}
const MultipleSelect = (props) => {
    const theme = useTheme();
    const { data, option, setOption, label, name } = props;
    const handleChange = (event) => {
        const { target: { value }, } = event;
        // Ensure value is always an array
        const selectedValues = Array.isArray(value) ? value : [value];
        // Use type assertion here
        selectedValues.map((selectedValue) => {
            // Your mapping logic here
        });
        setOption(selectedValues); // Now always passing an array
    };
    return (<FormControl sx={{ m: 1, width: '100%', margin: '0px' }}>
            <InputLabel id="demo-multiple-name-label" style={{ color: theme.palette.primary.main }}>{label}</InputLabel>
            <Select labelId="demo-multiple-name-label" id="demo-multiple-name" multiple value={option} onChange={handleChange} input={<OutlinedInput label="Name"/>} MenuProps={MenuProps} name={name} renderValue={(selectedIds) => (<div>
                        {Array.isArray(selectedIds) ?
                selectedIds.map((id) => {
                    const selectedLabel = data?.find(item => item.id === id)?.title || '';
                    return (<Chip key={id} label={selectedLabel} style={{
                            color: theme.palette.secondary.main,
                            background: theme.palette.primary.main,
                            marginRight: 4,
                        }}/>);
                })
                : null}
                    </div>)}>
                {data?.map((item, index) => (<MenuItem key={index} value={item.id} style={getStyles(item.title, option, theme, props.itemStyle)}>
                        {item.title}
                    </MenuItem>))}
            </Select>
        </FormControl>);
};
export default MultipleSelect;
