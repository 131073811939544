import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    countryId : null
};


const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountryId: (state, action) => {
            state.countryId = action.payload
        }
    }
});


export const { setCountryId } = countrySlice.actions;
export default countrySlice.reducer