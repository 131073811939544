import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Icons from "../../../assets/icons";
import {
  CustomTab,
  CustomTabPanel,
} from "../../../components/tabs/tabs.component";
import { Link, useLocation } from "react-router-dom";
import EmailRegister from "../../../pages/Authentication/Register/EmailRegister";
import PhoneNumberRegister from "../../../pages/Authentication/Register/PhoneNumberRegister";
import * as ROUTES from "../../../constants/routes";
import "../../../pages/Authentication/authentication.scss";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet";
import SectionTitle from "../../../components/section-title/section-title.component";
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
const Register = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const currentLocationState = location?.state?.method;
  const [tabValue, setTabValue] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (
      currentLocationState !== undefined &&
      currentLocationState === "email"
    ) {
      setTabValue(0);
    } else if (
      currentLocationState !== undefined &&
      currentLocationState === "phone-number"
    ) {
      setTabValue(1);
    }
  }, [currentLocationState]);

  const btnStyle = {
    background: theme.palette.secondary.main,
    border: "1px solid #EEE",
    color: theme.palette.primary.main,
    margin: "8px 0px",
    boxShadow: "unset",
    textTransform: "capitalize",
    alignItems: "center",
    width: "-webkit-fill-available",
    justifyContent: "center",
    gap: 20,
    fontSize: "1.1667rem",
    fontWeight: "400",
  };
  return (
    <>
      <Helmet>
        <title>{t("signup-login-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>

      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "40px 0px 46px 0px",
          }}
        >
          <SectionTitle
            variant={"h2"}
            title={t("authentication.register.create_account")}
          />
        </Box>
        <Box>
          {/* <BackTo
          path={ROUTES.LOGIN_METHODS}
          title={t("authentication.register.create_account")}
          func="back"
        /> */}

          <Stack
            className="autharea"
            sx={{
              overflow: "hidden",
              display: "flex",
              justifyItems: "center",
              width: isMobile ? "100%" : "508px",
              border: "1px solid #cccccc",
              borderRadius: "20px",
              margin: isMobile ? "0px auto 80px auto" : "0px auto 240px auto",
            }}
          >
            <CustomTab
              tabValue={tabValue}
              setTabValue={setTabValue}
              classes={classes}
              style={{
                border: "none",
              }}
            >
              <CustomTabPanel
                label={
                  <Box
                    color={theme.palette.primary.main}
                    fontSize={14}
                    gap={"5px!important"}
                    display={"flex"}
                    sx={{ placeItems: "center" }}
                  >
                    <Icons.EMAIL_OPEN />
                    {t("authentication.register.social.email")}
                  </Box>
                }
              >
                <EmailRegister />
              </CustomTabPanel>
              <CustomTabPanel
                label={
                  <Box
                    color={theme.palette.primary.main}
                    fontSize={14}
                    gap={"5px!important"}
                    display={"flex"}
                    sx={{ placeItems: "center" }}
                  >
                    <Icons.PHONE_NUMBER />
                    {t("authentication.register.social.phone_number")}
                  </Box>
                }
              >
                <PhoneNumberRegister />
              </CustomTabPanel>
            </CustomTab>
            <Box
              sx={{
                marginTop: "40px !important",
                marginLeft: "auto",
                marginRight: "auto",
                gap: "30px!important",
                display: "grid",
                justifyItems: "center",
              }}
            >
              <Typography
                color="#86878B !important"
                textAlign="center"
                fontStyle="normal"
              >
                {t("authentication.register.have_account")}
                <Link
                  style={{
                    fontWeight: "700",
                    textDecoration: "unset",
                    marginLeft: 5,
                    color: theme.palette.primary.main,
                  }}
                  to={ROUTES.LOGIN_METHODS}
                >
                  {t("authentication.register.sign_in")}
                </Link>
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Container>
    </>
  );
};
export default Register;
