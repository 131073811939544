import React from 'react'
import Header from "../../components/Header/header.component";
import Footer from "../../components/Footer/footer.component";
import { useTranslation } from 'react-i18next';
import SearchedAds from './SearchedAds';


const SearchedLayout = () => {
    const { i18n } = useTranslation();
  return (
    <>
    <Header />
    <div className={i18n.language === 'en' ? 'ltr-layout' : 'rtl-layout'}>
        <main>

         <SearchedAds />
            
        </main>
        <Footer />
    </div>
</>
  )
}

export default SearchedLayout
