import React, { useState } from "react";
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Icons from "../../../assets/icons";
import { styled } from "@mui/material";
import { FaCheck } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const apiURL = process.env.REACT_APP_BASE_URL;



const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  width: 40,
  height: 40,
  color: '#FFFFFF',
  backgroundColor: '#000000',
  borderRadius: '10px',
  '&.Mui-checked': {
    color: '#FFFFFF',
  },
  '&:hover': {
    backgroundColor: '#333333',
  },
}));

const ConversationItem = ({ chat, isEditMode, onClick, handleDeleteChat }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation("common")
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isActive, setIsActive] = useState(false);
  const handleSelectedChat = () => {
    setIsActive(!isActive);
    onClick(chat);
  };

  return (
    <>
      <Box
        className={`item ${isActive ? "active" : ""}`}
        display={"flex"}
        justifyContent={ i18n?.language === "en"? "left" : "right" }
        alignItems={"center"}
        gap={`${isMobile ? "10px" : "20px"}`}
        marginTop={"25px"}
        sx={{ cursor: "pointer" }}
        position={"relative"}
        onClick={handleSelectedChat}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          {isEditMode && (
            <Box position="absolute" left={0} top={0}>
             <CustomCheckbox
                icon={<span />}
                checkedIcon={<span style={{marginTop: "6px"}} ><FaCheck /></span>}
              
              />

            </Box>
          )}

          {chat.item && chat.item.image && chat.item.image.includes("null") ? (

         

            <Icons.NEW_LOGO
              style={{
                width: `${isMobile ? "70px" : "128px"}`,
                height: `${isMobile ? "70px" : "128px"}`,
                borderRadius: "25px",
              }}
            />
          ) : (
            <img
              src={`${apiURL}${chat.item && chat.item.image}`}
              alt="ad"
              style={{
                width: `${isMobile ? "70px" : "128px"}`,
                height: `${isMobile ? "70px" : "128px"}`,
                borderRadius: "25px",
              }}
            />
          )}

          {chat?.receiver && chat?.receiver.image ? (
            <img
              src={`${apiURL}${chat?.receiver.image}`}
              alt="ad"
              style={{
                borderRadius: "50%",
                position: "absolute",
                right: "-9px",
                bottom: "-3px",
                width: `${isMobile ? "30px" : "50px"}`,
                height: `${isMobile ? "30px" : "50px"}`,
                border: "3px solid #fff",
              }}
            />
          ) : (
          
              <Icons.NEW_LOGO 
              style={{
                position: "absolute",
                right: "-9px",
                bottom: "-3px",
                width: `${isMobile ? "30px" : "50px"}`,
                height: `${isMobile ? "30px" : "50px"}`,
                border:'1px solid #e5e5e5',
                color: "#fff",
                background: "#fff",
                borderRadius:"50px",
                filter:'grayscale(100%)'
              }}
              />
              
          )}
        </Box>
        <Box></Box>
        <Box>
          <Typography
            noWrap
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "15rem",
              fontSize: `${isMobile ? "1rem" : "20px"}`,
              fontWeight: "500",
              textTransform: "capitalize",
              overflow: "hidden !important",
              whiteSpace: "nowrap !important",
              textOverflow: "ellipsis !important",
              width: "210px",
            }}
          >
            {chat.item && chat.item.title}
          </Typography>
          {/* <Typography sx={{
            fontSize: '14px !important',
            color: '#666666',
            margin: '10px 0px'
          }}>{chat.receiver.first_name} {chat.receiver.last_name}</Typography> */}

          {/* <Typography sx={{
            fontSize: '15px !important',
            color: '#666666'
          }}>1:20 pm</Typography> */}
        </Box>
      </Box>
    </>
  );
};

export default ConversationItem;
