import React, { useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SectionTitle from "../../components/section-title/section-title.component";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import "./about-us.scss";
import { fontSize } from "@mui/system";

const typographyStyles = {
  fontSize: "14px",
  marginTop: "20px",
};

const AboutUs = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleChange = (_, newIndex) => setActiveIndex(newIndex);

  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Helmet>
        <title>{t("about-us.title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>
      <Container>
        <Box
          margin="70px auto 0px auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("about-us.banner-title")} />
        </Box>

        <Box className="about-tabs" style={{ display: "flex" }}>
          <VerticalTabs value={activeIndex} onChange={handleChange}>
            <MyTab label="What is Kulushae?" />
            <MyTab label="Cars" />
            <MyTab label="Kulushae cars" />
            <MyTab label="Classifieds" />
            <MyTab label="Kulushae Property" />
            <MyTab label="Kulushae Jobs" />
          </VerticalTabs>

          {activeIndex === 0 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                What is Kulushae?
              </Typography>
              <Typography style={typographyStyles}>
                Curabitur finibus vitae mauris nec consectetur. Class aptent
                taciti sociosqu ad litora torquent per conubia nostra, per
                inceptos himenaeos. Mauris ac ligula at massa aliquet pharetra.
                Duis vitae consectetur elit. Cras urna lacus, pretium ut nibh
                et, porttitor tincidunt eros. Suspendisse eget leo mauris.
                Aenean viverra ligula vitae dapibus lacinia. Aliquam arcu
                turpis, mollis ac consectetur sed, ultricies ac nunc. Sed vitae
                blandit ante, sit amet mattis lorem. In cursus mattis nisl, id
                euismod velit ultricies in. Vestibulum eu dictum enim. Sed
                hendrerit luctus est sit amet fermentum. Etiam dictum urna sed
                dapibus vehicula. Praesent eget dapibus est, eget scelerisque
                odio. Donec nec purus sed ex luctus mollis sit amet ut justo.
                Nam tristique dictum enim, ut sodales arcu laoreet at.
              </Typography>

              <Typography style={typographyStyles}>
                Ut fermentum aliquet posuere. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Integer auctor nunc eget felis euismod eleifend.
                Integer consectetur et magna a consequat. Interdum et malesuada
                fames ac ante ipsum primis in faucibus. Curabitur dictum ut nisl
                id pretium. Etiam tempor fringilla ante id aliquam. Proin tempor
                nisi eget posuere interdum.
              </Typography>

              <Typography style={typographyStyles}>
                Maecenas vel bibendum massa. Mauris mattis consequat tellus, in
                semper tortor dictum ut. Sed blandit dolor in sapien sodales
                pulvinar. Cras ut sollicitudin augue. Nunc sodales porta orci,
                at venenatis lorem pellentesque quis. Sed volutpat sodales
                tortor, vitae bibendum nunc aliquam vitae. Morbi varius, erat
                non ornare lobortis, lorem felis ullamcorper purus, eu
                ullamcorper arcu velit sed quam. Pellentesque metus turpis,
                iaculis condimentum ipsum ut, malesuada tincidunt ipsum. Nulla
                consequat ac eros at molestie. Nulla sed rhoncus ipsum.
              </Typography>
            </TabContainer>
          )}
          {activeIndex === 1 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Cars
              </Typography>
              <Typography style={typographyStyles}>
                Curabitur finibus vitae mauris nec consectetur. Class aptent
                taciti sociosqu ad litora torquent per conubia nostra, per
                inceptos himenaeos. Mauris ac ligula at massa aliquet pharetra.
                Duis vitae consectetur elit. Cras urna lacus, pretium ut nibh
                et, porttitor tincidunt eros. Suspendisse eget leo mauris.
                Aenean viverra ligula vitae dapibus lacinia. Aliquam arcu
                turpis, mollis ac consectetur sed, ultricies ac nunc. Sed vitae
                blandit ante, sit amet mattis lorem. In cursus mattis nisl, id
                euismod velit ultricies in. Vestibulum eu dictum enim. Sed
                hendrerit luctus est sit amet fermentum. Etiam dictum urna sed
                dapibus vehicula. Praesent eget dapibus est, eget scelerisque
                odio. Donec nec purus sed ex luctus mollis sit amet ut justo.
                Nam tristique dictum enim, ut sodales arcu laoreet at.
              </Typography>
              <Typography style={typographyStyles}>
                Ut fermentum aliquet posuere. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Integer auctor nunc eget felis euismod eleifend.
                Integer consectetur et magna a consequat. Interdum et malesuada
                fames ac ante ipsum primis in faucibus. Curabitur dictum ut nisl
                id pretium. Etiam tempor fringilla ante id aliquam. Proin tempor
                nisi eget posuere interdum.
              </Typography>
              <Typography style={typographyStyles}>
                Maecenas vel bibendum massa. Mauris mattis consequat tellus, in
                semper tortor dictum ut. Sed blandit dolor in sapien sodales
                pulvinar. Cras ut sollicitudin augue. Nunc sodales porta orci,
                at venenatis lorem pellentesque quis. Sed volutpat sodales
                tortor, vitae bibendum nunc aliquam vitae. Morbi varius, erat
                non ornare lobortis, lorem felis ullamcorper purus, eu
                ullamcorper arcu velit sed quam. Pellentesque metus turpis,
                iaculis condimentum ipsum ut, malesuada tincidunt ipsum. Nulla
                consequat ac eros at molestie. Nulla sed rhoncus ipsum.
              </Typography>
            </TabContainer>
          )}
          {activeIndex === 2 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Kulushae Cars
              </Typography>
              <Typography style={typographyStyles}>
                Curabitur finibus vitae mauris nec consectetur. Class aptent
                taciti sociosqu ad litora torquent per conubia nostra, per
                inceptos himenaeos. Mauris ac ligula at massa aliquet pharetra.
                Duis vitae consectetur elit. Cras urna lacus, pretium ut nibh
                et, porttitor tincidunt eros. Suspendisse eget leo mauris.
                Aenean viverra ligula vitae dapibus lacinia. Aliquam arcu
                turpis, mollis ac consectetur sed, ultricies ac nunc. Sed vitae
                blandit ante, sit amet mattis lorem. In cursus mattis nisl, id
                euismod velit ultricies in. Vestibulum eu dictum enim. Sed
                hendrerit luctus est sit amet fermentum. Etiam dictum urna sed
                dapibus vehicula. Praesent eget dapibus est, eget scelerisque
                odio. Donec nec purus sed ex luctus mollis sit amet ut justo.
                Nam tristique dictum enim, ut sodales arcu laoreet at.
              </Typography>
              <Typography style={typographyStyles}>
                Ut fermentum aliquet posuere. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Integer auctor nunc eget felis euismod eleifend.
                Integer consectetur et magna a consequat. Interdum et malesuada
                fames ac ante ipsum primis in faucibus. Curabitur dictum ut nisl
                id pretium. Etiam tempor fringilla ante id aliquam. Proin tempor
                nisi eget posuere interdum.
              </Typography>
              <Typography style={typographyStyles}>
                Maecenas vel bibendum massa. Mauris mattis consequat tellus, in
                semper tortor dictum ut. Sed blandit dolor in sapien sodales
                pulvinar. Cras ut sollicitudin augue. Nunc sodales porta orci,
                at venenatis lorem pellentesque quis. Sed volutpat sodales
                tortor, vitae bibendum nunc aliquam vitae. Morbi varius, erat
                non ornare lobortis, lorem felis ullamcorper purus, eu
                ullamcorper arcu velit sed quam. Pellentesque metus turpis,
                iaculis condimentum ipsum ut, malesuada tincidunt ipsum. Nulla
                consequat ac eros at molestie. Nulla sed rhoncus ipsum.
              </Typography>
            </TabContainer>
          )}
          {activeIndex === 3 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Classified
              </Typography>
              <Typography style={typographyStyles}>
                Curabitur finibus vitae mauris nec consectetur. Class aptent
                taciti sociosqu ad litora torquent per conubia nostra, per
                inceptos himenaeos. Mauris ac ligula at massa aliquet pharetra.
                Duis vitae consectetur elit. Cras urna lacus, pretium ut nibh
                et, porttitor tincidunt eros. Suspendisse eget leo mauris.
                Aenean viverra ligula vitae dapibus lacinia. Aliquam arcu
                turpis, mollis ac consectetur sed, ultricies ac nunc. Sed vitae
                blandit ante, sit amet mattis lorem. In cursus mattis nisl, id
                euismod velit ultricies in. Vestibulum eu dictum enim. Sed
                hendrerit luctus est sit amet fermentum. Etiam dictum urna sed
                dapibus vehicula. Praesent eget dapibus est, eget scelerisque
                odio. Donec nec purus sed ex luctus mollis sit amet ut justo.
                Nam tristique dictum enim, ut sodales arcu laoreet at.
              </Typography>
              <Typography style={typographyStyles}>
                Ut fermentum aliquet posuere. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Integer auctor nunc eget felis euismod eleifend.
                Integer consectetur et magna a consequat. Interdum et malesuada
                fames ac ante ipsum primis in faucibus. Curabitur dictum ut nisl
                id pretium. Etiam tempor fringilla ante id aliquam. Proin tempor
                nisi eget posuere interdum.
              </Typography>
              <Typography style={typographyStyles}>
                Maecenas vel bibendum massa. Mauris mattis consequat tellus, in
                semper tortor dictum ut. Sed blandit dolor in sapien sodales
                pulvinar. Cras ut sollicitudin augue. Nunc sodales porta orci,
                at venenatis lorem pellentesque quis. Sed volutpat sodales
                tortor, vitae bibendum nunc aliquam vitae. Morbi varius, erat
                non ornare lobortis, lorem felis ullamcorper purus, eu
                ullamcorper arcu velit sed quam. Pellentesque metus turpis,
                iaculis condimentum ipsum ut, malesuada tincidunt ipsum. Nulla
                consequat ac eros at molestie. Nulla sed rhoncus ipsum.
              </Typography>
            </TabContainer>
          )}
          {activeIndex === 4 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Kulushae Property
              </Typography>
              <Typography style={typographyStyles}>
                Curabitur finibus vitae mauris nec consectetur. Class aptent
                taciti sociosqu ad litora torquent per conubia nostra, per
                inceptos himenaeos. Mauris ac ligula at massa aliquet pharetra.
                Duis vitae consectetur elit. Cras urna lacus, pretium ut nibh
                et, porttitor tincidunt eros. Suspendisse eget leo mauris.
                Aenean viverra ligula vitae dapibus lacinia. Aliquam arcu
                turpis, mollis ac consectetur sed, ultricies ac nunc. Sed vitae
                blandit ante, sit amet mattis lorem. In cursus mattis nisl, id
                euismod velit ultricies in. Vestibulum eu dictum enim. Sed
                hendrerit luctus est sit amet fermentum. Etiam dictum urna sed
                dapibus vehicula. Praesent eget dapibus est, eget scelerisque
                odio. Donec nec purus sed ex luctus mollis sit amet ut justo.
                Nam tristique dictum enim, ut sodales arcu laoreet at.
              </Typography>
              <Typography style={typographyStyles}>
                Ut fermentum aliquet posuere. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Integer auctor nunc eget felis euismod eleifend.
                Integer consectetur et magna a consequat. Interdum et malesuada
                fames ac ante ipsum primis in faucibus. Curabitur dictum ut nisl
                id pretium. Etiam tempor fringilla ante id aliquam. Proin tempor
                nisi eget posuere interdum.
              </Typography>
              <Typography style={typographyStyles}>
                Maecenas vel bibendum massa. Mauris mattis consequat tellus, in
                semper tortor dictum ut. Sed blandit dolor in sapien sodales
                pulvinar. Cras ut sollicitudin augue. Nunc sodales porta orci,
                at venenatis lorem pellentesque quis. Sed volutpat sodales
                tortor, vitae bibendum nunc aliquam vitae. Morbi varius, erat
                non ornare lobortis, lorem felis ullamcorper purus, eu
                ullamcorper arcu velit sed quam. Pellentesque metus turpis,
                iaculis condimentum ipsum ut, malesuada tincidunt ipsum. Nulla
                consequat ac eros at molestie. Nulla sed rhoncus ipsum.
              </Typography>
            </TabContainer>
          )}
          {activeIndex === 5 && (
            <TabContainer>
              <Typography
                sx={{ fontSize: "15px", color: "#000", fontWeight: "700" }}
              >
                Kulushae Jobs
              </Typography>
              <Typography style={typographyStyles}>
                Curabitur finibus vitae mauris nec consectetur. Class aptent
                taciti sociosqu ad litora torquent per conubia nostra, per
                inceptos himenaeos. Mauris ac ligula at massa aliquet pharetra.
                Duis vitae consectetur elit. Cras urna lacus, pretium ut nibh
                et, porttitor tincidunt eros. Suspendisse eget leo mauris.
                Aenean viverra ligula vitae dapibus lacinia. Aliquam arcu
                turpis, mollis ac consectetur sed, ultricies ac nunc. Sed vitae
                blandit ante, sit amet mattis lorem. In cursus mattis nisl, id
                euismod velit ultricies in. Vestibulum eu dictum enim. Sed
                hendrerit luctus est sit amet fermentum. Etiam dictum urna sed
                dapibus vehicula. Praesent eget dapibus est, eget scelerisque
                odio. Donec nec purus sed ex luctus mollis sit amet ut justo.
                Nam tristique dictum enim, ut sodales arcu laoreet at.
              </Typography>
              <Typography style={typographyStyles}>
                Ut fermentum aliquet posuere. Pellentesque habitant morbi
                tristique senectus et netus et malesuada fames ac turpis
                egestas. Integer auctor nunc eget felis euismod eleifend.
                Integer consectetur et magna a consequat. Interdum et malesuada
                fames ac ante ipsum primis in faucibus. Curabitur dictum ut nisl
                id pretium. Etiam tempor fringilla ante id aliquam. Proin tempor
                nisi eget posuere interdum.
              </Typography>
              <Typography style={typographyStyles}>
                Maecenas vel bibendum massa. Mauris mattis consequat tellus, in
                semper tortor dictum ut. Sed blandit dolor in sapien sodales
                pulvinar. Cras ut sollicitudin augue. Nunc sodales porta orci,
                at venenatis lorem pellentesque quis. Sed volutpat sodales
                tortor, vitae bibendum nunc aliquam vitae. Morbi varius, erat
                non ornare lobortis, lorem felis ullamcorper purus, eu
                ullamcorper arcu velit sed quam. Pellentesque metus turpis,
                iaculis condimentum ipsum ut, malesuada tincidunt ipsum. Nulla
                consequat ac eros at molestie. Nulla sed rhoncus ipsum.
              </Typography>
            </TabContainer>
          )}
        </Box>
      </Container>
    </>
  );
};

const VerticalTabs = withStyles((theme) => ({
  flexContainer: {
    flexDirection: "column",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const MyTab = withStyles((theme) => ({
  selected: {
    color: "#000",
    borderBottom: "2px solid #000000",
  },
}))(Tab);

const TabContainer = ({ children }) => {
  return (
    <Typography
      component="div"
      style={{ padding: "12px 24px", width: "fit-content" }}
    >
      {children}
    </Typography>
  );
};

export default AboutUs;
