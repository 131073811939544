import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useQuery, NetworkStatus, useLazyQuery } from "@apollo/client";
import {
  Stack,
  Box,
  Autocomplete,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Menu,
  Button,
  Typography,
  IconButton,
  FormControl,
  Card,
  CardContent,
  useMediaQuery,
  Paper,
  InputBase,
} from "@mui/material";
import IconWithBadge from "../../../components/icon-with-badge/icon-with-badge.component";
import * as ROUTES from "../../../constants/routes";
import ControlledButton from "../../../components/Button/button.component";
import SectionTitle from "../../../components/section-title/section-title.component";
import Icons from "../../../assets/icons";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ResponsiveCarousel from "../../../components/responsive-carousel/responsive-carousel.component";
import { formatNumberWithCommas } from "../../../helpers/global";
import { useTheme } from "@mui/material";
import "./PropertyForRent.scss";
import { GET_PROPERTIES } from "../../../api/queries/home.queries";
import { GET_PROPERTIES_BY_CATEGORYID } from "../../../api/queries/featured-ads.queries";
import PriceSlider from "../../../components/slider/slider.component";
import ControlledMap from "../../../components/geo-map/map.component";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import { CustomTab } from "../../../components/tabs/tabs.component";
import CustomPagination from "../../../components/pagination/pagiantion.component";
import AreaSlider from "../../../components/area-slider/AreaSlider";
import { renderToStringWithData } from "@apollo/client/react/ssr";
import Alert from "@mui/material/Alert";
import { Helmet } from "react-helmet";
import Skeleton from "@mui/material/Skeleton";
import { Container } from "@mui/system";
import Images from "../../../assets/images";
const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const useStyles = makeStyles(() => ({
  selectRoot: {
    width: "100%",
    height: "min-content",
    borderRadius: "8px",
    fontSize: "14px",
    padding: 0,
    "& .MuiSelect-select": {
      padding: "9.5px 14px",
      fontSize: "14px",
    },
  },
  autocompleteRoot: {
    padding: 0,
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
  },
  inputRoot: {
    padding: "0",
  },
  MuiInputBaseRoot: {
    borderRadius: "8px !important",
    borderColor: "#D9D9D9",
  },
  bedsBathsSelect: {
    width: "100%",
    height: "min-content",
    borderRadius: "8px",
    fontSize: "14px",
    padding: 0,
  },
  bedsBathsMenuPaper: {
    width: "300px",
  },
}));

const AdCard = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    classes,
    data,
    verifiedRankStyles,
    featuredStyles,
    bedroomBathroomAspectStyles,
    statusStyles,
    isMobile,
    lang,
  } = props;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      // simulate loading delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };
    loadData();
  }, []);
  const handleClickProperty = (id) => {
    navigate(generatePath(`${ROUTES.PROPERTY}`, { id }));
  };

  return (
    <Card
      className="ad-card"
      sx={{
        borderTopRightRadius: "5px!important",
        borderTopLeftRadius: "5px!important",
        cursor: "pointer",
        boxShadow: "unset",
      }}
      onClick={() => handleClickProperty(data?.id.toString())}
    >
      {loading ? (
        <Skeleton
          variant="rect"
          width={281}
          height={183}
          style={{ borderRadius: "5px" }}
          animation="wave"
        />
      ) : (
        <Box
          className="card-carousel"
          position={"relative"}
          width={"281px"}
          height={"183px"}
        >
          {data?.images?.length >= 1 && (
            <ResponsiveCarousel
              images={data.images ? data?.images : <Icons.NO_IMAGE />}
              hideThumbs={true}
            />
          )}
          {data?.images?.length === 0 && (
            <Box
              display={"grid"}
              height={"100%"}
              borderRadius={"8px"}
              border={"3px solid #D9D9D9"}
            >
              <Box alignSelf={"center"} justifySelf={"center"}>
                <Icons.NO_IMAGE style={{ width: "50%" }} />
                <Typography color={theme.palette.primary.main}>
                  No Image Available
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}

      <CardContent
        sx={{ padding: isMobile ? "16px 0px" : "16px", display: "grid" }}
      >
        <Stack direction={"row"} display={"flex"}>
          {/* <IconWithBadge
                    icon={<Icons.VERIFIED className={isMobile ? classes.mobileMedia : ''} />}
                    title={data?.verified && 'Verified'}
                    badgeStyle={verifiedRankStyles}
                        />
                                {!isMobile && (
                            <IconWithBadge
                                icon={<Icons.RANK />}
                                title={data?.rank}
                                badgeStyle={verifiedRankStyles}
                            />
                        )}
                                <IconWithBadge
                            title={data?.featured && 'Featured'}
                            badgeStyle={featuredStyles}
                        /> */}
        </Stack>
        <Box padding={isMobile && "0 8px"} textAlign={"start"}>
          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <Box className={classes.headline} gap={"8px"} display={"grid"}>
              <Typography
                variant="h3"
                color={theme.palette.primary.main}
              >{`${formatNumberWithCommas(
                data?.price
              )} AED / Yearly`}</Typography>
              <Typography
                variant="h3"
                fontWeight={400}
                color={theme.palette.primary.main}
                sx={{
                  width: '250px',
                  overflow: 'hidden',
                  textOverflow:'ellipsis',
                  whiteSpace: 'noWrap'
                }}
              >
                {data?.title}
              </Typography>
            </Box>
          )}

          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <IconWithBadge
              icon={<Icons.LOCATION style={{ width: 20, height: 20 }} />}
              title={data?.neighbourhood}
              badgeStyle={{
                margin: "8px 0px!important",
                display: "flex",
                alignItems: "center",
                color: theme.palette.primary.main,
              }}
              type={"location"}
              className={classes?.badges}
            />
          )}
        </Box>

        {loading ? (
          <Skeleton animation="wave" />
        ) : (
          <Typography
            color={theme.palette.primary.main}
            display={"flex"}
            justifyContent={"space-between"}
            padding={isMobile ? "6px" : 0}
            margin={"0"}
            sx={{ cursor: "pointer", flexWrap: "wrap" }}
          >
            <IconWithBadge
              icon={<Icons.BEDROOM style={{ width: 20, height: 20 }} />}
              title={data?.bedrooms}
              badgeStyle={bedroomBathroomAspectStyles}
              className={classes?.badges}
            />
            <IconWithBadge
              icon={<Icons.BATHROOM style={{ width: 20, height: 20 }} />}
              title={data?.bathrooms}
              badgeStyle={bedroomBathroomAspectStyles}
              className={classes?.badges}
            />
            <IconWithBadge
              icon={<Icons.ASPECT_RATIO style={{ width: 20, height: 20 }} />}
              title={formatNumberWithCommas(Number(data?.size))}
              badgeStyle={bedroomBathroomAspectStyles}
              className={classes?.badges}
            />
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const PropertyForRent = () => {
  const params = useParams();

  // getting category ID
  const { categoryId } = params;
  const categoryIdInt = parseInt(categoryId);

  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [isGridView, setIsGridView] = useState(true);

  const handleGridViewClick = () => {
    setIsGridView(true);
  };

  const handleListViewClick = () => {
    setIsGridView(false);
  };

  const [places, setPlaces] = useState([]);
  const [value, setValue] = useState("");

  const getPlaces = async () => {
    const promise = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${mapboxAccessToken}`
    );
    const data = await promise.json();
    setPlaces(data.features);
  };

  useEffect(() => {
    getPlaces();
  }, [value]);

  const [minPrice, setMinPrice] = useState(7000);
  const [maxPrice, setMaxPrice] = useState(10000000);

  const handlePriceChange = (newValues) => {
    const [minPrice, maxPrice] = newValues;
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_price: minPrice,
      end_price: maxPrice,
    }));
  };

  const [minAreaValue, setminAreaValue] = useState(100);
  const [maxAreaValue, setmaxAreaValue] = useState(10000);

  const handleAreaChange = (newValues) => {
    const [minAreaValue, maxAreaValue] = newValues;
    setMinPrice(minAreaValue);
    setMaxPrice(maxAreaValue);
    setFilters((prevFilters) => ({
      ...prevFilters,
      area_start: minAreaValue,
      area_end: maxAreaValue,
    }));
  };

  const [tabValue, setTabValue] = useState(0);

  // filters

  const [ispriceOpen, setpriceOpen] = useState(false);

  const OpenpriceMenu = () => {
    setpriceOpen(!ispriceOpen);
  };

  const verifiedRankStyles = {
    display: "inline-flex",
    gap: "5px!important",
    borderRadius: 100,
    background: "#EFEFEF",
    alignItems: "center",
    padding: "2px 7px",
    margin: isMobile ? "0 5px" : "0 5px 0 0",
  };
  const featuredStyles = {
    padding: "5px 9px",
    borderRadius: 100,
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  };
  const bedroomBathroomAspectStyles = {
    borderRadius: "4px!important",
    border: "1px solid #EFEFEF",
    padding: "4px 12px",
    margin: "8px 0px!important",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  };
  const statusStyles = {
    padding: "5px 9px",
    position: "absolute",
    top: "12%",
    width: "max-content",
    left: 0,
    borderRadius: "0px 3px 3px 0px",
    background: theme.palette.secondary.main,
  };
  const customButtonStyles = {
    background: "#fff",
    borderRadius: "40px",
    background: "#FFF",
    boxShadow: "0px 4px 74px 0px rgba(0, 0, 0, 0.10)",
    border: "none",
    width: "205px",
    height: "52px",
  };

  const filterTypes = [
    {
      name: t('for-rent.rent'),
      function: () => console.log("filter rent"),
    },
    {
      name: t('for-rent.buy'),
      function: () => console.log("filter rent"),
    },
    {
      name: t('for-rent.sell'),                                                                       
      function: () => console.log("filter rent"),
    },
  ];

  const [openSecelect, setOpenSeclect] = React.useState(false);

  const handleClickOpen = () => {
    setOpenSeclect(true);
  };
  const handleSelectClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenSeclect(false);
    }
  };

  const [activeType, setActiveType] = useState(filterTypes[0].name);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [propertyType, setPropertType] = useState([]);

  const [showFilterMessage, setShowFilterMessage] = useState(false);

  const handleButtonClick = (name) => {
    setActiveType(name);
    // You can also call the respective function here if needed
    const filter = filterTypes.find((type) => type.name === name);
    if (filter) {
      filter.function();
    }
  };

  const handlePlaceChange = (event, value) => {
    setSelectedPlaces(value);
    const neighbourhoodValue =
      value.length === 1
        ? String(value[0].place_name)
        : value.map((place) => String(place?.place_name));
    setFilters((prevFilters) => ({
      ...prevFilters,
      neighbourhood: neighbourhoodValue,
    }));
  };

  const propertyTypes = [
    "Apartment",
    "Villa",
    "Villa Compound ",
    "Residential Plot",
    "Hotel Apartment",
    "Residential Building",
    "Residential Floor",
  ];
  const onTypeChange = (value) => {
    setPropertType(value);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [filterOpen, setFilterOpen] = useState(null);

  const filterOpennow = Boolean(filterOpen);

  const handleFilterBtnClick = (e) => {
    setFilterOpen(e.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterOpen(null);
  };

  const [priceOpen, setPriceOpen] = useState(null);

  const priceOpennow = Boolean(filterOpen);

  const handlePriceBtnClick = (e) => {
    setPriceOpen(e.currentTarget);
  };

  const handlePriceClose = () => {
    setPriceOpen(null);
  };

  const [selectedBed, setSelectedBed] = useState(3);
  const [selectedBath, setSelectedBath] = useState(5);

  const handleBedClick = (bed) => {
    setSelectedBed(bed);
    setFilters((prevFilters) => ({
      ...prevFilters,
      bedrooms: bed,
    }));
  };
  const handleBathClick = (bath) => {
    setSelectedBath(bath);
    setFilters((prevFilters) => ({
      ...prevFilters,
      bathrooms: bath,
    }));
  };
  const bedsAndBaths = [
    {
      id: 1,
      name: "Beds",
      value: ["Studio", selectedBed],
      selected: selectedBed,
      handleClick: handleBedClick,
    },
    {
      id: 2,
      name: "Baths",
      value: [selectedBath],
      selected: selectedBath,
      handleClick: handleBathClick,
    },
  ];

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const amenities = [
    {
      id: 1,
      title: "Covered Parking",
    },
    {
      id: 2,
      title: "Spa",
    },

    {
      id: 3,
      title: "Gym",
    },
    {
      id: 4,
      title: "View of Water",
    },

    {
      id: 5,
      title: "Landmark View",
    },

    {
      id: 6,
      title: "Conference Room",
    },

    {
      id: 7,
      title: "Available Networked",
    },

    {
      id: 8,
      title: "Dining in Building",
    },

    {
      id: 9,
      title: "Retail in Building",
    },
  ];

  const [selectedAmenities, setselectedAmenities] = useState([]);

  const handleAmenityClick = (amenity) => {
    const isAlreadySelected = selectedAmenities.some(
      (selected) => selected.id === amenity.id
    );
    if (isAlreadySelected) {
      const updatedAmenities = selectedAmenities.filter(
        (selected) => selected.id !== amenity.id
      );
      setselectedAmenities(updatedAmenities);
    } else {
      setselectedAmenities([...selectedAmenities, amenity]);
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      amenities: selectedAmenities,
    }));
  };

  const rentOptions = [
    {
      id: 1,
      title: "Yearly",
    },

    {
      id: 2,
      title: "Monthly",
    },

    {
      id: 3,
      title: "Quarterly",
    },

    {
      id: 4,
      title: "Bi-Yearly",
    },
  ];

  const [selectedRent, setSelecteRent] = useState([]);

  const handleRentClick = (rent) => {
    const isAlreadySelected = selectedRent.some(
      (selected) => selected.id === rent.id
    );

    let updatedRentOptions;
    if (isAlreadySelected) {
      updatedRentOptions = selectedRent.filter(
        (selected) => selected.id !== rent.id
      );
    } else {
      updatedRentOptions = [...selectedListedOption, rent];
    }
    const selectedRentValue = updatedRentOptions.map((option) =>
      String(option.title)
    );

    setSelecteRent(updatedRentOptions);

    setFilters((prevFilters) => ({
      ...prevFilters,
      rent: selectedRentValue.join(", "),
    }));
  };

  const furnishingStatus = [
    {
      id: 1,
      title: "All",
    },

    {
      id: 2,
      title: "Furnished",
    },

    {
      id: 3,
      title: "Unfurnished",
    },
  ];

  const [selectedFurnished, setselectedFurnished] = useState([]);

  const handleFurnishedClick = (fur) => {
    let updatedFur;
    if (fur.title === "All") {
      updatedFur = [];
    } else {
      const isAlreadySelected = selectedFurnished.some(
        (selected) => selected.id === fur.id
      );
      if (isAlreadySelected) {
        updatedFur = selectedFurnished.filter(
          (selected) => selected.id !== fur.id
        );
      } else {
        updatedFur = [...selectedFurnished, fur];
      }
    }
    let furnishedValue;
    if (updatedFur.length === 0) {
      furnishedValue = false;
    } else if (updatedFur.some((fur) => fur.title === "Furnished")) {
      furnishedValue = true;
    } else {
      furnishedValue = false;
    }

    setselectedFurnished(updatedFur);

    setFilters((prevFilters) => ({
      ...prevFilters,
      furnished: furnishedValue,
    }));
  };

  const ListedOptions = [
    {
      id: 1,
      title: "Agent",
    },

    {
      id: 2,
      title: "Landlord",
    },

    {
      id: 3,
      title: "Developer",
    },
  ];

  const [selectedListedOption, setSelectedListedOption] = useState([]);

  const handleListedClick = (list) => {
    const isAlreadySelected = selectedListedOption.some(
      (selected) => selected.id === list.id
    );

    let updatedListed;
    if (isAlreadySelected) {
      updatedListed = selectedListedOption.filter(
        (selected) => selected.id !== list.id
      );
    } else {
      updatedListed = [...selectedListedOption, list];
    }

    // Convert the selected options to strings
    const postedByValues = updatedListed.map((option) => String(option.title));

    setSelectedListedOption(updatedListed);

    setFilters((prevFilters) => ({
      ...prevFilters,
      posted_by: postedByValues.join(", "),
    }));
  };

  // checking search is preformed or not

  const [searchPerformed, setSearchPerformed] = useState(false);

  // pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(12);
  const [filters, setFilters] = useState({
    amenities: null,
    neighbourhood: null,
    bedrooms: null,
    furnished: null,
    // is_ads_with_video: null,
    // is_with_360: null,
    area_start: null,
    bathrooms: null,
    // category_id: null,
    posted_by: null,
    area_end: null,
    start_price: null,
    end_price: null,
    rent: null,
  });

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  let {
    loading: isLoadingProperties,
    error: errorLoadingProperties,
    data: propertiesList,
  } = useQuery(GET_PROPERTIES_BY_CATEGORYID, {
    variables: {
      page: currentPage,
      userId: null,
      categoryId: categoryIdInt,
    },
  });

  let {
    loading: searchPropertiesLoading,
    error: errorLoadingSearch,
    data: searchedProperties,
    refetch,
  } = useQuery(GET_PROPERTIES, {
    fetchPolicy: "cache-and-network",
    variables: {
      page: null,
      userId: null,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleSearch = () => {
    const filtersSelected = Object.values(filters).some(
      (filter) => filter !== null && filter !== ""
    );
    if (filtersSelected) {
      refetch({
        page: currentPage,
        filters: filters,
      });
      setFiltersApplied(true);
      setSearchPerformed(true);
    } else {
      setShowFilterMessage(true);
    }
  };

  const resetFilters = () => {
    setFilters({
      amenities: null,
      neighbourhood: null,
      bedrooms: null,
      furnished: null,
      area_start: null,
      bathrooms: null,
      posted_by: null,
      area_end: null,
      start_price: null,
      end_price: null,
      rent: null,
    });
  };

  const handleResetClick = () => {
    setSelecteRent([]);
    setSelectedListedOption([]);
    setselectedFurnished([]);
    setselectedAmenities([]);

    resetFilters();
  };

  useEffect(() => {
    if (searchPropertiesLoading) {
      console.log("loading ", searchPropertiesLoading);
    }
  }, [searchPropertiesLoading]);

  useEffect(() => {
    if (errorLoadingSearch) {
      console.log("error ", errorLoadingSearch);
    }
  }, [errorLoadingSearch]);

  // Check if data is empty after refetching
  const handleSearchButtonClick = () => {
    handleSearch();
  };

  useEffect(() => {
    console.log("loading", isLoadingProperties);
  }, [isLoadingProperties]);

  const [adsToShow, setAdsToShow] = React.useState(8);

  // paginaitlon

  const totalProperties = propertiesList?.properties?.total || 0;
  const totalPages = Math.ceil(totalProperties / propertiesPerPage);

  // Pagination Logic
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = Math.min(
    (currentPage - 1) * propertiesPerPage,
    totalProperties - propertiesPerPage
  );
  const currentProperties =
    propertiesList?.properties?.data.slice(
      indexOfFirstProperty,
      indexOfLastProperty
    ) || [];

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // for map search

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  const resetSearchValue = () => {
    console.log("resetting ");
    setSearchValue("");
  };

  // sorting the properties

  const [sortBy, setSortBy] = useState("latest");

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const sortProperties = () => {
    if (
      sortBy === "latest" &&
      propertiesList &&
      propertiesList.properties &&
      propertiesList.properties.data
    ) {
      const sortedData = [...propertiesList.properties.data];
      sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
      propertiesList = {
        ...propertiesList,
        properties: {
          ...propertiesList.properties,
          data: sortedData,
        },
      };
    } else if (
      sortBy === "default" &&
      propertiesList &&
      propertiesList.properties &&
      propertiesList.properties.data
    ) {
      const sortedData = [...propertiesList.properties.data];
      sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
      propertiesList = {
        ...searchedProperties,
        properties: {
          ...propertiesList.properties,
          data: sortedData,
        },
      };
    }
  };

  React.useEffect(() => {
    sortProperties();
  }, [sortBy]);

  const tabContents = [
    <ul className="type-list">
      {" "}
      {propertyTypes.map((name) => (
        <MenuItem key={name} value={name}>
          {name} <Checkbox />
        </MenuItem>
      ))}{" "}
    </ul>,

    <ul className="type-list">
      {" "}
      {propertyTypes.map((name) => (
        <MenuItem key={name} value={name}>
          {name} <Checkbox />
        </MenuItem>
      ))}{" "}
    </ul>,
  ];
  return (
    <>
      <Helmet>
        <title>{t("property-detail-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>
      <Container sx={{ minHeight: "100vh" }} >

        <Box>
          <img src={Images.PROPERTYFORENT} alt="for-rent-banner" style={{width:"100%"}}/>
        </Box>

        <Box
          padding="0 15px"
          margin="30px auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("rent-a-property")} />
        </Box>
        <Box
          padding="0 15px"
          margin="30px auto"
          gap={"5px !important"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
            <Box display={"flex"} marginRight={i18n.language === "en"? "auto" : '0'} >
              {filterTypes.map((filter, index) => (
                <ControlledButton
                  key={index}
                  onClick={() => handleButtonClick(filter.name)}
                  className={`property-option ${
                    activeType === filter.name ? "active" : ""
                  }`}
                  value={filter.name}
                >
                  {filter.name}
                </ControlledButton>
              ))}
            </Box>
            {/* <Box marginLeft={'auto'} className="total-result">Total Results <span>{propertiesList?.properties?.total}</span></Box> */}

            <Box marginLeft={i18n.language === "en"? "auto" : '0'}  className="total-result">
              {t("rent-property.total-results")}{" "}
              <span>
                {searchPerformed
                  ? searchedProperties?.properties?.total
                  : propertiesList?.properties?.total}
              </span>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <Autocomplete
                ListboxProps={{
                  className: "myCustomList",
                }}
                componentsProps={{
                  paper: {
                    sx: {
                      width: 830,
                      border: "1px solid #d1d1d1",
                      overflowX: "hidden",
                      marginTop: "10px",
                      padding: "20px",
                      fontSize: "14px !important",
                    },
                  },
                }}
                multiple
                limitTags={2}
                options={places}
                getOptionLabel={(option) => option.place_name}
                value={selectedPlaces}
                onChange={(event, newValue) =>
                  handlePlaceChange(event, newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ marginRight: 0 }}
                        >
                          <Icons.LOCATION
                            style={{
                              height: "24px",
                              width: "24px",
                              margin: "0 5px",
                            }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ marginRight: 0 }}
                        >
                          <Box className="selected-count">
                            {selectedPlaces.length}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    placeholder={t('for-rent.location-placeholder')}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    sx={{
                      "& .MuiAutocomplete-input": {
                        padding: "0 !important",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "rgba(0, 0, 0, 0.7)",
                        fontSize: "14px !important",
                        fontStyle: "normal",
                        fontWeight: 400,
                      },
                    }}
                  />
                )}
                sx={{
                  "& fieldset": { border: "none", padding: 0 },
                  "& .MuiAutocomplete-inputRoot": {
                    width: "100%",
                    padding: "9px 0 !important",
                  },
                }}
                classes={{
                  root: classes.autocompleteRoot,
                  inputRoot: classes.inputRoot,
                }}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1">
                        {option.place_name}
                      </Typography>

                      <Checkbox
                        sx={{ marginRight: "8px" }}
                        checked={selected}
                        color="primary"
                      />
                    </Grid>

                    {selectedPlaces.length > 0 && (
                      <Typography
                        sx={{
                          backgroundColor: "#E4E4E4",
                          borderRadius: "50px",
                          fontSize: "14px !important",
                          padding: "12px 30px",
                          color: "#000",
                        }}
                      >
                        {selectedPlaces[0].place_name}
                      </Typography>
                    )}
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2} className="type-selector">
              <Select
                multiple
                placeholder="Types"
                value={propertyType}
                onChange={(e) => onTypeChange(e.target.value)}
                input={
                  <OutlinedInput classes={{ root: classes.MuiInputBaseRoot }} />
                }
                className={classes.selectRoot}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      border: "1px solid #d1d1d1",
                      borderRadius: "8px",
                      padding: "18px 24px",
                      marginTop: "10px",
                    },
                  },
                }}
              >
                <CustomTab
                  className="custom-tabs"
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  classes={{ muiBox: "custom-mui-box", tabs: "custom-tabs" }}
                >
                  {tabContents.map((content, index) => (
                    <div
                      key={index}
                      label={index === 0 ? "Residential" : "Commercial"}
                    >
                      <Grid xs={6}>{content}</Grid>
                    </div>
                  ))}
                </CustomTab>
                <Box display={"flex"} justifyContent={"flex-end"} gap={"20px"}>
                  <Button
                    sx={{
                      background: "#fff",
                      border: "1px solid #D1D1D1",
                      width: "90px",
                      height: "38px",
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    sx={{
                      background: "#D1D1D1",
                      width: "90px",
                      height: "38px",
                    }}
                    onClick={handleClose}
                  >
                    Done
                  </Button>
                </Box>
              </Select>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {t('for-rent.beds-bath')} <Icons.BTNICON />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  maxWidth: "100%",
                  "& .MuiPaper-root": {
                    width: "unset",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    padding: "15px 30px",
                    border: "1px solid #D1D1D1",
                    borderRadius: "8px",
                    marginTop: "7px",
                  },
                }}
              >
                {bedsAndBaths.map((category) => (
                  <Box key={category.id} display={"flex"} alignItems={"center"}>
                    <Typography>{category.name}</Typography>
                    <Box className="beds-bath-box">
                      {category.value.map((option, index) => (
                        <MenuItem
                          key={index}
                          className={`beds-baths-opt ${
                            category.selected === option ? "selected" : ""
                          }`}
                          onClick={() => {
                            if (category.id === 1 && option === "studio")
                              return;
                            category.handleClick(option);
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                      <MenuItem
                        className={`beds-baths-opt`}
                        disabled={
                          category.id === 1 && category.selected === "studio"
                        }
                        onClick={() => {
                          if (
                            category.id === 1 &&
                            category.selected === "studio"
                          )
                            return;
                          const value = category.selected;
                          category.handleClick((Number(value) - 1).toString());
                        }}
                      >
                        -
                      </MenuItem>
                      <MenuItem
                        className={`beds-baths-opt`}
                        disabled={
                          category.id === 1 && category.selected === "studio"
                        }
                        onClick={() => {
                          if (
                            category.id === 1 &&
                            category.selected === "studio"
                          )
                            return;
                          const value = category.selected;
                          category.handleClick((Number(value) + 1).toString());
                        }}
                      >
                        +
                      </MenuItem>
                    </Box>
                  </Box>
                ))}
                <Box display={"flex"} justifyContent={"end"}>
                  <Button
                    sx={{
                      background: "#D1D1D1",
                      width: "90px",
                      height: "38px",
                      marginTop: "30px",
                    }}
                  >
                    Done
                  </Button>
                </Box>
              </Menu>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <Button
                onClick={handlePriceBtnClick}
                id="price-button"
                aria-controls={filterOpennow ? "price-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={filterOpennow ? "true" : undefined}
                sx={{
                  border: "1px solid #d1d1d1",
                  borderRadius: "8px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {t('for-rent.price')} <Icons.BTNICON />
              </Button>
              <Menu
                id="price-menu"
                anchorEl={priceOpen}
                open={priceOpen}
                onClose={handlePriceClose}
                MenuListProps={{
                  "aria-labelledby": "price-button",
                }}
                sx={{
                  maxWidth: "100%",
                  "& .MuiPaper-root": {
                    width: "unset",
                    minWidth: "max-content",
                    minHeight: "max-content",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "400px",
                    height: "220px",
                    overflowY: "auto",
                    borderRadius: "8px",
                    marginTop: "7px",
                  },
                }}
              >
                <PriceSlider
                  minValue={minPrice}
                  maxValue={maxPrice}
                  onChange={handlePriceChange}
                />
              </Menu>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                sx={{
                  border: "1px solid #d1d1d1",
                  borderRadius: "8px",
                  height: "48px",
                  width: "48px",
                }}
                id="filter-button"
                aria-controls={filterOpennow ? "filter-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={filterOpennow ? "true" : undefined}
                onClick={handleFilterBtnClick}
              >
                <Icons.FILTER />
              </IconButton>
              <Menu
                className="filters-menu"
                id="filter-menu"
                anchorEl={filterOpen}
                open={filterOpen}
                onClose={handleFilterClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                MenuListProps={{
                  "aria-labelledby": "filter-button",
                }}
                sx={{
                  "& .MuiList-root": {
                    width: "1100px",
                  },

                  "& .MuiPaper-root": {
                    minWidth: "max-content",
                    minHeight: "max-content",
                    maxHeight: "100%",
                    padding: "15px 30px",
                    border: "1px solid #D1D1D1",
                    borderRadius: "8px",
                    marginTop: "7px",
                  },
                }}
              >
                <Box>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: "10px",
                            textAlign: i18n?.language === "en" ? "left" : "right",
                             marginRight: i18n?.language === "en" ? "0px" : "36px"
                          }}
                        >
                            {t('for-rent.rent-paid')}
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                          }}
                        >
                          {rentOptions.map((rent) => (
                            <li
                              key={rent.id}
                              style={{
                                border: "1px solid #D1D1D1",
                                padding: "12px",
                                borderRadius: "10px",
                                fontSize: "14px",
                                fontWeight: "400",
                                cursor: "pointer",
                                marginBottom: "15px",
                              }}
                              onClick={() => handleRentClick(rent)}
                              className={
                                selectedRent.some(
                                  (selected) => selected.id === rent.id
                                )
                                  ? "selected-amenity"
                                  : ""
                              }
                            >
                              {" "}
                              {rent.title}
                            </li>
                          ))}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={6} md={6} lg={3}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          marginBottom: "10px",
                          textAlign: i18n?.language === "en" ? "left" : "right",
                           marginRight: i18n?.language === "en" ? "0px" : "36px"
                        }}
                      >
                      {t('for-rent.furnishing-status')}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        {furnishingStatus.map((status) => (
                          <li
                            key={status.id}
                            style={{
                              border: "1px solid #D1D1D1",
                              padding: "12px",
                              borderRadius: "10px",
                              fontSize: "14px",
                              fontWeight: "400",
                              cursor: "pointer",
                              marginBottom: "15px",
                            }}
                            onClick={() => handleFurnishedClick(status)}
                            className={
                              selectedFurnished.some(
                                (selected) => selected.id === status.id
                              )
                                ? "selected-amenity"
                                : ""
                            }
                          >
                            {status.title}
                          </li>
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <Box
                        sx={{
                          marginBottom: isMobile ? "100px" : "0px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                              textAlign: i18n?.language === "en" ? "left" : "right",
                               marginRight: i18n?.language === "en" ? "0px" : "36px"
                          }}
                        >
                           {t('for-rent.area')} {" "}
                        </Typography>

                        <Box>
                          <AreaSlider
                            minAreaValue={minAreaValue}
                            maxAreaValue={maxAreaValue}
                            onChange={handleAreaChange}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={6} md={6} lg={3}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          marginBottom: "10px",
                          textAlign: i18n?.language === "en" ? "left" : "right",
                          marginRight: i18n?.language === "en" ? "0px" : "36px"
                        }}
                      >
                        {t('for-rent.listed-by')}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        {ListedOptions.map((option) => (
                          <li
                            key={option.id}
                            style={{
                              border: "1px solid #D1D1D1",
                              padding: "12px",
                              borderRadius: "10px",
                              fontSize: "14px",
                              fontWeight: "400",
                              cursor: "pointer",

                              marginBottom: "15px",
                            }}
                            onClick={() => handleListedClick(option)}
                            className={
                              selectedListedOption.some(
                                (selected) => selected.id === option.id
                              )
                                ? "selected-amenity"
                                : ""
                            }
                          >
                            {option.title}
                          </li>
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          marginTop: isMobile ? "20px" : "46px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: "10px",
                            textAlign: i18n?.language === "en" ? "left" : "right",
                             marginRight: i18n?.language === "en" ? "0px" : "36px"
                          }}
                        >
                          {t('for-rent.amenities')}
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          {amenities.map((amenity) => (
                            <li
                              key={amenity.id}
                              style={{
                                border: "1px solid #D1D1D1",
                                padding: "12px",
                                borderRadius: "10px",
                                fontSize: "14px",
                                fontWeight: "400",
                                cursor: "pointer",
                              }}
                              onClick={() => handleAmenityClick(amenity)}
                              className={
                                selectedAmenities.some(
                                  (selected) => selected.id === amenity.id
                                )
                                  ? "selected-amenity"
                                  : ""
                              }
                            >
                              {amenity.title}
                            </li>
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      marginTop: isMobile ? "20px" : "50px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: isMobile ? "start" : "end",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        gap: "22px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: "10px",
                            textAlign: i18n?.language === "en" ? "left" : "right",
                             marginRight: i18n?.language === "en" ? "0px" : "8px"
                          }}
                        >
                          {t('for-rent.real-estate-agency')}
                        </Typography>

                        <TextField
                          sx={{
                            height: "38px !important",
                            borderRadius: "10px !important",
                          }}
                          placeholder="e.g. XYZ Properties"
                          size="small"
                        />
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            marginBottom: "10px",
                            textAlign: i18n?.language === "en" ? "left" : "right",
                             marginRight: i18n?.language === "en" ? "0px" : "8px"
                          }}
                        >
                          {t('for-rent.keywords')}
                        </Typography>

                        <TextField
                          sx={{
                            borderRadius: "10px !important",
                          }}
                          placeholder="e.g. Pool, Security, Ref ID No. "
                          size="small"
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        marginTop: isMobile ? "20px" : "0px",
                      }}
                    >
                      <Button
                        sx={{
                          background: "#fff",
                          border: "1px solid #D1D1D1",
                          width: "90px",
                          height: "38px",
                        }}
                        onClick={handleResetClick}
                      >
                        {t('for-rent.reset')}
                      </Button>
                      <Button
                        sx={{
                          background: "#D1D1D1",
                          width: "90px",
                          height: "38px",
                        }}
                        onClick={handleClose}
                      >
                        {t('for-rent.done')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Menu>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Button
                sx={{
                  background: theme.palette.primary.blue,
                  color: "#fff",
                  height: "48px",
                  borderRadius: "8px",
                  width: "100%",

                  "&:hover": {
                    backgroundColor: theme.palette.primary.blue,
                    color: "#fff",
                  },
                }}
                onClick={handleSearchButtonClick}
              >
                {t('for-rent.search-btn')}
              </Button>
            </Grid>
          </Grid>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            gap={"30px"}
            marginTop={"50px"}
            overflowX={"hidden"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <IconButton className="icon-button">
              <Icons.BOOKMARK />
            </IconButton>
            <Box display={"flex"} gap={"20px"} className="filters-box">
              <Box display={"flex"} gap={"10px"}>
                <IconButton
                  className="icon-button"
                  sx={{
                    display: "flex",
                    gap: "10px",
                    backgroundColor: "#E4E4E4",
                  }}
                >
                  <Icons.VERIFIED /> Verified
                </IconButton>
                <IconButton
                  className="icon-button"
                  sx={{ display: "flex", gap: "10px" }}
                >
                  <Icons.MEMBER />
                  Members
                </IconButton>
              </Box>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortBy}
                  onChange={handleSortChange}
                  className="my-select"
                  sx={{
                    width: "100px",
                    height: "48px",
                    border: "1px solid #d1d1d1",
                    borderRadius: "8px",
                    fontSize: "14px !important",

                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <MenuItem
                    value="latest"
                    sx={{
                      fontSize: "15px !important",
                    }}
                  >
                    Latest
                  </MenuItem>
                  <MenuItem
                    value="default"
                    sx={{
                      fontSize: "15px !important",
                    }}
                  >
                    Default
                  </MenuItem>
                </Select>
              </FormControl>
              <Box display={"flex"} gap={"5px"}>
                <IconButton
                  className="icon-button"
                  onClick={handleListViewClick}
                >
                  <Icons.PREV />
                </IconButton>
                <IconButton
                  className="icon-button"
                  onClick={handleGridViewClick}
                >
                  <Icons.DASHBOARD />
                </IconButton>
              </Box>
            </Box>
          </Box>
          {/* property-area */}
          <Grid container>
            {/* <Grid
              item
              xs={12}
              md={5}
              lg={6}
              className="item"
              position={"relative"}
            >
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: isMobile ? "20px auto" : "0 auto",
                  width: isMobile ? "unset" : "500px",
                  borderRadius: "50px",
                  boxShadow: "0px 4px 14px rgba(0,0,0, 15%)",
                  height: "45px",
                  position: "relative",
                  zIndex: "100",
                  top: "30px",
                  margin: "0 auto",
                  width:"450px",
                  marginBottom: "-45px"
                }}
              >
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <Icons.SEARCH />
                </IconButton>
                <InputBase
                  value={searchValue}
                  onChange={handleSearchChange}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Creek Horizon Tower 2..."
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                  onClick={resetSearchValue}
                >
                  <CloseIcon />
                </IconButton>
              </Paper>
              <ControlledMap />
            </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
              <Box
                className={`properties-item ${
                  isGridView ? "grid-view" : "list-view"
                }`}
                style={{
                  display: "grid",
                  gridTemplateColumns: isMobile
                    ? "repeat(1, 0fr) !important"
                    : "repeat(4, 0fr)",
                  gridGap: "0",
                  placeContent: isGridView ? "start" : "start",
                }}
              >
                {filtersApplied
                  ? searchedProperties &&
                    searchedProperties.properties &&
                    searchedProperties.properties.data
                      .slice(0, adsToShow)
                      .map((ad, index) => (
                        <Box
                          key={index}
                          style={{
                            boxSizing: "border-box",
                            padding: "10px",
                            margin: "0",
                            width: "max-content",
                          }}
                        >
                          <AdCard
                            classes={classes}
                            data={ad}
                            verifiedRankStyles={verifiedRankStyles}
                            featuredStyles={featuredStyles}
                            bedroomBathroomAspectStyles={
                              bedroomBathroomAspectStyles
                            }
                            statusStyles={statusStyles}
                            isMobile={isMobile}
                            lang={i18n.language}
                          />
                        </Box>
                      ))
                  : propertiesList &&
                    propertiesList.properties &&
                    propertiesList.properties.data
                      .slice(0, adsToShow)
                      .map((ad, index) => (
                        <Box
                          key={index}
                          style={{
                            boxSizing: "border-box",
                            padding: "10px",
                            margin: "0",
                            width: "max-content",
                          }}
                        >
                          <AdCard
                            classes={classes}
                            data={ad}
                            verifiedRankStyles={verifiedRankStyles}
                            featuredStyles={featuredStyles}
                            bedroomBathroomAspectStyles={
                              bedroomBathroomAspectStyles
                            }
                            statusStyles={statusStyles}
                            isMobile={isMobile}
                            lang={i18n.language}
                          />
                        </Box>
                      ))}
              </Box>
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
export default PropertyForRent;
