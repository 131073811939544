import React, { useState, useEffect} from 'react'
import { GET_FAVOURITES } from '../../api/queries/home.queries'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Stack,
    Typography,
    Card,
    CardContent,
    useMediaQuery,
    Container,
    useTheme
  } from "@mui/material";
import SectionTitle from '../../components/section-title/section-title.component';
import { useQuery } from '@apollo/client';
import { generatePath, useNavigate } from "react-router-dom";
import Icons from '../../assets/icons'
import Skeleton from "@mui/material/Skeleton";
import CryptoJS from "crypto-js";
import IconWithBadge from '../../components/icon-with-badge/icon-with-badge.component';
import { formatNumberWithCommas } from '../../helpers/global';
import * as ROUTES from "../../constants/routes";
import { makeStyles } from "@mui/styles";

const baseURL = process.env.REACT_APP_BASE_URL

const useStyles = makeStyles((theme) => ({
    cardRoot: {
      width: "unset",
      transition: "box-shadow 0.3s",
      cursor: "pointer",
      borderRadius: "18px !important",
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      textAlign: "center",
      height: "unset",
      "& svg": {
        width: "100%",
        height: "auto",
        maxWidth: "100%",
        objectFit: "contain",
      },
    },
    headline: {
      fontStyle: "normal",
      lineHeight: "normal !important",
      fontWeight: "bold !important",
      textAlign: "start",
      marginTop: "12px!important",
      marginBottom: "0!important",
    },
    mobileMedia: { margin: 5 },
    badges: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
    },
  }));



const AdCard = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const {
      classes,
      data,
      verifiedRankStyles,
      featuredStyles,
      bedroomBathroomAspectStyles,
      statusStyles,
      isMobile,
      lang,
    } = props;
  
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      const loadData = async () => {
        // simulate loading delay
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false);
      };
      loadData();
    }, []);
  
    // hashing the ids
    const encryptId = (id) =>{
      const passphrase = process.env.REACT_APP_ENCRYPTION_KEY;
      return CryptoJS.AES.encrypt(id.toString(),passphrase.toString());
    }
  
  
    const handleClickProperty = (id) => {
      const encryptedId = encryptId(id);
      navigate(generatePath(`${ROUTES.PROPERTY}`, { id: encodeURIComponent(encryptedId) }));
    };
  
    return (
      <Card
        className={classes.cardRoot}
        sx={{
          borderTopRightRadius: "5px!important",
          borderTopLeftRadius: "5px!important",
          cursor: "pointer",
          boxShadow: "unset",
        }}
        onClick={() => handleClickProperty(data?.id.toString())}
      >
        {loading ? (
          <Skeleton
            variant="rect"
            width={281}
            height={183}
            style={{ borderRadius: "5px" }}
            animation="wave"
          />
        ) : (
            <Box position={"relative"} width={"281px"} height={"183px"}>
            {data?.image ? (
            
              <img src={`${baseURL}${data?.image}`} alt="Property" style={{ width: "281px", height: "183px", borderRadius:"10px" }} />
            ) : (
              <Box
                display={"grid"}
                height={"100%"}
                borderRadius={"8px"}
                border={"3px solid #D9D9D9"}
              >
                <Box alignSelf={"center"} justifySelf={"center"}>
                  <Icons.NO_IMAGE style={{ width: "50%" }} loading="lazy" />
                </Box>
              </Box>
            )}
          </Box>
        )}
  
        <CardContent
          sx={{ padding: isMobile ? "16px 0px" : "16px 0px", display: "grid" }}
        >
          <Stack direction={"row"} display={"flex"}>
            {/* <IconWithBadge
              icon={<Icons.VERIFIED className={isMobile ? classes.mobileMedia : ''} />}
              title={data?.verified && 'Verified'}
              badgeStyle={verifiedRankStyles}
                  /> */}
            {/* {!isMobile && (
                      <IconWithBadge
                          icon={<Icons.RANK />}
                          title={data?.rank}
                          badgeStyle={verifiedRankStyles}
                      />
                  )} */}
            {/* <IconWithBadge
                      title={data?.featured && 'Featured'}
                      badgeStyle={featuredStyles}
                  /> */}
          </Stack>
          <Box padding={isMobile && "0 8px"} textAlign={"start"}>
            {loading ? (
              <Skeleton animation="wave" />
            ) : (
              <Box className={classes.headline} gap={"8px"} display={"grid"}>
                <Typography
                  variant="h3"
                  color={theme.palette.primary.main}
                >{`${formatNumberWithCommas(
                  data?.price
                )} AED / Yearly`}</Typography>
                <Typography
                  variant="h3"
                  fontWeight={400}
                  color={theme.palette.primary.main}
                  sx={{
                    width: '250px',
                    overflow: 'hidden',
                    textOverflow:'ellipsis',
                    whiteSpace: 'noWrap'
                  }}
                >
                  {data?.title}
                </Typography>
              </Box>
            )}
  
          </Box>
  
          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <Typography
              color={theme.palette.primary.main}
              display={"flex"}
              gap={"10px"}
              padding={isMobile ? "6px" : 0}
              marginTop={"10px"}
              sx={{ cursor: "pointer", flexWrap: "wrap" }}
            >
              <IconWithBadge
                icon={<Icons.BEDROOM style={{ width: 20, height: 20 }} />}
                title={data?.bedrooms}
                badgeStyle={bedroomBathroomAspectStyles}
                className={classes?.badges}
              />
              <IconWithBadge
                icon={<Icons.BATHROOM style={{ width: 20, height: 20 }} />}
                title={data?.bathrooms}
                badgeStyle={bedroomBathroomAspectStyles}
                className={classes?.badges}
              />
              <IconWithBadge
                icon={<Icons.ASPECT_RATIO style={{ width: 20, height: 20 }} />}
                title={`${formatNumberWithCommas(Number(data?.size))} sqft`}
                badgeStyle={bedroomBathroomAspectStyles}
                className={classes?.badges}
              />
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  };

const Favorites = () => {
    const { t, i18n } = useTranslation("common");
    const theme = useTheme();
    const classes = useStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    // get current user id
    let userId = null;
    let userData = localStorage.getItem("userData");
    let user = JSON.parse(userData);
  
    if (user) {
      userId = parseInt(user.id);
    }

// fetch favorites:

    const { loading, error, data: favoritesList, refetch  } = useQuery(GET_FAVOURITES, {
        variables: {
            page: null,
            userId : userId,
        }
    })

    
    useEffect(() => {
      refetch();
  }, [refetch]);



    const verifiedRankStyles = {
        display: "inline-flex",
        gap: "5px!important",
        borderRadius: 100,
        background: "#EFEFEF",
        alignItems: "center",
        padding: "2px 7px",
        margin: isMobile ? "0 5px" : "0 5px 0 0",
      };
      const featuredStyles = {
        padding: "5px 9px",
        borderRadius: 100,
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      };
      const bedroomBathroomAspectStyles = {
        borderRadius: "4px!important",
        border: "1px solid #EFEFEF",
        padding: "4px 12px",
        margin: "8px 0px!important",
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.main,
      };
      const statusStyles = {
        padding: "5px 9px",
        position: "absolute",
        top: "12%",
        width: "max-content",
        left: 0,
        borderRadius: "0px 3px 3px 0px",
        background: theme.palette.secondary.main,
      };
    
      const customButtonStyles = {
        background: "#fff",
        borderRadius: "40px",
        background: "#FFF",
        boxShadow: "0px 4px 74px 0px rgba(0, 0, 0, 0.10)",
        border: "none",
        width: "205px",
        height: "52px",
      };

  return (
    <>
    
    <Helmet>
        <title>{t("favorites.meta-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>

    <Container>

    <Box
          margin="50px auto 0px auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("favorites.title")} />
        </Box>


        <Box>

        <Box
            className="ad-box-last"
            style={{
              display: "grid",
              gridTemplateColumns: isMobile
                ? "repeat(1, 0fr)"
                : "repeat(4, 0fr)",
              gridGap: "0",
              placeContent: "center",
              marginTop:"80px"
            }}
          >
            {favoritesList &&
              favoritesList?.favourites?.data?.map((favorite) => (
                  <Box
                    key={favorite.id}
                    style={{
                      boxSizing: "border-box",
                      padding: "10px",
                      margin: "0",
                      width: "max-content",
                    }}
                  >
                    <AdCard
                      classes={classes}
                      loading={loading}
                      data={favorite}
                      verifiedRankStyles={verifiedRankStyles}
                      featuredStyles={featuredStyles}
                      bedroomBathroomAspectStyles={bedroomBathroomAspectStyles}
                      statusStyles={statusStyles}
                      isMobile={isMobile}
                      lang={i18n.language}
                    />
                  </Box>
                ))}
          </Box>
        </Box>


    </Container>
    
    
    </>
  )
}

export default Favorites