import {
  Box,
  Stack,
  useMediaQuery,
  useTheme,
  Container,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Icons from "../../../assets/icons";
import {
  CustomTab,
  CustomTabPanel,
} from "../../../components/tabs/tabs.component";
import * as ROUTES from "../../../constants/routes";
import { useLocation } from "react-router-dom";
import EmailLogin from "./EmailLogin";
import PhoneNumberLogin from "./PhoneNumberLogin";
import SocialAuthentication from "../../social-login.component";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet";
import SectionTitle from "../../../components/section-title/section-title.component";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "react-router-dom";
import "./login.scss";
const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
const Login = () => {
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const currentLocationState = location?.state?.method;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    if (
      currentLocationState !== undefined &&
      currentLocationState === "email"
    ) {
      setTabValue(0);
    } else if (
      currentLocationState !== undefined &&
      currentLocationState === "phone-number"
    ) {
      setTabValue(1);
    }
  }, [currentLocationState]);
  return (
    <>
      <Helmet>
        <title>{t("authentication.login.login")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>

      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "40px 0px 46px 0px",
          }}
        >
          <SectionTitle
            variant={"h2"}
            title={t("authentication.login.login")}
          />
        </Box>
        <Box sx={{}}>
          {/* <BackTo
          title={
            (currentLocationState !== undefined &&
              currentLocationState?.method === "email") ||
            tabValue === 0
              ? `${t(`authentication.login.login`)} ${t(
                  "authentication.login.with_email"
                )}`
              : (currentLocationState !== undefined &&
                  currentLocationState?.method === "phone-number") ||
                tabValue === 1
              ? `${t(`authentication.login.login`)} ${t(
                  "authentication.login.with_phone_number"
                )}`
              : ""
          }
          path={ROUTES.LOGIN_METHODS}
          func="back"
        /> */}
          <Stack
            className="autharea"
            sx={{
              overflow: "hidden",
              display: "flex",
              justifyItems: "center",
              width: isMobile ? "100%" : "508px",
              border: "1px solid #cccccc",
              borderRadius: "20px",
              margin: isMobile ? "0px auto 120px auto" : "0px auto 240px auto",
            }}
          >
            <CustomTab
              tabValue={tabValue}
              setTabValue={setTabValue}
              classes={classes}
              sx={{
                "&.MuiTabs-flexContainer": {
                  justifyContent: "space-between",
                },
              }}
            >
              <CustomTabPanel
                label={
                  <Box
                    color={theme.palette.primary.main}
                    fontSize={14}
                    textTransform="capitalize"
                    gap={"5px!important"}
                    display={"flex"}
                    sx={{ placeItems: "center" }}
                  >
                    <Icons.EMAIL_OPEN />
                    {t("authentication.login.login-with-email")}
                  </Box>
                }
              >
                <EmailLogin />
              </CustomTabPanel>
              <CustomTabPanel
                label={
                  <Box
                    color={theme.palette.primary.main}
                    fontSize={14}
                    textTransform="capitalize"
                    gap={"5px !important"}
                    display={"flex"}
                    sx={{ placeItems: "center" }}
                  >
                    <Icons.PHONE_NUMBER />
                    {t("authentication.login.login-with-mobile")}
                  </Box>
                }
              >
                <PhoneNumberLogin />
              </CustomTabPanel>
            </CustomTab>
            <Box
              className="remember"
              sx={{
                margin: 0,
                padding: 0,
                textAlign:
                  i18n.language === "en"
                    ? "right"
                    : i18n.language === "ar"
                    ? "left"
                    : "",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label={t('authentication.login.remember')}
                sx={{
                  ".MuiTypography-root": {
                    fontSize: "12px",
                  },
                }}
              />

              <Typography sx={{ fontSize: "14px !important" }}>
                {t("authentication.login.forgot_pass")}
                <Link
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: "unset",
                    cursor: "pointer",
                    fontWeight: "700",
                    fontSize: "14px !important",
                    marginLeft: "5px",
                  }}
                  to={ROUTES.FORGET_PASSWORD}
                >
                  {t("authentication.forget_password.click-here")}
                </Link>
              </Typography>
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "18px !important",
                fontWeight: "600",
                marginTop: "40px",
              }}
            >
              {t('authentication.login.continue-with')}
            </Typography>
            <SocialAuthentication />


              <Box>

                <Typography sx={{fontSize:'16px !important',textAlign:"center",marginTop:"12px",color:"#86878B !important",fontWeight:"400"}}>{t("authentication.login.no-account")} <Link style={{fontSize:"16px", textDecoration:"none", color:theme.palette.primary.dark, fontWeight:"bold"}} to={ROUTES.REGISTER}> {t("authentication.login.no-account-register")}</Link> </Typography>

              </Box>
            

            <Box
              sx={{
                marginTop: "40px !important",
                marginLeft: "auto",
                marginRight: "auto",
                gap: "30px !important",
                display: "grid",
                justifyItems: "center",
              }}
            >
              <Typography
                className="agreement"
                sx={{
                  marginTop: "40px",
                  textAlign: "center",
                }}
              >
                <span id="custom_full_span">
                  {" "}
                  {t("authentication.login.agreement-statement")}{" "}
                  <span id="custom_sub_span">
                    {" "}
                    <Link
                      style={{ textDecoration: "none", color: "#000" }}
                      to={ROUTES.TERMS_CONDITIONS}
                    >
                      {" "}
                      {t("authentication.login.agreement-statement-sub")}
                    </Link>
                  </span>{" "}
                  {t("authentication.login.agreement-statement-sub2")}{" "}
                  <span id="custom_sub_span">
                    {" "}
                    <Link
                      style={{ textDecoration: "none", color: "#000" }}
                      to={ROUTES.PRIVACY_POLICY}
                    >
                      {" "}
                      {t("authentication.login.agreement-statement-sub3")}
                    </Link>
                  </span>
                  {t("authentication.login.agreement-statement-sub4")}
                </span>
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Container>
    </>
  );
};
export default Login;
