import Hashids from 'hashids';

const hashids = new Hashids(process.env.REACT_APP_ENCRYPTION_KEY, 10);

// Function to encode ID
export function encodeId(id) {
  return hashids.encode(id);
}

// Function to decode ID
export function decodeId(encodedId) {
  const decoded = hashids.decode(encodedId);
  return decoded.length ? decoded[0] : null; 
}
