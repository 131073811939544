import FormBox from "../../../components/form-box/form-box.component";
import FormInput from "../../../components/form-input/form-input.component";
import { useTranslation } from "react-i18next";
import Icons from "../../../assets/icons";
import * as yup from "yup";
import { useState } from "react";
import { Alert, Collapse, IconButton, useTheme } from "@mui/material";
import Toaster from "../../../components/toaster/toaster.component";
import { useMutation } from "@apollo/client";
import { USER_VERIFICATION_MUTATION } from "../../../api/queries/auth.queries";
import { useFormik } from "formik";
const ByEmail = () => {
    const { t } = useTranslation("common");
    const theme = useTheme();
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openAlert, setOpenAlert] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const ByEmailSchema = yup.object().shape({
        email: yup
            .string()
            .email(t("authentication.login.email_format"))
            .required(t("authentication.login.email_required")),
    });
    const [forgetPasswordByEmailMutation] = useMutation(USER_VERIFICATION_MUTATION);
    const onSubmitByEmail = async (event) => {
        event.preventDefault();
        try {
            const { email } = formik.values;
            formik.setTouched({ email: true });
            ByEmailSchema.validateSync({ email }, { abortEarly: false });
            const response = await forgetPasswordByEmailMutation({
                variables: { value: email, type: "email" },
            });
            const isExist = response?.data?.userVerfication?.isExist;
            if (isExist) {
                setOpenSuccess(true);
                formik.resetForm();
                formik.submitForm();
            }
            else {
                setErrorMessage(t("authentication.register.otp.user_not_exist"));
                setOpenAlert(true);
            }
        }
        catch (error) {
            if (error.name === "ValidationError") {
                const validationErrors = {};
                error.inner.forEach((validationError) => {
                    validationErrors[validationError.path] = validationError.message;
                });
                formik.setErrors(validationErrors);
            }
            else {
                const { message } = error;
                setErrorMessage(message);
                setOpenAlert(true);
            }
        }
    };
    const handleCloseSuccess = () => {
        setOpenSuccess(false);
    };
    const handleClose = () => {
        setOpenAlert(false);
    };
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: ByEmailSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await onSubmitByEmail(values);
                setSubmitting(false);
            }
            catch (error) {
                setSubmitting(false);
            }
        },
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
    });
    return (<>
            {errorMessage && (<Collapse in={openAlert}>
                    <Alert severity="error" action={<IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
                                <Icons.CLOSE />
                            </IconButton>}>
                        {errorMessage}
                    </Alert>
                </Collapse>)}
            <FormBox onSubmit={(event) => onSubmitByEmail(event)} sx={{ display: "grid" }} submitValue={t("authentication.forget_password.continue")} submitStyle={{
            borderRadius: 40,
            background: theme.palette.primary.orange,
            color: theme.palette.secondary.main,
            textTransform: "capitalize",
            margin: "25px 0",
        }}>
                <FormInput {...formik.getFieldProps("email")} name="email" required fullWidth placeholder={t("authentication.login.social.email")} type="email" error={formik.touched["email"] && Boolean(formik.errors["email"])} helperText={formik.touched["email"] && formik.errors["email"]} label={"Email"} value={formik.values && formik.values["email"]
            ? formik.values["email"]
            : ""} icon={<Icons.MAIL style={{ margin: "0px 5px" }}/>}/>
            </FormBox>
            <Toaster open={openSuccess} title={`${t("common.great")}!`} description={t("authentication.forget_password.email_sent_msg")} handleClose={handleCloseSuccess} icon={<Icons.AGREE style={{ margin: "15px 0" }}/>}/>
        </>);
};
export default ByEmail;
