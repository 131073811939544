import Carousel, { consts } from "react-elastic-carousel";
import { DropzoneDialog } from "react-mui-dropzone";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import Icons from "../../assets/icons";
const CustomFileInput = ({
  title,
  onFilesChange,
  filesList,
  modalIsOpen,
  setModalIsOpen,
  onDeleteFile,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = (e) => {
    e.stopPropagation();
    setModalIsOpen(false);
  };
  const handleSave = (file, event) => {
    onFilesChange(file);
    handleClose(event);
  };
  const handleOpen = (e) => {
    e.stopPropagation();
    setModalIsOpen(true);
  };
  const breakPoints = [{ width: 1, itemsToShow: 1 }];
  const carouselArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <Icons.ARROW
          style={{ transform: "rotate(180deg)", cursor: "pointer" }}
        />
      ) : (
        <Icons.ARROW />
      );
    return (
      <Button
        onClick={onClick}
        disabled={isEdge}
        sx={{ minWidth: "unset", padding: "unset" }}
      >
        {pointer}
      </Button>
    );
  };
  return (
    <>
      <Box
        style={{
          textAlign: "center",
          padding: "30px",
          borderRadius: 0,
          width: "100%",
          cursor: "pointer",
          border: `1px dashed #D9D9D9`,
        }}
        onClick={(e) => handleOpen(e)}
      >
        <Box
          display={"flex"}
          gap={"15px"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            color: theme.palette.primary.main,
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          <Icons.ATTACHMENT />
          {title}
        </Box>
        <DropzoneDialog
          open={modalIsOpen}
          onSave={(file, event) => handleSave(file, event)}
          acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={(e) => handleClose(e)}
        />
      </Box>
      {filesList?.length > 0 && (
        <>
          <Carousel
            breakPoints={breakPoints}
            pagination={false}
            isRTL={false}
            renderArrow={carouselArrow}
            style={{
              margin: "10px 0",
              padding: "20px",
              border: `1px dashed ${theme.palette.primary.main}`,
              borderRadius: 10,
            }}
          >
            {filesList?.map((fileData) => (
              <Box
                display={isMobile ? "table-cell" : "contents"}
                key={fileData["key"]}
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <Icons.REMOVE
                  style={{
                    position: "absolute",
                    right: 0,
                    zIndex: 100,
                    cursor: "pointer",
                    opacity: 0.2,
                    margin: "5px",
                  }}
                  onClick={() => onDeleteFile(fileData["key"])}
                />
                <img
                  src={URL.createObjectURL(fileData["file"])}
                  alt={`Preview ${fileData["file_name"]}`}
                  style={{
                    width: "150px",
                    height: "200px",
                    objectFit: "cover",
                    border: "1px solid #999",
                  }}
                />
              </Box>
            ))}
          </Carousel>
        </>
      )}
    </>
  );
};
export default CustomFileInput;
