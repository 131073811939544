import React, { useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import SectionTitle from "../../components/section-title/section-title.component";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import "../AboutUs/about-us.scss";
const HeadtypographyStyles = {
  fontSize: "18px",
  fontWeight: "700",
  marginBottom: "28px",
};

const bodyTypoStyle = {
  fontSize: "14px",
  fontWeight: "400",
  marginBottom: "20px",
};

const TermsConditions = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleChange = (_, newIndex) => setActiveIndex(newIndex);

  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Helmet>
        <title>{t("terms.meta")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>
      <Container>
        <Box
          margin="70px auto 120px auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("terms.title")} />
        </Box>

        <Box class="terms-conditions">
          <Typography variant="h3" style={HeadtypographyStyles}>
            Your Relationship with Us
          </Typography>

          <Typography style={bodyTypoStyle}>
            Welcome to Kulushae (the “Platform”), which is provided by Cashgate
            Information Technology LLC (Kulushae, “we” or “us”). You are reading
            the terms of service (the “Terms”), which govern the relationship
            and serve as an agreement between you and us and set forth the terms
            and conditions by which you may access and use the Platform and our
            related websites, services, applications, products and content
            (collectively, the “Services”). Our Services are provided for
            private, non-commercial use. For purposes of these Terms, “you” and
            “your” means you as the user of the Services.
          </Typography>

          <Typography style={bodyTypoStyle}>
            Any dispute or claim arising out of or in connection with us shall
            be governed and construed in accordance with the laws of UAE. United
            Arab of Emirates is our country of domicile.
          </Typography>

          <Typography style={bodyTypoStyle}>
            The Terms form a legally binding agreement between you and us.
            Please take the time to read them carefully.
          </Typography>

          <Box sx={{ marginTop: "48px" }}>
            <Typography variant="h3" style={HeadtypographyStyles}>
              Accepting the Terms
            </Typography>

            <Typography style={bodyTypoStyle}>
              By accessing or using our Services, you confirm that you can form
              a binding contract with Chobi, that you accept these Terms and
              that you agree to comply with them. Your access to and use of our
              Services is also subject to our Privacy Policy, the terms of which
              can be found directly on the Platform, or where the Platform is
              made available for download, on your mobile device’s applicable
              app store, and are incorporated herein by reference. By using the
              Services, you consent to the terms of the Privacy Policy. If you
              are accessing or using the Services on behalf of a business or
              entity, then (a) “you” and “your” includes you and that business
              or entity, (b) you represent and warrant that you are an
              authorized representative of the business or entity with the
              authority to bind the entity to these Terms, and that you agree to
              these Terms on the entity’s behalf, and (c) your business or
              entity is legally and financially responsible for your access or
              use of the Services as well as for the access or use of your
              account by others affiliated with your entity, including any
              employees, agents or contractors. You can accept the Terms by
              accessing or using our Services. You understand and agree that we
              will treat your access or use of the Services as acceptance of the
              Terms from that point onwards. You should print off or save a
              local copy of the Terms for your records.
            </Typography>
          </Box>

          <Box sx={{ marginTop: "48px" }}>
            <Typography variant="h3" style={HeadtypographyStyles}>
              Changes to the Terms
            </Typography>

            <Typography style={bodyTypoStyle}>
              We amend these Terms from time to time, for instance when we
              update the functionality of our Services, when we combine multiple
              apps or services operated by us or our affiliates into a single
              combined service or app, or when there are regulatory changes. We
              will use commercially reasonable efforts to generally notify all
              users of any material changes to these Terms, such as through a
              notice on our Platform, however, you should look at the Terms
              regularly to check for such changes. We will also update the “Last
              Updated” date at the top of these Terms, which reflect the
              effective date of such Terms. Your continued access or use of the
              Services after the date of the new Terms constitutes your
              acceptance of the new Terms. If you do not agree to the new Terms,
              you must stop accessing or using the Services.
            </Typography>
          </Box>

          <Box sx={{ marginTop: "48px" }}>
            <Typography variant="h3" style={HeadtypographyStyles}>
              Your Account with Us
            </Typography>

            <Typography style={bodyTypoStyle}>
              To access or use some of our Services, you must create an account
              with us. When you create this account, you must provide accurate
              and up-to-date information. It is important that you maintain and
              promptly update your details and any other information you provide
              to us, to keep such information current and complete. It is
              important that you keep your account password confidential and
              that you do not disclose it to any third party. If you know or
              suspect that any third party knows your password or has accessed
              your account, you must notify us immediately via our available
              communication channels. You agree that you are solely responsible
              (to us and to others) for the activity that occurs under your
              account. We reserve the right to disable your user account at any
              time, including if you have failed to comply with any of the
              provisions of these Terms, or if activities occur on your account
              which, in our sole discretion, would or might cause damage to or
              impair the Services or infringe or violate any third-party rights,
              or violate any applicable laws or regulations. If you no longer
              want to use our Services again, and would like your account
              deleted, contact us and we will provide you with further
              assistance and guide you through the process. Once you choose to
              delete your account, you will not be able to reactivate your
              account or retrieve any of the content or information you have
              added.
            </Typography>
          </Box>

          <Box sx={{ marginTop: "48px" }}>
            <Typography variant="h3" style={HeadtypographyStyles}>
              Your Access to and Use of Our Services
            </Typography>

            <Typography style={bodyTypoStyle}>
              Your access to and use of the Services is subject to these Terms
              and all applicable laws and regulations. You may not:
            </Typography>

            <Typography style={bodyTypoStyle}>
              We reserve the right, at any time and without prior notice, to
              remove or disable access to content at our discretion for any
              reason or no reason. Some of the reasons we may remove or disable
              access to content may include finding the content objectionable,
              in violation of these Terms or otherwise harmful to the Services
              or our users. Our automated systems analyze your content to
              provide you personally relevant product features, such as
              customized search results, tailored advertising, and spam and
              malware detection. This analysis occurs as the content is sent,
              received, and when it is stored.
            </Typography>
          </Box>

          <Box sx={{ marginTop: "48px" }}>
            <Typography variant="h3" style={HeadtypographyStyles}>
              Intellectual Property Rights
            </Typography>

            <Typography style={bodyTypoStyle}>
              We respect intellectual property rights and ask you to do the
              same. As a condition of your access to and use of the Services,
              you agree not to use the Services to infringe on any intellectual
              property rights. We reserve the right, with or without notice, at
              any time and in our sole discretion to block access to and/or
              terminate the accounts of any user who infringes or is alleged to
              infringe any copyrights or other intellectual property rights.
            </Typography>
          </Box>

          <Box sx={{ marginTop: "48px" }}>
            <Typography variant="h3" style={HeadtypographyStyles}>
              Payment and Refund
            </Typography>

            <Typography style={bodyTypoStyle}>
              If you make a payment for our products or services on our
              platform, the details you are asked to submit will be provided
              directly to our payment provider via a secured connection.
            </Typography>
            <Typography style={bodyTypoStyle}>
              The cardholder will retain a copy of transaction records and
              Merchant policies and rules.
            </Typography>
            <Typography style={bodyTypoStyle}>
              We accept payments online using Visa and MasterCard credit/debit
              card in AED and USD.
            </Typography>
            <Typography style={bodyTypoStyle}>
              All credit/debit cards details and personally identifiable
              information will NOT be stored, sold, shared, rented or leased to
              any third parties.
            </Typography>
            <Typography style={bodyTypoStyle}>
              Refunds will be done only through the Original Mode of Payment.
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default TermsConditions;
