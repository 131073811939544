import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Box, List, Typography, useTheme } from "@mui/material";
import SectionTitle from "../../components/section-title/section-title.component";
import { GET_CATEGORY_CHILD_BY_ID } from '../../api/queries/categories.queries';
import ChildCategory from '../../pages/PostAd/ChildCategory';
const RentProperty = () => {
    const { id } = useParams();
    const { t } = useTranslation('common');
    const theme = useTheme();
    const { loading: isLoadingChildCategoryList, error: errorLoadingChildCategoryList, data: childCategoryList, refetch: refetchChildCategory } = useQuery(GET_CATEGORY_CHILD_BY_ID, {
        variables: {
            afl: 1
        },
    });

    useEffect(() => {
        if (errorLoadingChildCategoryList) {
            console.error('Error loading child category:', errorLoadingChildCategoryList);
        }
    }, [errorLoadingChildCategoryList]);
    useEffect(() => {
        console.log('loading');
    }, [isLoadingChildCategoryList]);
    useEffect(() => {
        if (id) {
            refetchChildCategory({
                categoryId: Number(id),
                afl: null,
                showOnScreen: null
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (<Box sx={{ margin: '50px 0' }}>
            <Box padding="0 15px" margin="auto" gap={'5px !important'} display="grid" sx={{ placeItems: "center" }}>
                <SectionTitle variant={'h2'} title={t('post-ad.place-ad')}/>
                <Typography color={theme.palette.primary.main}>{t('post-ad.description')}</Typography>
            </Box>
            <Box margin='0 auto' display='flex' justifyContent='center' alignItems='center' maxWidth={1200} width={'100%'}>
                <List style={{ width: '50%' }}>
                    <ChildCategory list={childCategoryList} mainCategoryId={Number(id)}/>
                </List>
            </Box>
        </Box>);
};
export default RentProperty;
