import CryptoJS from "crypto-js";


export const setLanguagePreference = (lang) => {
    localStorage.setItem('selectedLanguage', lang);
};
export const getLanguagePreference = () => {
    return localStorage.getItem('selectedLanguage') || 'en';
};
export const formatNumberWithCommas = (val) => {
    if (val === undefined)
        return "";
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const hidePhoneNumber = (number) => {
    // Splitting the phone number into different parts
    const country = number.substring(0, 3);
    const area = number.substring(3, 6);
    const local = number.substring(6);
    // Hiding the area code
    const hiddenArea = area.replace(/./g, '*');
    // Hiding the local number
    const hiddenLocal = local.replace(/./g, '*');
    // Combining the hidden parts with the country code
    const hiddenNumber = country + hiddenArea + hiddenLocal;
    return hiddenNumber;
};
export const dateFormmaterNoTime = (date) => {
    return date?.format("YYYY-MM-DD") || null;
};

export const formatDate = (timestamp) => {
    const time = Number(timestamp);
    if (isNaN(time)) return "Invalid date";

    const date = new Date(time);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const daySuffix = (day) => {
        if (day === 1 || day === 21 || day === 31) return `${day}st`;
        if (day === 2 || day === 22) return `${day}nd`;
        if (day === 3 || day === 23) return `${day}rd`;
        return `${day}th`;
    };

    return `${daySuffix(day)} of ${month} ${year}`;
};

