import React from 'react';
import { Button } from "@mui/material";
import { LoadingButton } from '@mui/lab';
const ControlledButton = ({ value, onClick, loadingButtonProps, customStyle, disabled, className, icon, ...otherProps }) => {
    if (loadingButtonProps) {
        return (<LoadingButton variant="contained" style={customStyle} onClick={onClick} {...loadingButtonProps} disabled={disabled} className={className}>
                {value}
            </LoadingButton>);
    }
    else {
        return (<Button variant="contained" style={customStyle} onClick={onClick} disabled={disabled} className={className} {...otherProps}>
                  {value}
                {icon && React.cloneElement(icon, { style: { marginLeft: '8px' } })}
          
            </Button>);
    }
};
export default ControlledButton;
