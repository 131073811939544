import React from 'react'
import Header from '../../components/Header/header.component'
import Footer from '../../components/Footer/footer.component'
import Chat from './Chat'
import { useTranslation } from 'react-i18next';
const Main = () => {

    const { i18n } = useTranslation();
  return (
    <>
    <Header />
    <div className={i18n.language === 'en' ? 'ltr-layout' : 'rtl-layout'}>
        <main>
            <Chat />
        </main>
        <Footer />
    </div>
</>
  )
}



export default Main