import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  IconButton,
  FormControl,
} from "@mui/material";
import {
  CustomTab,
  CustomTabPanel,
} from "../../components/tabs/tabs.component";
import { useTranslation } from 'react-i18next'
import { GET_PROPERTIES } from "../../api/queries/home.queries";
import { useQuery } from "@apollo/client";
import Icons from "../../assets/icons";
import Checkbox from "@mui/material/Checkbox";
import CustomPagination from "../../components/pagination/pagiantion.component";
import NewLogo from '../../assets/icons/new-logo.svg'

const appURL = process.env.REACT_APP_BASE_URL;

const MyAds = () => {

  const { t, i18n } = useTranslation('common');
  const [tabValue, setTabValue] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(5);
  const [adsToShow, setAdsToShow] = React.useState(5);

  let userData = localStorage.getItem("userData");
  let user = JSON.parse(userData);
  let userId = parseInt(user.id);

  // toggling list & grid view

  const [isGridView, setIsGridView] = useState(true);

  const handleGridViewClick = () => {
    setIsGridView(true);
  };

  const handleListViewClick = () => {
    setIsGridView(false);
  };

  // sorting

  const [sortBy, setSortBy] = useState("latest");

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const { loading, error, data } = useQuery(GET_PROPERTIES, {
    variables: {
      page: null,
      userId: userId,
    },
  });

  const totalAds = parseInt(data?.properties?.total);

  useEffect(() => {
    console.log("loading", loading);
  }, [loading]);

  useEffect(() => {
    console.log("error", error);
  }, [error]);

  // pagination

  const totalProperties = data?.properties?.total || 0;
  const totalPages = Math.ceil(totalProperties / propertiesPerPage);

  // Calculate the index of the first and last properties on the current page
  const indexOfLastProperty = Math.min(
    currentPage * propertiesPerPage,
    totalProperties
  );
  const indexOfFirstProperty = Math.min(
    (currentPage - 1) * propertiesPerPage,
    totalProperties - propertiesPerPage
  );
  const currentProperties =
    data?.properties?.data.slice(indexOfFirstProperty, indexOfLastProperty) ||
    [];

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Typography variant="body2" sx={{ fontSize: "18px", fontWeight: "600" }}>
        {t('profile.total-ads')}: {totalAds}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alginItems: "center",
        }}
      >
        <Box>
          <CustomTab tabValue={tabValue} setTabValue={setTabValue}>
            <CustomTabPanel label={t('profile.all')}></CustomTabPanel>
            {/* <CustomTabPanel label="Live">
                        </CustomTabPanel>
                        <CustomTabPanel label="Pending">
                        </CustomTabPanel> */}
          </CustomTab>
        </Box>

        <Box sx={{ display: "flex" }} className="ads-actions">
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortBy}
              onChange={handleSortChange}
              className="my-select"
              sx={{
                width: "100px",
                height: "48px",
                border: "1px solid #d1d1d1",
                borderRadius: "8px",
                fontSize: "14px !important",
                marginRight:i18n?.language === "en"? "20px" : "",
                marginLeft:i18n?.language === "en"? "0px" : "20px",

                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              <MenuItem
                value="latest"
                sx={{
                  fontSize: "15px !important",
                }}
              >
                {t('profile.ads-latest')}
              </MenuItem>
              <MenuItem
                value="default"
                sx={{
                  fontSize: "15px !important",
                }}
              >
               {t('profile.ads-default')}
              </MenuItem>
            </Select>
          </FormControl>
          <Box display={"flex"} gap={"5px"}>
            <IconButton className="icon-button" onClick={handleListViewClick}>
              <Icons.PREV />
            </IconButton>
            <IconButton className="icon-button" onClick={handleGridViewClick}>
              <Icons.DASHBOARD />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Grid container>
        {currentProperties.map((ad, index) => (
          <Grid item xs={12} md={12} lg={6} key={index}>
            <Box
              sx={{
                display: "flex",
                gap: "45px",
                alignItems: "center",
                marginBottom: "70px",
              }}
              className="ad-box"
            >
              <Box>
              {ad.images && ad.images.length > 0 && ad.images[0].image ? (
                <img
                  src={`${appURL}${ad.images[0].image}`}
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "10px",
                    border: "1px solid #f5f5f5"
                  }}
                  alt={`Ad ${index}`}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = require("../../assets/icons/new-logo.svg").default;
                    e.target.style.filter = "grayScale(1)";
                  }}
                />
              ) : (
                <Icons.NO_IMAGE />
              )}
            </Box>
              <Box>
                <Typography
                  noWrap
                  variant="h3"
                  sx={{
                    textTransform: "capitalize",
                    width: "260px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  className="ad-title"
                >
                  {ad.title}
                </Typography>
              </Box>
              <Box>
                <Checkbox />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <CustomPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default MyAds;
