import axios from "axios";
const restLink = process.env.REACT_APP_REST_API_URL;


const api = axios.create({
    baseURL: restLink,
    headers: {
        Pragma: "no-cache",
        "Cache-control": "no-cache",
    },
    timeout: 10000,
});

api.interceptors.request.use(async (config) => {
    try {
        const storedUser = localStorage.getItem('userData');
        const parsedUserData = storedUser ? JSON.parse(storedUser) : null;
        const token = parsedUserData?.login?.token || parsedUserData?.userVerfication?.token;
        
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    catch (error) {
        console.error('Error parsing user data:', error);
    }
    return config; 
}, (error) => {
    console.error('Interceptor error:', error);
    return Promise.reject(error);
});
export default api;
