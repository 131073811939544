import gql from 'graphql-tag';
const GET_COUNTRIES = gql `
    query Countries {
        countries {
            id
            iso3
            name
            states {
                id
                name
            }
        }
    }
`;
const GET_PROPERTIES = gql `
    query Properties($page: Int, $userId: Int, $filters: JSON) {
        properties(page: $page, user_id: $userId, filters: $filters) {
            current_page
            per_page
            total
            data {
                amenities {
                    id
                    title
                }
                annual_community_fee
                bathrooms
                bedrooms
                buyer_transfer_fee
                category_id
                contact_number
                description
                developer
                furnished
                id
                images {
                    id
                    image
                }
                location
                maintenance_fee
                neighbourhood
                occupancy_status
                posted_by
                price
                ready_by
                reference_number
                seller_transfer_fee
                size
                socialmedia {
                    id
                    type
                    value
                }
                title
                total_closing_fee
                user_id {
                    email
                    first_name
                    id
                    image
                    last_name
                    member_since
                    phone
                    total_listings
                }
            }
        }
    }`;


    const SEARCH = gql`
    query Search($value: String) {
        search(value: $value) {
          ads
          category
          category_id
          title
          type
        }
      }
    `


    // add to favourite


    const ADD_TO_FAVOURITE_MUTATION = gql`
    mutation Add_favorite($like: Boolean!, $itemId: Int, $type: String) {
        add_favorite(like: $like, item_id: $itemId, type: $type)
      }
    `

    const GET_FAVOURITES =  gql`
        query Favourites($userId: Int, $page: Int) {
        favourites(user_id: $userId, page: $page) {
            current_page
            data {
            id
            price
            title
            image
            type
            location
            bedrooms
            bathrooms
            size
            user_id
            car_year
            car_kilometers
            car_specs
            car_steering
            }
            per_page
            total
        }
        }
      `

    
export { GET_COUNTRIES, GET_PROPERTIES, SEARCH, ADD_TO_FAVOURITE_MUTATION, GET_FAVOURITES };
