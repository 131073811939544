import React, { createContext, useState, useContext } from 'react'
const CategoryContext = createContext();
export const useCategory = () => useContext(CategoryContext);
export const CategoryProvider  = ({ children }) => {
    const [selectedCategory, setSelectedCategory] = useState(2) 
  return (
    <CategoryContext.Provider value={{ selectedCategory, setSelectedCategory }}>
    {children}
  </CategoryContext.Provider>
  )
}

