import React, { useState } from "react";
import { Button } from "@mui/material";
const ShowMoreList = ({
  items,
  initialItemCount,
  selectedItems,
  onItemClick,
}) => {
  const [showAllItems, setshowAllItems] = useState(false);
  const handleToggleShow = () => {
    setshowAllItems(!showAllItems);
  };
  const displayedItems = showAllItems
    ? items
    : items.slice(0, initialItemCount);
  return (
    <>
      {displayedItems.map((item) => (
        <li
          key={item.id}
          style={{
            border: "1px solid #D1D1D1",
            padding: "12px",
            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: "400",
            cursor: "pointer",
          }}
          className={
            selectedItems.some((selected) => selected.id === item.id)
              ? "selected-amenity"
              : ""
          }
          onClick={() => onItemClick(item)}
        >
          {item.title}
        </li>
      ))}
      <Button
        sx={{
          background: "#000",
          color: "#fff",
          borderRadius: "8px",
          fontSize: "14px !important",
          fontWeight: "400",

          "&:hover": {
            background: "#000",
          },
        }}
        onClick={handleToggleShow}
      >
        {showAllItems ? "Less" : "More"}
      </Button>
    </>
  );
};
export default ShowMoreList;
