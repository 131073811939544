import { useState, useEffect, useRef, useCallback } from 'react';
import MapGL, { Source, Layer, Marker } from 'react-map-gl';
import { Box } from '@mui/system';
const mapToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN



const ControlledMap = (props) => {
    const mapRef = useRef(null);
    const { end, setEnd, selectedPlace, type } = props;
    const [viewState, setViewState] = useState({
        longitude: 55.296249,
        latitude: 25.276987,
        zoom: 10
    });
    const [markerState, setMarkerState] = useState({
        longitude: 55.296249,
        latitude: 25.276987,
        zoom: 10
    });
    const start = [24.4539, 54.3773];
    const handleMapOnLoad = useCallback(() => {
        if (selectedPlace) {
            const map = mapRef?.current?.getMap();
            map?.easeTo({
                center: [selectedPlace?.center[0], selectedPlace?.center[1]],
                zoom: 14,
                duration: 1000,
                easing: (t) => t,
            });
            const newViewState = {
                longitude: selectedPlace?.center[0],
                latitude: selectedPlace?.center[1],
                zoom: 10,
            };
            setMarkerState(newViewState);
        }
        else {
            const map = mapRef?.current?.getMap();
            map?.easeTo({
                center: [55.296249, 25.276987],
                zoom: 12,
                duration: 1000,
                easing: (t) => t,
            });
            setMarkerState({
                longitude: 55.296249,
                latitude: 25.276987,
                zoom: 10
            });
        }
    }, [selectedPlace]);
    useEffect(() => {
        handleMapOnLoad();
    }, [selectedPlace, handleMapOnLoad]);
    const GeolocateControlRef = useRef();
    useEffect(() => {
        getRoute();
    }, [end, setEnd, GeolocateControlRef]);
    const getRoute = async () => {
        try {
            const response = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${mapToken}`);
            const data = await response.json()
            if (!data.routes || data.routes.length === 0) {
                console.error('No routes found in the API response:', data);
                return;
            }
        }
        catch (error) {
            console.error('Error fetching or processing route data:', error);
        }
    };
    const handleClick = async (e) => {
        const newEnd = e.lngLat;
        setEnd([newEnd.lng, newEnd.lat]);
        // Update selectedPlace based on the clicked coordinates
        try {
            const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${newEnd.lng},${newEnd.lat}.json?access_token=${mapToken}`);
            const data = await response.json();

            console.log(data, 'map')

            if (data?.features && data.features.length > 0) {
                const placeName = data.features[0]?.place_name;
                const newSelectedPlace = {
                    place_name: placeName,
                    center: [newEnd.lng, newEnd.lat],
                };
                props?.setSelectedPlace(newSelectedPlace);
            }
        }
        catch (error) {
            console.error('Error fetching place name:', error);
        }
        const newViewState = {
            longitude: newEnd.lng,
            latitude: newEnd.lat
        };
        setViewState(newViewState);
        setMarkerState(newViewState);
        await getRoute();
    };
    const endPointFeature = {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Point',
            coordinates: end,
        },
    };
    const layerEndpoint = {
        id: 'end',
        type: 'circle',
        source: 'endSource',
        paint: {
            'circle-radius': 10,
            'circle-color': '#f30',
        },
    };
    const viewport = {
        width: '100vw',
        height: '100%',
        zoom: 10,
    };
    return (
    
            <Box margin={'unset'} width={'100%'} height={'100%'} position="relative">
                    <MapGL style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, borderRadius: '8px' }} ref={mapRef} {...viewport} {...viewState} onClick={type ? handleClick : null} onMove={(evt) => setViewState(evt.viewState)} mapStyle="mapbox://styles/mapbox/streets-v11" mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN} minZoom={viewport['minZoom']} maxZoom={viewport['maxZoom']} attributionControl={true} cooperativeGestures onLoad={handleMapOnLoad}>
                        <Source id="endSource" type="geojson" data={{ type: 'FeatureCollection', features: [endPointFeature] }}>
                            <Layer {...layerEndpoint}/>
                        </Source>
                        {markerState.longitude && markerState.latitude && (<Marker longitude={markerState.longitude} latitude={markerState.latitude}>
                                <div style={{ fontSize: '20px', color: '#0078FF' }}>📍</div>
                            </Marker>)}
                    </MapGL>
                </Box>
        
        );
};
export default ControlledMap;
