import { ReactComponent as apple } from "./apple.svg";
import { ReactComponent as bell } from "./bell.svg";
import { ReactComponent as facebook } from "./facebook.svg";
import { ReactComponent as googlePlay } from "./google-play.svg";
import { ReactComponent as heart } from "./heart.svg";
import { ReactComponent as instagram } from "./instagram.svg";
import { ReactComponent as linkedIn } from "./linkedin.svg";
import { ReactComponent as logo } from "./logo.svg";
import { ReactComponent as scroll } from "./scroll.svg";
import { ReactComponent as twitter } from "./twitter.svg";
import { ReactComponent as user } from "./user.svg";
import { ReactComponent as userData } from "./user-data.svg";
import { ReactComponent as whatsapp } from "./whatsapp.svg";
import { ReactComponent as youtube } from "./youtube.svg";
import { ReactComponent as search } from "./search.svg";
import { ReactComponent as smCircles } from "./sm-circles.svg";
import { ReactComponent as smCube } from "./sm-cube.svg";
import { ReactComponent as smHalfCircle } from "./sm-half-circle.svg";
import { ReactComponent as rentTravel } from "./rent-travel.svg";
import { ReactComponent as vehicles } from "./vehicles.svg";
import { ReactComponent as propertyForSale } from "./property-for-sale.svg";
import { ReactComponent as propertyForRent } from "./property-for-rent.svg";
import { ReactComponent as mobilesTabs } from "./mobiles-tabs.svg";
import { ReactComponent as electronics } from "./electronics.svg";
import { ReactComponent as classified } from "./classified.svg";
import { ReactComponent as homeAppliance } from "./home-appliance.svg";
import { ReactComponent as vendorServices } from "./vendor-services.svg";
import { ReactComponent as furniture } from "./furniture.svg";
import { ReactComponent as community } from "./community.svg";
import { ReactComponent as jobs } from "./jobs.svg";
import { ReactComponent as aspectRatio } from "./aspect-ratio.svg";
import { ReactComponent as bathroom } from "./bathroom.svg";
import { ReactComponent as bedroom } from "./bedroom.svg";
import { ReactComponent as location } from "./location.svg";
import { ReactComponent as rank } from "./rank.svg";
import { ReactComponent as verified } from "./verified.svg";
import { ReactComponent as arrow } from "./arrow.svg";
import { ReactComponent as facebookAuth } from "./facebook-auth.svg";
import { ReactComponent as googleAuth } from "./google-auth.svg";
import { ReactComponent as mail } from "./mail.svg";
import { ReactComponent as phoneNumber } from "./phone-number.svg";
import { ReactComponent as emailOpen } from "./email-open.svg";
import { ReactComponent as eye } from "./eye.svg";
import { ReactComponent as eyeOff } from "./eye-off.svg";
import { ReactComponent as lock } from "./lock.svg";
import { ReactComponent as success } from "./success.svg";
import { ReactComponent as close } from "./close.svg";
import { ReactComponent as agree } from "./agree.svg";
import { ReactComponent as noImage } from "../icons/no-image.svg";
import { ReactComponent as next } from "./next.svg";
import { ReactComponent as camera } from "./camera.svg";
import { ReactComponent as question } from "./question.svg";
import { ReactComponent as gpsFixed } from "./gps-fixed.svg";
import { ReactComponent as remove } from "./remove.svg";
import { ReactComponent as arrowDown } from "./arrow-down.svg";
import { ReactComponent as arrowUp } from "./arrow-up.svg";
import { ReactComponent as callPhone } from "./call-phone.svg";
import { ReactComponent as dislike } from "./dislike.svg";
import { ReactComponent as flag } from "./flag.svg";
import { ReactComponent as flipCamera } from "./flip-camera.svg";
import { ReactComponent as image } from "./image.svg";
import { ReactComponent as like } from "./like.svg";
import { ReactComponent as maidRoom } from "./maid-room.svg";
import { ReactComponent as map } from "./map.svg";
import { ReactComponent as member } from "./member.svg";
import { ReactComponent as review } from "./review.svg";
import { ReactComponent as share } from "./share.svg";
import { ReactComponent as video } from "./video.svg";
import { ReactComponent as noFilmMovie } from "./no-film-movie.svg";
import { ReactComponent as chat } from "./chat.svg";
import { ReactComponent as live } from "./live.svg";
import { ReactComponent as btnIcon } from "./btn-icon.svg";
import { ReactComponent as filterIcon } from "./filter-icon.svg";
import { ReactComponent as dashboardIcon } from "./darhboard-icon.svg";
import { ReactComponent as memberIcon } from "./members-icon.svg";
import { ReactComponent as bookmark } from "./bookmark-icon.svg";
import { ReactComponent as prevIcon } from "./prev-Icon.svg";
import { ReactComponent as profileIcon } from "./d_profile.svg";
import { ReactComponent as adIcon } from "./d_myads.svg";
import { ReactComponent as dSearchICon } from "./d_search.svg";
import { ReactComponent as Dbookmark } from "./d_bookmark.svg";
import { ReactComponent as SettingD } from "./settings.svg";
import { ReactComponent as Signout } from "./logout.svg";
import { ReactComponent as tick } from "./tick.svg";
import { ReactComponent as Member_Since } from "./member_since-profile.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as edit } from "./edit.svg";
import { ReactComponent as Formlock } from "./formlock.svg";
import { ReactComponent as Formuser } from "./formuser.svg";
import { ReactComponent as FormPhone } from "./formphone.svg";
import { ReactComponent as FormCake } from "./cake.svg";
import { ReactComponent as Attachment } from "./attachment.svg";
import { ReactComponent as FileUploadIcon } from "./file-upload.svg";
import { ReactComponent as MenuOpen } from "./menu-open.svg";
import { ReactComponent as MenuClose } from "./close-menu.svg";
import { ReactComponent as NewLogo} from './new-logo.svg'
import { ReactComponent as DeleteIcon } from './delete-icon.svg'
export default {
  APPLE: apple,
  ATTACHMENT: Attachment,
  ADICON: adIcon,
  DELETE_ICON: Delete,
  FILE_UPLOAD: FileUploadIcon,
  EDIT_ICON: edit,
  DSEARCH: dSearchICon,
  MEMBER_SINCE: Member_Since,
  DBOOKMARK: Dbookmark,
  DSETTINGS: SettingD,
  FORMLOCK: Formlock,
  FORMUSER: Formuser,
  FORMPHONE: FormPhone,
  FORMCAKE: FormCake,
  LOGOUT: Signout,
  TICK: tick,
  BELL: bell,
  BTNICON: btnIcon,
  PROFILE: profileIcon,
  DASHBOARD: dashboardIcon,
  MEMBERICON: memberIcon,
  MENU_OPEN: MenuOpen,
  MENU_CLOSE: MenuClose,
  BOOKMARK: bookmark,
  PREV: prevIcon,
  FILTER: filterIcon,
  CHAT: chat,
  LIVE: live,
  FACEBOOK: facebook,
  GOOGLE_PLAY: googlePlay,
  HEART: heart,
  INSTAGRAM: instagram,
  LINKEDIN: linkedIn,
  LOGO: logo,
  SCROLL: scroll,
  TWITTER: twitter,
  USER: user,
  USER_DATA: userData,
  WHATSAPP: whatsapp,
  YOUTUBE: youtube,
  SEARCH: search,
  SM_CIRCLES: smCircles,
  SM_CUBE: smCube,
  SM_HALF_CIRCLE: smHalfCircle,
  RENT_TRAVEL: rentTravel,
  VEHICLES: vehicles,
  PROPERTY_FOR_SALE: propertyForSale,
  PROPERTY_FOR_RENT: propertyForRent,
  MOBILES_TABS: mobilesTabs,
  ELECTRONICS: electronics,
  CLASSIFIED: classified,
  HOME_APPLIANCE: homeAppliance,
  VENDOR_SERVICES: vendorServices,
  FURNITURE: furniture,
  COMMUNITY: community,
  JOBS: jobs,
  ASPECT_RATIO: aspectRatio,
  BATHROOM: bathroom,
  BEDROOM: bedroom,
  LOCATION: location,
  RANK: rank,
  VERIFIED: verified,
  ARROW: arrow,
  FACEBOOK_AUTH: facebookAuth,
  GOOGLE_AUTH: googleAuth,
  MAIL: mail,
  PHONE_NUMBER: phoneNumber,
  EMAIL_OPEN: emailOpen,
  EYE: eye,
  EYE_OFF: eyeOff,
  LOCK: lock,
  SUCCESS: success,
  CLOSE: close,
  AGREE: agree,
  NO_IMAGE: noImage,
  NEXT: next,
  CAMERA: camera,
  QUESTION: question,
  GPS_FIXED: gpsFixed,
  REMOVE: remove,
  ARROW_DOWN: arrowDown,
  ARROW_UP: arrowUp,
  CALL_PHONE: callPhone,
  DISLIKE: dislike,
  FLAG: flag,
  FLIP_CAMERA: flipCamera,
  IMAGE: image,
  LIKE: like,
  MAID_ROOM: maidRoom,
  MAP: map,
  MEMBER: member,
  REVIEW: review,
  SHARE: share,
  VIDEO: video,
  NO_FILM_MOVIE: noFilmMovie,
  NEW_LOGO: NewLogo,
  DELETE_ICON: DeleteIcon
};
