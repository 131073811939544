
import Pusher from 'pusher-js'

const apiURL = process.env.REACT_APP_REST_API_URL

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY,{
    app_id : process.env.REACT_APP_PUSHER_APP_ID,
    secret : process.env.REACT_APP_PUSHER_SECRET,
    cluster : process.env.REACT_APP_PUSHER_CLUSTER,
    channelAuthorization: {
        endpoint: `${apiURL}/pusher/auth`
      },

})
