import React from 'react';
import { Box } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import ControlledButton from '../Button/button.component';
function FormHookBox({ onSubmit, initialValues, children, sx, submitValue, methods, submitStyle, disableSubmit }) {
    if (initialValues) {
        Object.keys(initialValues).forEach((key) => {
            methods?.setValue(`data.${key}`, initialValues[key]);
        });
    }
    return (submitValue || submitStyle || disableSubmit ? (<Box component={'form'} noValidate autoComplete="off" onSubmit={methods?.handleSubmit(onSubmit)} sx={sx}>
                <FormProvider {...methods}>
                    {children}
                    <ControlledButton value={submitValue} loadingButtonProps={{
            type: 'submit',
            fullWidth: true,
            sx: { py: '0.8rem', mt: '1rem' },
        }} customStyle={submitStyle} disabled={disableSubmit}/>
                </FormProvider>
            </Box>) : (<Box component={'form'} noValidate autoComplete="off" onSubmit={methods?.handleSubmit(onSubmit)} sx={sx}>
                <FormProvider {...methods}>
                    {children}
                </FormProvider>
            </Box>));
}
export default FormHookBox;
