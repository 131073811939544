
import gql from 'graphql-tag';

const GET_CATEGORIES = gql `
    query Categories($categoryId: Int, $showOnScreen: Int, $afl: Int) {
      categories(category_id: $categoryId, show_on_screen: $showOnScreen, afl: $afl) {
        id
        image
        title
        parent_id
        show_on_screen
        has_child
        price
        active_for_listing
        bgColor
        days
        has_form
        service_type
      }
    }
`;


const GET_CATEGORY_CHILD_BY_ID = gql `
  query Category($categoryId: Int, $afl: Int, $showOnScreen: Int) {
    categories(category_id: $categoryId, afl: $afl, show_on_screen: $showOnScreen) {
      id
      image
      title
      parent_id
      show_on_screen
      has_child
      price
      active_for_listing
      bgColor
      days
      has_form
      service_type
    }
  }
`;




export { GET_CATEGORIES, GET_CATEGORY_CHILD_BY_ID };
